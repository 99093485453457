<template>
  <div class="products-list-content" :key="renderkey">
    <loader :loader="loader" />
    <modal @close="hideModal" v-if="showModal" class="modal-content" name="add-product-modal">
      <template v-slot:header>
        <h3 class="modal-title">{{ $t('chooseProduct') }}</h3>
      </template>
      <template slot="footer"> </template>
      <template v-slot:body>
        <div class="md-layout modal-content">
          <div class="md-layout-item md-size-100" v-for="(p, index) in productsToAdd" :key="index">
            <md-field class="modal-product list-row">
              <span @click="handleAdd(p)" class="modal-span" type="text" id="">{{ p.Title }}</span>
            </md-field>
          </div>
        </div>
      </template>
    </modal>

    <modal @close="hideModal" v-if="showInfoModal" class="modal-content" name="add-info-modal">
      <template v-slot:header>
        <h3 class="modal-title">{{ $t('productCommentary') }}</h3>
      </template>
      <template slot="footer"> </template>
      <template v-slot:body>
        <div class="md-layout modal-content">
          <div class="md-layout-item md-size-100">
            <md-field>
              <label for="info">{{ $t('comment') }}</label>
              <md-input name="info" type="text" v-model="productInfo"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-button class="md-simple md-round" @click="hideModal">{{ $t('cancel') }}</md-button>
          </div>
          <div class="md-layout-item md-size-50">
            <md-button class="md-primary md-round" @click="postAdd(toPostProduct)">{{ $t('confirm') }}</md-button>
          </div>
        </div>
      </template>
    </modal>

    <div class="title-div">
      <h4 v-if="companyProductsPage || allProductsPage || contactProducts" class="company-name-title">
        {{ this.cardTitle }}
      </h4>
      <!-- <md-field v-if="companyProductsPage" class="company-name-title">{{ this.companyName }}</md-field> -->
      <h4 v-else class="my-company-title">{{ $t('myproducts') }}</h4>
    </div>
    <!-- <div class="spacer" v-else></div> -->
    <div class="display-products">
      <div v-for="product in selectedProducts" :key="product.ID + getRndNo()">
        <div class="md-layout-item">
          <product-card @delete-event="companyProductsPage ? handleDelete(product) : null" header-animation="true">
            <div slot="imageHeader" class="image-wrap" @click="goToProductPage(product)">
              <img class="img" :src="productImages[product.ID]" :alt="product.Image" />
            </div>

            <h4 slot="title" class="title">
              <a @click="goToProductPage(product)">{{ product[$t('productTitle')] }} </a>
            </h4>
            <div slot="description" class="card-description subtitle">
              <div>{{ product[$t('productSubtitle')] }}</div>
              <div v-if="product.Info">{{ product.Info }}</div>
            </div>
            <template slot="third-button" v-if="companyProductsPage && allow('{c452b678-30a6-48af-8fee-087040a6f594}')">
              <md-icon>close</md-icon>
              <md-tooltip md-direction="bottom">{{ $t('remove') }}</md-tooltip>
            </template>
          </product-card>
        </div>
      </div>
      <div class="" id="add-prod" v-if="!studentRole && allow('{6f2004e5-c1e2-4998-a660-1838cfda8c8e}')">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div @click="toggleModal" class="add-icon-div">
              <span class="add-prod-icon animated-icon">add_circle</span>
            </div>
            <div class="add-icon-text">
              <span>{{ $t('addNewProduct') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProductCard } from '@/components'
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationStore } from '@/stores/configuration'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import { useManagementStore } from '@/stores/management'
import { permissionMixin } from '../../RoleManagement/permissionMixin'

import Modal from '../../../components/Modal.vue'

import Swal from 'sweetalert2'

export default {
  mixins: [permissionMixin],
  components: {
    ProductCard,
    Modal,
  },
  props: {
    contactProducts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardTitle: '',
      isHover: [],
      showModal: false,
      showInfoModal: false,
      productInfo: '',
      toPostProduct: {},
      companyID: '',
      allProducts: [],
      selectedProductIDs: [],
      selectedProducts: [],
      productsToAdd: [],
      allProductsInfoArray: [],
      userProductsInfoArray: [],
      links: {
        '{691a838c-0e9f-11ee-b3bc-0050561ebe44}': 'RoloffMatek',
        '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}': 'Student Product',
        '{faeb2319-1fb3-4fb8-a5ea-d3250f5ccccd}': 'Aws Select',
        '{14dc88db-5d79-447a-9bd6-193ae1fc0c90}': 'Student 2022',
      },
      loader: false,
      responsive: false,
      renderkey: 0,
      companyName: '',
      companyProductsPage: false,
      studentProductID: '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}',
      productIDCloud: '{faeb2319-1fb3-4fb8-a5ea-d3250f5ccccd}',
      productIDMdesign2022: '{1138e27f-99d6-45b4-9ed7-a059bee0fb41}',
      currentURLpage: '',
      configID: '',
      contactID: '',
      studentRole: this.userStore.role.includes('Student'),
      allProductsPage: false,
      productImages: {},
    }
  },
  watch: {
    // selectedProductIDs(newVal) {
    //   this.productsToAdd = this.allProducts;
    //   var newSelection = [];
    //   if (newVal.length > 0) {
    //     this.selectedProductIDs.forEach((id) => {
    //       var product = this.allProducts.find((p) => p.ID === id);
    //       newSelection.push(product);
    //     });
    //   }
    //   this.selectedProducts = newSelection;
    //   if (this.selectedProducts.length > 0) {
    //     this.productsToAdd = this.productsToAdd.filter(
    //       (val) => !this.selectedProducts.includes(val)
    //     );
    //   }
    // },
    $route: 'onRouteChange',
  },
  methods: {
    async handleAdd(product) {
      this.showModal = false
      this.toPostProduct = {}

      Swal.fire({
        icon: 'question',
        inputLabel: this.$t('productCommentary'),
        inputPlaceholder: this.$t('productInputPlaceholder'),
        input: 'text',
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then(async (result) => {
        if (!(await result).isConfirmed) return

        this.productInfo = result.value
        this.postAdd(product)
      })
      return
      Swal.fire({
        title: this.$t('addProductText'),
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isDismissed) return

        this.showInfoModal = true
        this.toPostProduct = product

        return
      })
    },
    handleDelete(product) {
      Swal.fire({
        title: this.$t('removeProductText'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('no'),
        confirmButtonText: this.$t('yes'),
      }).then((result) => {
        if (result.isDismissed) return

        Swal.fire({
          icon: 'warning',
          title: this.$t('deleteTitle'),
          html: this.$t('deleteText'),
          showCancelButton: true,
          cancelButtonText: this.$t('cancel'),
        }).then((result) => {
          if (result.isDismissed) return
          this.postDelete(product)
        })
      })
    },
    async postAdd(product) {
      this.showInfoModal = false
      this.loader = true
      if (window.location.href.includes('contactproducts') || this.contactProducts) {
        var response = await this.companyStore.createProductToContact(this.contactID, product.ID, this.userStore.token)

        if (response.status === 200) {
          this.getContactProducts()
        } else {
          Swal.fire({
            title: this.$t('error'),
            text: response.statusText,
            icon: 'warning',
          })
        }
      }
      if (window.location.href.includes('companyproducts') || window.location.href.includes('company')) {
        // AWS Product
        if (product.ID === this.productIDCloud) {
          var success = await this.postProductCloud(product)
          if (success === false) return
        } else {
          var prod = {
            CompanyID: this.companyStore.company.ID,
            ProductID: product.ID,
            Info: this.productInfo,
          }

          var response = await this.configurationProductLevelStore.createCompanyHasProduct(prod, this.userStore.token)
          if (response.status === 200) {
            this.selectedProducts = []
            this.selectedProductIDs = []
            this.getCompanyProducts()
          } else {
            Swal.fire({
              icon: 'error',
              title: $t('error'),
              html: `${$t('errorCreateCompHasProd')} ${response.data.Message}`,
            })
          }
        }

        this.toPostProduct = {}
        this.productInfo = ''
      }
      this.loader = false
    },
    async postProductCloud(product) {
      // ID für configuration vorrübergehend selbst erstellen bis api beim Erstellen die ID zurückliefert
      // create configuration
      // var response = await this.companyStore.createConfiguration("Cloud Company - " + this.companyStore.company.Name, this.userStore.token)
      var response = await this.configurationStore.createConfiguration(
        'Cloud Company - ' + this.companyStore.company.Name,
        this.companyStore.company.ID,
        this.userStore.token,
      )
      this.awsConfigID = response.data
      if (response.status !== 200) {
        Swal.fire({
          title: this.$t('error'),
          text: responseAllContacts.statusText,
          icon: 'warning',
        }).then(() => {
          return false
        })
      } else {
        const now = this.companyStore.getNow()

        var prod = {
          ConfigurationID: this.awsConfigID,
          CompanyID: this.companyStore.company.ID,
          ProductID: product.ID,
          Info: this.productInfo,
          FileCount: 0,
          Disable: false,
          CreatedBy: this.userStore.userData.ID,
          Created: now,
          ModifiedBy: this.userStore.userData.ID,
          Modified: now,
        }

        var responseProd = await this.configurationProductLevelStore.createCompanyHasProduct(prod, this.userStore.token)
        if (responseProd.status === 200) {
          this.selectedProducts = []
          this.selectedProductIDs = []
          this.getCompanyProducts()

          Swal.fire({
            title: this.$t('success'),
            html: this.$t('productAdded'),
            icon: 'success',
            confirmButtonText: this.$t('ok'),
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            html: `${this.$t('errorAddProd')} ${responseProd.data.Message}`,
          })
        }
      }
    },

    async postDelete(product) {
      this.loader = true
      if (window.location.href.includes('contactproducts') || this.contactProducts) {
        var response = await this.companyStore.deleteProductFromContact(
          this.contactID,
          product.ID,
          this.userStore.token,
        )
      }
      if (window.location.href.includes('companyproducts') || window.location.href.includes('company')) {
        var response = await this.companyStore.deleteCompanyHasProduct_ID(
          product.CompanyHasProductID,
          this.userStore.token,
        )
      }

      if (response?.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successDeleteProduct'),
        })
        await this.getCompanyProducts()
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: response.data.Message,
        })
      }
      this.loader = false
    },
    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
    async goToProductPage(product) {
      const currentURL = window.location.href
      const token = this.userStore.token
      if (this.allProductsPage) {
        this.configurationProductLevelStore.product = product
        this.$router.push({ name: 'Edit Product' })
      } else if (this.companyProductsPage) {
        var params = {}
        this.configurationProductLevelStore.isContactProduct = false
        if (product.ID !== this.productIDCloud) {
          this.configurationProductLevelStore.product = product

          if (!this.userStore.role.includes('Administrator')) this.configurationProductLevelStore.isReadonly = true

          var temp = this.companyStore.productData.companyHasProduct.find((el) => el.ProductID === product.ID)

          this.$router.push({ name: 'Mdesign Overview' })
          return
        }
        // for testing purpose the ID is 'MDESIGN Pumpen', just a dummy product
        else if (product.ID === '{b7eaf622-6817-4456-a2d3-47b6966de7cb}') {
          this.configurationProductLevelStore.product = product
          this.$router.push({ name: 'Mdesign Overview' })
        } else {
          this.$router.push({ name: this.links[product.ID], params: params })
        }

        // if (product.ID === this.productIDCloud){
        //   params = this.companyStore.productData.companyHasProduct.find(p => p.ProductID === this.productIDCloud)

        // }
      } else if (this.contactProducts) {
        this.configurationProductLevelStore.product = product
        this.configurationProductLevelStore.isContactProduct = true
      } else if (currentURL.includes('myproducts')) {
        this.$router.push({ name: this.links[product.ID] })
      }
    },
    async getMyProducts() {
      this.loader = true
      await this.getUserProductsID()
      await this.getProductsInfoArray()
      this.getUserProductInfoArray()
      this.loader = false
    },
    async getUserProductsID() {
      const productIDArrayTemp = []
      await this.userStore.getUserProducts(this.userStore.userData.ID).then((result) => {
        if (result === 'unauthorized') {
          this.$router.push({ name: 'Login' })
          return
        }
        if (result) {
          result.map((product) => {
            productIDArrayTemp.push(product.ProductID)
          })
        }
      })
      this.productsIDArray = this.getVueObject(productIDArrayTemp)
      this.userStore.userProductsID = this.productsIDArray
      //here we are checking that user has Student role or assign Student product to him if he has not
      if (this.userStore.role.includes('Student')) {
        this.userStore.userProductsID.includes(this.studentProductID)
          ? null
          : (await this.userStore.createProductToContact(this.userStore.userData.ID, this.studentProductID),
            await this.getUserProductsID())
      }
    },

    getVueObject(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    async getProductsInfoArray() {
      let productIDArrayTemp = []
      await this.userStore.getAllProductsInfo().then((result) => {
        if (result) {
          productIDArrayTemp = result
        }
      })
      this.allProductsInfoArray = productIDArrayTemp
    },
    getUserProductInfoArray() {
      let userProductsInfoArrayTemp = []
      this.productsIDArray.map((productID) => {
        this.allProductsInfoArray.map((product) => {
          if (productID === product.ID) {
            userProductsInfoArrayTemp.push(product)
          }
        })
      })
      this.selectedProducts = userProductsInfoArrayTemp
      this.userStore.userProducts = this.userProductsInfoArray
      var pageContent = document.querySelector('.display-products')
      if (pageContent) {
        if (!this.companyProductsPage) {
          // Add the class if this.companyProductsPage is false
          pageContent.classList.add('hide-buttons-wrapper')
        }
      }
    },
    onRouteChange() {
      // This method will be called whenever the route changes
      // You can perform any logic you need before the component is updated
      this.selectedProducts = []
      this.selectedProductIDs = []
      const currentURL = window.location.href
      if (currentURL.includes('companyproducts')) {
        this.companyProductsPage = true
        this.getCompanyProducts()
      } else if (currentURL.includes('myproducts')) {
        this.companyProductsPage = false
        this.allProductsPage = false
        this.getMyProducts()
      } else if (currentURL.includes('contactproducts') || this.contactProducts) {
        this.companyProductsPage = true
        this.getContactProducts()
      } else if (currentURL.includes('allproducts')) {
        if (!this.userStore.role.includes('Admin')) this.$router.push({ name: 'Login' })
        this.companyProductsPage = false
        this.allProductsPage = true
        this.cardTitle = this.$t('products')
        this.getAllProducts()
      }

      var pageContent = document.querySelector('.display-products')
      if (pageContent) {
        if (!this.companyProductsPage) {
          // Add the class if this.companyProductsPage is false
          pageContent.classList.add('hide-buttons-wrapper')
        } else {
          // Remove the class if this.companyProductsPage is true
          pageContent.classList.remove('hide-buttons-wrapper')
        }
      }
    },
    async getCompanyProducts() {
      if (this.companyStore.company.ID && this.companyStore.company.Name) {
        this.companyID = this.companyStore.company.ID
        this.companyName = this.companyStore.company.Name
      } else {
        const resCompany = await this.companyStore.getCompanyDataByID(
          this.userStore.userData.CompanyID,
          this.userStore.token,
        )
        const company = resCompany.data[0]
        this.companyStore.company = company
        this.companyID = company.ID
        this.companyName = company.Name
      }
      if (this.$route.fullPath === '/company') this.cardTitle = this.$t('products')
      else this.cardTitle = `${this.$t('productsFrom')} ${this.companyName}`
      if (!this.companyID) {
        this.$router.push({ name: 'Companies' })
      }
      var responseAllProducts = await this.companyStore.getProducts(this.userStore.token)

      if (responseAllProducts.status !== 200) {
        this.$router.push({ name: 'Companies' })
      }

      this.allProducts = responseAllProducts.data
      this.productsToAdd = this.allProducts

      var responseProductsByID = await this.companyStore.getProductsByCompanyID(this.companyID, this.userStore.token)
      var data = responseProductsByID.data

      // filter out disabled Products
      if (data.length > 0) data = data.filter((el) => el.Disable === false)

      // falls awsConfig vorhanden speichern
      if (data.length > 0) var awsProd = data.find((item) => item.ProductID === this.productIDCloud)
      if (awsProd !== undefined) this.configID = awsProd.ConfigurationID

      if (data.length > 0) data.forEach((d) => this.selectedProductIDs.push(d.ProductID))

      if (this.selectedProductIDs.length > 0) {
        var selectedTemp = []
        data.forEach((prod) => {
          let product = { ...this.allProducts.find((p) => p.ID === prod.ProductID) }

          product.Info = prod.Info
          product.CompanyHasProductID = prod.ID
          product.Created = prod.Created

          selectedTemp.push(product)
        })

        selectedTemp = selectedTemp.sort((a, b) => {
          const dateA = new Date(a.Created)
          const dateB = new Date(b.Created)

          return dateA - dateB
        })
        this.selectedProducts = selectedTemp

        // if (this.selectedProducts.length > 0)
        //   this.productsToAdd = this.productsToAdd.filter((val) => !this.selectedProducts.includes(val))
      }
    },
    async getContactProducts() {
      if (!this.userStore.editContactID.ID) this.$router.push('Contacts')
      this.contactID = this.userStore.editContactID.ID
      var responseAllProducts = await this.companyStore.getProducts(this.userStore.token)
      this.allProducts = []
      this.productsToAdd = []
      this.selectedProducts = []
      this.selectedProductIDs = []
      if (responseAllProducts.status !== 200) {
        Swal.fire({
          title: this.$t('error'),
          text: responseAllProducts.statusText,
          icon: 'warning',
          confirmButtonClass: 'md-button md-success btn-fill',
        }).then((result) => {
          this.$router.push('Contacts')
        })
      }

      this.allProducts = responseAllProducts.data
      this.productsToAdd = this.allProducts

      var responseProductsByID = await this.companyStore.getProductsByContactID(this.contactID, this.userStore.token)
      if (responseProductsByID.status !== 200 && responseProductsByID.status !== 404) {
        this.$router.push('Contacts')
      }
      this.userStore.editContactProducts = responseProductsByID.data

      var data = responseProductsByID.data
      if (data.length > 0) data.forEach((d) => this.selectedProductIDs.push(d.ProductID))

      if (this.selectedProductIDs.length > 0) {
        var selectedTemp = []
        this.selectedProductIDs.forEach((prod) => {
          var product = this.allProducts.find((p) => p.ID === prod)
          selectedTemp.push(product)
        })
        this.selectedProducts = selectedTemp

        if (this.selectedProducts.length > 0)
          this.productsToAdd = this.productsToAdd.filter((val) => !this.selectedProducts.includes(val))
      }
    },
    getRndNo() {
      return Math.floor(Math.random() * 10001).toString()
    },
    async toggleModal() {
      if (this.allProductsPage) {
        this.goToEditProductPage()
        return
      }
      let inputOptions = {}
      // this.productsToAdd.sort((a, b) => a.Title.localeCompare(b.Title))
      this.productsToAdd.sort((a, b) => b.Version - a.Version)
      this.productsToAdd.forEach((item) => {
        inputOptions[item.ID] = item.Title
      })

      const result = await Swal.fire({
        icon: 'question',
        title: this.$t('products'),
        input: 'select',
        inputOptions: inputOptions,
        inputPlaceholder: this.$t('chooseProduct'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        return result
      })

      if (!result.isConfirmed || result.value === '') return

      const product = this.productsToAdd.find((p) => p.ID === result.value)
      this.handleAdd(product)

      // else this.showModal = true
    },
    hideModal() {
      this.showModal = false
      this.showInfoModal = false
    },
    async getAllProducts() {
      var responseAllProducts = await this.companyStore.getProducts(this.userStore.token)
      this.allProducts = responseAllProducts.data
      this.allProducts.sort((a, b) => b.Version - a.Version)
      this.selectedProducts = responseAllProducts.data
    },
    goToEditProductPage() {
      this.$router.push({ name: 'Edit Product' })
    },
  },

  async beforeMount() {
    this.loader = true
    this.$changePageTitle(this.$t('products'))
    const currentURL = window.location.href
    this.isStudent = this.userStore.role === 'Student' || this.userStore.role === 'User'

    this.configurationProductLevelStore.resetConfigurationProductLevelStore()

    this.productImages = await this.managementStore.getProductImages(this.userStore.token)
    if (currentURL.includes('companyproducts') || currentURL.includes('company')) {
      this.companyProductsPage = true
      if (this.userStore.role === 'Student' || this.userStore.role === 'User') this.$router.push({ name: 'Login' })
      await this.getCompanyProducts()
    } else if (currentURL.includes('myproducts')) {
      this.companyProductsPage = false
      await this.getMyProducts()
    } else if (currentURL.includes('contactproducts') || this.contactProducts) {
      this.companyProductsPage = false
      this.cardTitle = this.$t('products')
      await this.getContactProducts()
    } else if (currentURL.includes('allproducts')) {
      if (!this.userStore.role.includes('Admin')) this.$router.push({ name: 'Login' })
      this.allProductsPage = true
      this.companyProductsPage = false
      this.cardTitle = this.$t('allProducts')
      await this.getAllProducts()
    }
    this.renderkey++
    this.loader = false
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    const configurationStore = useConfigurationStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const managementStore = useManagementStore()
    return { userStore, companyStore, configurationStore, configurationProductLevelStore, managementStore }
  },
}
</script>

<style scoped>
.md-card .md-card-actions {
  display: none;
}

.display-products {
  display: flex;
  flex-wrap: wrap;
}

.display-products div {
  flex: 0 0 25%;
}

#add-prod {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  padding-top: 8rem;
}

.add-icon-div {
  display: flex;
  cursor: pointer;
  z-index: 2;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .display-products div {
    flex: 0 0 50%;
  }
  #add-prod {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 600px) {
  .display-products {
    flex-direction: column;
    width: 100%;
  }

  .display-nav {
    flex-direction: column;
    width: 100%;
  }
}

.h-2 {
  height: 2rem;
}

.img {
  object-fit: cover !important;
  height: 220px !important;
  object-position: top;
}

.image-wrap {
  display: flex;
  height: 220px !important;
  cursor: pointer;
  justify-content: center;
  /* align-items: flex-end; */
}
/* .image-wrap img {
  max-width: 100%;
  max-height: 100%;
  display: block;
} */

/deep/.buttons-wrapper .first-button,
/deep/.buttons-wrapper .second-button {
  display: none !important;
}

.hide-buttons-wrapper /deep/ .first-button,
.hide-buttons-wrapper /deep/ .second-button,
.hide-buttons-wrapper /deep/ .third-button {
  display: none !important;
}

.company-name-title {
  padding: 10px 10px;
}
.my-company-title {
  padding: 0 30px;
}
.subtitle {
  min-height: 50px;
}

.title {
  min-height: 51px;
}

.mb-1r {
  margin-bottom: 1rem;
}

.is-hover {
  background: #7e8fa7;
  cursor: pointer;
}
.modal-span {
  width: 100%;
  text-align: left;
}
.title-div {
  padding: 0 2rem;
}
/* [data-background-color='black'] {
  .add-prod-icon {
    background-image: linear-gradient(to right, #009ee3, #009ee3) !important;
  }
} */
.spacer {
  height: 3.2rem;
}
</style>

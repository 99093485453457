<template>
  <div id="licence-content">
    <div class="loader-wrapper">
      <loader :loader="loader" />
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <form class="edit-mdesign-licence">
        <md-card class="card-content">
          <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
            <div class="card-icon">
              <md-icon>list</md-icon>
            </div>
            <div>
              <h4>{{ titleText }}</h4>
              <h5>{{ subtitleText }}</h5>
            </div>
          </md-card-header>
          <md-card-content>
            <div
              class="md-layout"
              @drop.prevent="onFileChange"
              @dragover.prevent="handleDragOver"
              @dragleave="handleDragLeave"
              :class="{ entered: dragging }"
            >
              <div class="md-layout-item md-small-size-100 md-size-100 content-right">
                <md-button v-if="!readonly" class="md-round md-primary md-fileinput btn-custom">
                  <template
                    ><span>{{ $t('loadHostIDFile') }}</span></template
                  >
                  <input accept=".id" type="file" @change="onFileChange" />
                </md-button>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-25">
                <md-field class="has-count" :class="{ 'too-long': licenceCreatorModel.CompanyNameShort.length > 43 }">
                  <label>{{ $t('company') }}</label>
                  <md-input
                    v-model="licenceCreatorModel.CompanyNameShort"
                    type="text"
                    id="company"
                    maxlength="43"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-25">
                <md-field>
                  <label>{{ $t('comment') }}</label>
                  <md-input v-model="licenceCreatorModel.Comment" type="text" id="comment"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-25">
                <ValidationProvider name="email" rules="email" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('receiver') }}</label>
                    <md-input
                      :disabled="readonly"
                      v-model="licenceCreatorModel.Email"
                      type="email"
                      id="email"
                    ></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-25">
                <md-field>
                  <label for="version">{{ $t('version') }}</label>
                  <md-select disabled v-model="licenceCreatorModel.Version" name="version" id="version">
                    <md-option :value="17.0">{{ '17.0' }}</md-option>
                    <md-option :value="18.0">{{ '18.0' }}</md-option>
                    <md-option :value="19.0">{{ '19.0' }}</md-option>
                    <md-option :value="20.0">{{ '20.0' }}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-33 md-size-25">
                <md-switch
                  v-model="licenceCreatorModel.Unlimited"
                  @change="toggleSwitch('unlimited')"
                  :disabled="readonly"
                >
                  {{ $t('unlimited') }}
                </md-switch>
              </div>
              <div class="md-layout-item md-small-size-33 md-size-25">
                <md-switch v-model="licenceCreatorModel.TerminalServer" :disabled="readonly">
                  {{ $t('terminalServer') }}
                </md-switch>
              </div>
              <div class="md-layout-item md-small-size-33 md-size-25">
                <md-switch v-model="licenceCreatorModel.VirtualServer" :disabled="readonly">
                  {{ $t('virtualServer') }}
                </md-switch>
              </div>
              <div class="md-layout-item md-small-size-0 md-size-25"></div>
              <div class="md-layout-item md-small-size-100 md-size-25">
                <md-field class="input-field">
                  <label>{{ $t('startDate') }}</label>
                  <md-input type="date" v-model="licenceCreatorModel.StartDate" :disabled="readonly" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-25">
                <md-field class="input-field" v-if="!licenceCreatorModel.Unlimited">
                  <label>{{ $t('limited') }}</label>
                  <md-input type="date" v-model="licenceCreatorModel.EndDate" :disabled="readonly" />
                </md-field>
                <md-field v-else>
                  <label>{{ $t('limited') }}</label>
                  <md-input type="text" disabled class="md-input" :value="$t('unlimited')" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-25">
                <md-field>
                  <label>{{ $t('hostIDCreateDate') }}</label>
                  <md-input v-model="licenceCreatorModel.HostIDCreateDate" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-25">
                <md-field class="has-count" :class="{ 'too-long': licenceCreatorModel.CustomerNr.length > 10 }">
                  <label>{{ $t('customerNumber') }}</label>
                  <md-input v-model="licenceCreatorModel.CustomerNr" type="text" maxlength="10" disabled></md-input>
                </md-field>
              </div>

              <!-- spacer -->
              <div class="md-layout-item md-size-100 spacer"></div>

              <div class="md-layout-item md-small-size-50 md-size-20">
                <md-switch
                  v-model="licenceCreatorModel.IsMacAddress"
                  @change="toggleSwitch('macAddress')"
                  :disabled="readonly"
                >
                  {{ $t('macAddress') }}
                </md-switch>
              </div>
              <div class="md-layout-item md-small-size-50 md-size-20">
                <md-switch
                  v-model="licenceCreatorModel.IsPcName"
                  @change="toggleSwitch('computerName')"
                  :disabled="readonly"
                >
                  {{ $t('computerName') }}
                </md-switch>
              </div>
              <div class="md-layout-item md-small-size-50 md-size-20">
                <md-switch
                  v-model="licenceCreatorModel.IsMacAddressAndPcName"
                  @change="toggleSwitch('pcNameAndMac')"
                  :disabled="readonly"
                >
                  {{ $t('computerName') }} & {{ $t('macAddress') }}
                </md-switch>
              </div>
              <div class="md-layout-item md-small-size-50 md-size-20">
                <md-switch v-model="licenceCreatorModel.Triade" @change="toggleSwitch('triad')" :disabled="readonly">
                  {{ $t('triad') }}
                </md-switch>
              </div>
              <div class="md-layout-item md-small-size-50 md-size-20">
                <md-switch v-model="licenceCreatorModel.Unbound" @change="toggleSwitch('unbound')" :disabled="readonly">
                  {{ $t('unbound') }}
                </md-switch>
              </div>
              <div v-if="isMediumScreen || isSmallScreen" class="md-layout-item md-small-size-50 md-size-0"></div>

              <div class="md-layout-item md-size-100 spacer"></div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>{{ $t('port') }}</label>
                  <md-input v-model="licenceCreatorModel.Port" type="text" id="port" :disabled="readonly"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-66"></div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>{{ $t('macAddress') }}</label>
                  <md-input
                    v-model="licenceCreatorModel.MacAddress1"
                    type="text"
                    :disabled="licenceCreatorModel.Unbound || licenceCreatorModel.IsPcName || readonly"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>{{ $t('macAddress') }}</label>
                  <md-input
                    v-model="licenceCreatorModel.MacAddress2"
                    type="text"
                    :disabled="licenceCreatorModel.Unbound || !licenceCreatorModel.Triade || readonly"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>{{ $t('macAddress') }}</label>
                  <md-input
                    v-model="licenceCreatorModel.MacAddress3"
                    type="text"
                    :disabled="licenceCreatorModel.Unbound || !licenceCreatorModel.Triade || readonly"
                  ></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>{{ $t('computerName') }}</label>
                  <md-input
                    v-model="licenceCreatorModel.PcName1"
                    type="text"
                    :disabled="licenceCreatorModel.Unbound || licenceCreatorModel.IsMacAddress || readonly"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>{{ $t('computerName') }}</label>
                  <md-input
                    v-model="licenceCreatorModel.PcName2"
                    type="text"
                    :disabled="licenceCreatorModel.Unbound || !licenceCreatorModel.Triade || readonly"
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>{{ $t('computerName') }}</label>
                  <md-input
                    v-model="licenceCreatorModel.PcName3"
                    type="text"
                    :disabled="licenceCreatorModel.Unbound || !licenceCreatorModel.Triade || readonly"
                  ></md-input>
                </md-field>
              </div>
              <!-- spacer -->
              <div class="md-layout-item md-size-100 spacer"></div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-button class="md-simple md-round btn-custom" @click="backStep">{{ $t('back') }}</md-button>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-33"></div>
                <div class="md-layout-item md-small-size-100 md-size-33 text-right">
                  <md-button class="md-primary md-round btn-custom" @click="handleCreate" :disabled="invalid">{{
                    $t('save')
                  }}</md-button>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { useConfigurationProductLevelStore } from '../../../stores/configurationProductLevel.js'
import { useCompanyStore } from '../../../stores/company.js'
import { useUserStore } from '../../../stores/user.js'
import Swal from 'sweetalert2'
// source: https://www.npmjs.com/package/fast-xml-parser
import { XMLParser, XMLValidator } from 'fast-xml-parser'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { email } from 'vee-validate/dist/rules'

extend('email', email)

export default {
  components: { ValidationObserver, ValidationProvider },
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    oneSwitchTrue() {
      return (
        this.licenceCreatorModel.Unbound ||
        this.licenceCreatorModel.IsPcName ||
        this.licenceCreatorModel.IsMacAddressAndPcName ||
        this.licenceCreatorModel.Triade ||
        this.licenceCreatorModel.IsMacAddress
      )
    },
  },
  data() {
    return {
      loader: false,
      titleText: '',
      subtitleText: '',
      licenceCreatorModel: {
        isValid: false,
        ID: '',
        Disable: false,
        Salutation: '',
        Firstname: '',
        LastName: '',
        CompanyName: '',
        Receiver: '',
        Version: 0,
        Unlimited: false,
        TerminalServer: false,
        VirtualServer: false,
        CompanyNameShort: '',
        Comment: '',
        StartDate: '',
        EndDate: '',
        HostIDCreateDate: '',
        CustomerNr: '',
        IsMacAddress: false,
        IsPcName: false,
        IsMacAddressAndPcName: false,
        Triade: false,
        Unbound: false,
        Port: 0,
        MacAddress1: '',
        PcName1: '',
        MacAddress2: '',
        PcName2: '',
        MacAddress3: '',
        PcName3: '',
        ServerHostID: '',
        ServerName: '',
        InstallationType: '',
        Build: '',
        OperationSystem: '',
        OperationSystem: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      parsedData: null,
      dragging: false,
      readonly: false,
      isSmallScreen: false,
      isMediumScreen: false,
      isNew: false,
    }
  },
  async beforeMount() {
    this.isSmallScreen = window.matchMedia('(max-width: 768px)').matches
    this.isMediumScreen = window.matchMedia('(max-width: 960px)').matches
    window.addEventListener('resize', this.checkScreenSize)

    if (this.configurationProductLevelStore.licenceCreatorModel.ID === '') {
      this.setNewLicenceModel()
      this.titleText = this.$t('createHostIDFileTitle')
      this.subtitleText = this.$t('createHostIDFileSubtitle')
      this.isNew = true
    } else {
      this.titleText = this.$t('editHostIDFileTitle')
      this.subtitleText = this.$t('editHostIDFileSubtitle')
      this.licenceCreatorModel = this.configurationProductLevelStore.licenceCreatorModel
      this.licenceCreatorModel.CustomerNr = this.companyStore.company.CustomerNr
      this.licenceCreatorModel.StartDate = this.licenceCreatorModel.StartDate.includes('T')
        ? this.licenceCreatorModel.StartDate.split('T')[0]
        : this.licenceCreatorModel.StartDate
      this.licenceCreatorModel.EndDate = this.licenceCreatorModel.EndDate.includes('T')
        ? this.licenceCreatorModel.EndDate.split('T')[0]
        : this.licenceCreatorModel.EndDate
      this.licenceCreatorModel.HostIDCreateDate = this.companyStore.getReadableDate(
        this.licenceCreatorModel.HostIDCreateDate,
      )
    }
    this.licenceCreatorModel.Version = this.configurationProductLevelStore.product.Version
    if (this.licenceCreatorModel.Version < 19) this.licenceCreatorModel.Port = 28060
    else this.licenceCreatorModel.Port = 28064
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize)
  },
  methods: {
    async handleCreate() {
      if (this.licenceCreatorModel.CompanyNameShort.length > 43) {
        Swal.fire({
          title: this.$t('companyNameTooLong'),
          text: this.$t('companyNameTooLongText'),
          icon: 'warning',
        })
        return
      }
      if (this.licenceCreatorModel.CustomerNr.length > 10) {
        Swal.fire({
          title: this.$t('customerNrNameTooLong'),
          text: this.$t('customerNrNameTooLongText'),
          icon: 'warning',
        })
        return
      }

      const activeProduct = this.configurationProductLevelStore.activeProduct
      const date = new Date()
      const now = date.toISOString()
      var obj = {
        CompanyHasProductID: activeProduct.ID,
        ContactHasProductID: 'NULL',
        Email: this.licenceCreatorModel.Email,
        Disable: false,
        Version: this.licenceCreatorModel.Version,
        Unlimited: this.licenceCreatorModel.Unlimited,
        StartDate: this.licenceCreatorModel.StartDate,
        EndDate: this.licenceCreatorModel.EndDate,
        CompanyNameShort: this.licenceCreatorModel.CompanyNameShort,
        Comment: this.licenceCreatorModel.Comment,
        TerminalServer: this.licenceCreatorModel.TerminalServer,
        VirtualServer: this.licenceCreatorModel.VirtualServer,
        HostIDCreateDate: this.companyStore.formatDate(this.licenceCreatorModel.HostIDCreateDate),
        IsMacAddress: this.licenceCreatorModel.IsMacAddress,
        IsPcName: this.licenceCreatorModel.IsPcName,
        IsMacAddressAndPcName: this.licenceCreatorModel.IsMacAddressAndPcName,
        Triade: this.licenceCreatorModel.Triade,
        Unbound: this.licenceCreatorModel.Unbound,
        Port: this.licenceCreatorModel.Port,
        MacAddress1: this.licenceCreatorModel.MacAddress1,
        PcName1: this.licenceCreatorModel.PcName1,
        MacAddress2: this.licenceCreatorModel.MacAddress2,
        PcName2: this.licenceCreatorModel.PcName2,
        MacAddress3: this.licenceCreatorModel.MacAddress3,
        PcName3: this.licenceCreatorModel.PcName3,
        ServerHostID:
          this.licenceCreatorModel.ServerHostID === ''
            ? this.licenceCreatorModel.MacAddress1
            : this.licenceCreatorModel.ServerHostID,
        ServerName: this.licenceCreatorModel.ServerName,
        InstallationType: this.licenceCreatorModel.InstallationType,
        Build: this.licenceCreatorModel.Build,
        OperationSystem: this.licenceCreatorModel.OperationSystem,
        CreatedBy: this.licenceCreatorModel.CreatedBy,
        ModifiedBy: this.licenceCreatorModel.ModifiedBy,
      }
      var response
      if (this.licenceCreatorModel.ID === '') {
        obj.CreatedBy = this.userStore.userData.ID
        obj.ModifiedBy = this.userStore.userData.ID
        this.loader = true
        response = await this.configurationProductLevelStore.createConfigurationLicence(obj, this.userStore.token)
        this.loader = false
        if (response.status === 200)
          Swal.fire({
            position: 'middle-end',
            icon: 'success',
            title: this.$t('success'),
            html: this.$t('hostIDCreateSuccess'),
            allowOutsideClick: true,
            confirmButtonText: this.$t('ok'),
          }).then((result) => {
            this.$router.push({ name: 'Mdesign Overview' })
          })
      } else {
        let dateTime = new Date()
        dateTime.setTime(dateTime.getTime() - dateTime.getTimezoneOffset() * 60000)

        obj.ID = this.licenceCreatorModel.ID
        obj.ModifiedBy = this.userStore.userData.ID
        obj.Modified = dateTime
        this.loader = true
        response = await this.configurationProductLevelStore.updateConfigurationLicence(obj, this.userStore.token)

        if (response.status !== 200) {
          Swal.fire({
            position: 'middle-end',
            icon: 'error',
            title: this.$t('error'),
            html: response.data.Message,
            showConfirmButton: true,
            allowOutsideClick: false,
            confirmButtonText: this.$t('ok'),
          }).then((result) => {})
          this.loader = false
          return
        } else {
          Swal.fire({
            icon: 'success',
            title: this.$t('success'),
            html: this.$t('savedHostIDFile'),
          }).then((result) => {
            this.loader = false
            this.$router.push({ name: 'Mdesign Overview' })
          })
        }

        // check for licence file and update
        // await this.checkLicenceFileAndUpdate(obj)
        this.loader = false
      }
    },
    async checkLicenceFileAndUpdate(obj) {
      return
      const companyHasProductID = this.configurationProductLevelStore.activeProduct.ID
      const configurationID = this.configurationProductLevelStore.activeProduct.ConfigurationID

      var idFromTable = companyHasProductID + '\\\\' + obj.ID
      var tableName = 'company_has_product'
      var resDataFile = await this.companyStore.getFileReferenceByTable(idFromTable, tableName, this.userStore.token)
      if (resDataFile.status === 200) {
        var resCreateLic = await this.configurationProductLevelStore.getLicenceByCombi(
          obj.ID,
          configurationID,
          this.userStore.token,
        )
      }
      Swal.fire({
        position: 'middle-end',
        icon: 'success',
        title: this.$t('success'),
        html: this.$t('hostIDUpdateSuccess'),
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.$t('ok'),
      }).then((result) => {
        this.$router.push({ name: 'Mdesign Overview' })
      })
    },
    checkScreenSize() {
      this.isSmallScreen = window.matchMedia('(max-width: 768px)').matches
      if (this.isSmallScreen) {
        this.isMediumScreen = false
        return
      }
      this.isMediumScreen = window.matchMedia('(max-width: 960px)').matches
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    addProduct() {
      this.$router.push({ name: 'Mdesign Configuration Add Product' })
    },
    addTemplate() {
      this.$router.push({ name: 'Mdesign Configuration Select' })
    },
    onFileChange(e) {
      if (this.readonly) return
      this.dragging = false
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const options = {
        ignoreAttributes: false,
        allowBooleanAttributes: true,
      }
      const file = files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        const xmlString = reader.result
        const result = XMLValidator.validate(xmlString, {
          allowBooleanAttributes: true,
        })
        if (result === true) {
          const parser = new XMLParser(options)
          const xmlDoc = parser.parse(xmlString)

          if (xmlDoc.LicenseRequest !== undefined) {
            xmlDoc.LicenseRequest['@_TS']
              ? (this.licenceCreatorModel.TerminalServer = true)
              : (this.licenceCreatorModel.TerminalServer = false)
            xmlDoc.LicenseRequest['@_VS']
              ? (this.licenceCreatorModel.VirtualServer = true)
              : (this.licenceCreatorModel.VirtualServer = false)
            this.licenceCreatorModel.OperationSystem = xmlDoc.LicenseRequest['@_OS']
            this.licenceCreatorModel.InstallationType = xmlDoc.LicenseRequest['@_InstallationType']
            this.licenceCreatorModel.Build = xmlDoc.LicenseRequest['@_Build']
            this.licenceCreatorModel.Version = parseFloat(xmlDoc.LicenseRequest['@_VERSIONPROJECT'])
            this.licenceCreatorModel.ServerName = xmlDoc.LicenseRequest['@_ServerName']
            this.licenceCreatorModel.PcName1 = xmlDoc.LicenseRequest['@_ServerName']
            this.licenceCreatorModel.ServerHostID = xmlDoc.LicenseRequest['@_ServerHostId']
            this.licenceCreatorModel.MacAddress1 = xmlDoc.LicenseRequest['@_ServerHostId']
            //this.licenceCreatorModel.CustomerNr = xmlDoc.LicenseRequest['@_agreement']
            this.licenceCreatorModel.CompanyName = xmlDoc.LicenseRequest['@_Company']
            this.licenceCreatorModel.CompanyNameShort = xmlDoc.LicenseRequest['@_Company']
            this.licenceCreatorModel.Email = xmlDoc.LicenseRequest.CompanyInfo['@_Email']
            this.licenceCreatorModel.HostIDCreateDate = this.companyStore.getReadableDate(
              xmlDoc.LicenseRequest['@_DATE'],
            )
          } else {
            this.fireFileError()
          }
        } else {
          this.fireFileError()
        }
      }
    },
    fireFileError() {
      Swal.fire({
        title: this.$t('error'),
        html: this.$t('wrongFormat'),
        icon: 'error',
        confirmButtonText: this.$t('ok'),
      })
    },
    toggleSwitch(value) {
      const date = this.companyStore.getInOneYear()
      switch (value) {
        case 'unlimited':
          this.licenceCreatorModel.Unbound ? (this.licenceCreatorModel.IsMacAddress = true) : undefined
          this.licenceCreatorModel.Unlimited ? (this.licenceCreatorModel.Unbound = false) : undefined
          this.licenceCreatorModel.Unlimited
            ? (this.licenceCreatorModel.Enddate = '9999-12-31')
            : (this.licenceCreatorModel.Enddate = date)
          break
        case 'macAddress':
          if (this.licenceCreatorModel.IsMacAddress) {
            this.licenceCreatorModel.IsPcName = false
            this.licenceCreatorModel.IsMacAddressAndPcName = false
            this.licenceCreatorModel.Triade = false
            this.licenceCreatorModel.Unbound = false
          }
          if (!this.oneSwitchTrue) this.licenceCreatorModel.IsMacAddress = true
          break
        case 'computerName':
          if (this.licenceCreatorModel.IsPcName) {
            this.licenceCreatorModel.IsMacAddress = false
            this.licenceCreatorModel.IsMacAddressAndPcName = false
            this.licenceCreatorModel.Triade = false
            this.licenceCreatorModel.Unbound = false
          }
          if (!this.oneSwitchTrue) this.licenceCreatorModel.IsPcName = true
          break
        case 'pcNameAndMac':
          if (this.licenceCreatorModel.IsMacAddressAndPcName) {
            this.licenceCreatorModel.IsMacAddress = false
            this.licenceCreatorModel.IsPcName = false
            this.licenceCreatorModel.Triade = false
            this.licenceCreatorModel.Unbound = false
          }
          if (!this.oneSwitchTrue) this.licenceCreatorModel.IsMacAddressAndPcName = true
          break
        case 'triad':
          if (this.licenceCreatorModel.Triade) {
            this.licenceCreatorModel.IsMacAddress = false
            this.licenceCreatorModel.IsPcName = false
            this.licenceCreatorModel.IsMacAddressAndPcName = false
            this.licenceCreatorModel.Unbound = false
          }
          if (!this.oneSwitchTrue) this.licenceCreatorModel.Triade = true
          break
        case 'unbound':
          if (this.licenceCreatorModel.Unbound) {
            this.licenceCreatorModel.IsMacAddress = false
            this.licenceCreatorModel.IsPcName = false
            this.licenceCreatorModel.IsMacAddressAndPcName = false
            this.licenceCreatorModel.Triade = false
            this.licenceCreatorModel.Unlimited = false
            this.licenceCreatorModel.Enddate = date
          }
          if (!this.oneSwitchTrue) this.licenceCreatorModel.Unbound = true
          break
      }
    },
    handleDragOver(event) {
      if (this.readonly) return
      event.preventDefault()
      this.dragging = true
    },
    handleDragLeave() {
      this.dragging = false
    },

    async setNewLicenceModel() {
      this.licenceCreatorModel.StartDate = this.companyStore.getToday()
      this.licenceCreatorModel.EndDate = this.companyStore.getInOneYear()
      this.licenceCreatorModel.HostIDCreateDate = this.companyStore.getReadableDate(this.companyStore.getToday())
      const companyName = this.companyStore.company.Name
      this.licenceCreatorModel.CompanyName = companyName
      this.licenceCreatorModel.CompanyNameShort = companyName
      this.licenceCreatorModel.CustomerNr = this.companyStore.company.CustomerNr
      this.licenceCreatorModel.IsMacAddress = true
      this.configurationProductLevelStore.licenceCreatorModel = this.licenceCreatorModel
    },
    backStep() {
      this.$router.back()
    },
  },
  setup() {
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    return { configurationProductLevelStore, companyStore, userStore }
  },
}
</script>

<style scoped>
@media (max-width: 600px) {
  .content-right {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 601px) {
  .content-right {
    display: flex;
    justify-content: flex-end;
  }
}
.btn-custom {
  width: 150px;
}
.spacer {
  min-height: 1rem !important;
}

.space-between {
  justify-content: space-between !important;
}

.btn-mr {
  margin-right: 1rem;
}

.edit-mdesign-licence {
  max-width: 1200px;
}

#licence-content {
  display: flex;
  justify-content: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.has-count {
  position: relative !important;
}
.md-count {
  position: absolute !important;
  top: -20px !important;
  left: 0;
}
</style>

<template>
  <div class="md-layout">
    <loader :loader="loader" />
    <two-fa-card header-color="primary">
      <div slot="title" header-color="primary" class="md-card-header-icon md-card-header-default">
        <img :src="logo" alt="MyMdesignLogo" class="img-title" />
      </div>
      <div slot="content" class="sucess-text">
        <div class="md-layout md-size-100">
          <h3 class="title text-center">
            {{ $t('tfaTitle') }}
          </h3>
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <label>{{ $t('authCode') }}</label>
              <md-input type="text" v-model="tfacode"></md-input>
            </md-field>
          </div>
        </div>
        <div class="remaining-time">
          <h4>{{ $t('remainingTime') }}</h4>
          <h4>{{ remainingTime !== -1 ? remainingTime : $t('linkExpired') }}</h4>
        </div>
      </div>
      <div class="md-layout" slot="footer">
        <div class="md-layout-item md-size-100 just-center">
          <div class="resend-info">
            <span
              >{{ $t('resendMail') }}<br />
              {{ $t('clickHere') }}
              <a @click="sendAgain">{{ $t('resendMailLink') }}</a>
            </span>
          </div>
        </div>
        <div class="md-layout-item md-size-100 just-center">
          <md-button class="md-primary md-round" @click="comfirmTfa" :disabled="remainingTime === -1">{{
            $t('confirm')
          }}</md-button>
        </div>
      </div>
    </two-fa-card>
  </div>
</template>

<script>
import Loader from '@/components/Loader/Loader.vue'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'
import MyMdesignLogo from './../../../public/img/my_mdesign_white.svg'
import TwoFaCard from '@/components/Cards/TwoFACard.vue'
import { httpPost, ApiNames } from '@/libs/httpHelper'
import { sendEmailTfaAuthentication } from '@/libs/emailSender'

export default {
  components: { TwoFaCard },
  data() {
    return {
      loader: false,
      tfacode: '',
      remainingTime: '',
      intervalId: null,
      logo: MyMdesignLogo,
    }
  },
  methods: {
    async comfirmTfa() {
      var reVerify = await this.userStore.verifyTFA(this.tfacode)
      if (reVerify.status === 200) {
        this.userStore.tfa.Verified = true
        if (this.userStore.valid && !this.userStore.role.includes('Admin')) {
          this.$router.push({ name: 'My Products' })
        } else {
          this.$router.push({ name: 'Companies' })
        }
      } else {
        Swal.fire({
          icon: 'error',
          html: this.$t('errorTfa'),
          animation: false,
          showConfirmButton: true,
        }).then(() => {
          this.$router.push({ name: 'Login' })
        })
      }
    },
    calculateRemainingTime() {
      const expirationTime = parseInt(this.userStore.tfa.Exp) * 1000 // von Sekunden zu Millisekunden
      const now = Date.now()
      const timeLeft = expirationTime - now

      if (timeLeft > 0) {
        const minutes = Math.floor((timeLeft / 1000 / 60) % 60)
        const seconds = Math.floor((timeLeft / 1000) % 60)

        const formSec = String(seconds).padStart(2, '0')
        this.remainingTime = `${minutes}min ${formSec}sec`
      } else {
        this.remainingTime = -1
        clearInterval(this.intervalId)
      }
    },
    async sendAgain() {
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var code = await this.userStore.getCodeTFA()
      if (code.status !== 200) return

      var decode = atob(code.data)
      decode = JSON.parse(decode)

      var resEmailtfa = await sendEmailTfaAuthentication(
        decode.code,
        this.userStore.userData.FirstName,
        this.userStore.userData.LastName,
        this.userStore.userData.Email,
      )
      if (resEmailtfa === 'Email is sended.') {
        this.userStore.tfa.Exp = decode.exp
        if (this.remainingTime === -1) {
          this.intervalId = setInterval(this.calculateRemainingTime, 1000)
          // this.calculateRemainingTime()
        }
        Swal.fire({
          icon: 'success',
          html: this.$t('successResendMail'),
          animation: false,
          showConfirmButton: true,
        })
      } else {
        Swal.fire({
          icon: 'error',
          html: this.$t('errorResendMail'),
          animation: false,
          showConfirmButton: true,
        })
      }
    },
  },

  async mounted() {
    this.loader = true
    const params = this.$route.query
    this.intervalId = setInterval(this.calculateRemainingTime, 1000)
    if (params.code) {
      this.tfacode = params.code
      this.comfirmTfa()
    }
    this.loader = false
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
}
</script>

<style scoped>
.sucess-text {
  text-align: center;
  font-size: 24px;
}

.sucess-text a {
  color: #009ee3;
}
.just-center {
  display: flex;
  justify-content: center !important;
}
h4 {
  margin: 0 auto;
}
.resend-info {
  text-align: center;
  margin-bottom: 15px;
}
.remaining-time {
  margin-top: 15px;
}
</style>

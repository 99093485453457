import DashboardLayout from '@/pages/UserLayout/DashboardLayout.vue'
import User from '@/pages/UserProfile/EditProfileForm.vue'
import Password from '@/pages/Password/UserPassword.vue'
import Login from '@/pages/Login/Login.vue'
import AuthLayout from '@/pages/Login/AuthLayout.vue'
import ContactsList from '@/pages/Contacts/ContactsList.vue'
import Registration from '@/pages/Registration/Registration.vue'
import ConfirmationRegistration from '@/pages/Registration/ConfirmationRegistration.vue'
import ForgotPassword from '@/pages/Password/ForgotPassword.vue'
import MyProducts from '@/pages/Products/ProductsComponent/ProductsList.vue'
import RoloffMatek from '@/pages/Products/UserProducts/RoloffMatek.vue'
import Student from '@/pages/Products/UserProducts/Student.vue'
import Orders from '@/pages/Orders/Orders.vue'
import SetNewPassword from '@/pages/Password/SetNewPassword.vue'
import CompanyContactsList from '@/pages/CompanyContacts/CompanyContactsList.vue'
import Companies from '@/pages/CompanyList/CompanyList.vue'
import Company from '@/pages/CompanyProfile/EditCompanyProfile.vue'
import CreateCompany from '@/pages/CreateNewCompany/CreateNewCompany.vue'
import CreateCompanyContact from '@/pages/CompanyContacts/CreateNewCustomerContact.vue'
import EditCompanyContact from '@/pages/CompanyContacts/EditCompanyContactProfile.vue'
import CompanyProducts from '@/pages/Products/ProductsComponent/ProductsList.vue'
import ContactProducts from '@/pages/Products/ProductsComponent/ProductsList.vue'
import AllProducts from '@/pages/Products/ProductsComponent/ProductsList.vue'
import EditContact from '@/pages/Contacts/EditContactProfile.vue'
import CreateNewContact from '@/pages/Contacts/CreateNewContact.vue'
import EditAwsAccount from '@/pages/AwsAccount/AwsAccount.vue'
import StudentEmailConfirmation from '@/pages/Products/UserProducts/StudentEmailConfirmation.vue'
import EditAwsUsers from '@/pages/AwsAccount/EditAwsUsers.vue'
import AwsLogin from '@/pages/AwsLogin/AwsLogin.vue'
import AwsForgotPassword from '@/pages/AwsLogin/AwsForgotPassword.vue'
import AwsSelect from '../pages/AwsAccount/AwsSelect.vue'
import CreateAwsConfig from '../pages/AwsAccount/CreateAwsConfig.vue'
import MdesignLicenceCreator from '../pages/Products/Mdesign/MdesignLicenceCreator.vue'
import MdesignConfiguration from '../pages/Products/Mdesign/MdesignConfiguration.vue'
import MdesignConfigurationSelect from '../pages/Products/Mdesign/MdesignConfigurationSelect.vue'
import MdesignConfigurationAddProduct from '../pages/Products/Mdesign/MdesignConfigurationAddProduct.vue'
import MdesignOverview from '../pages/Products/Mdesign/MdesignOverview.vue'
import EditProductForm from '@/pages/Products/ProductsComponent/EditProductForm.vue'
import EditConfigurationProductLevel from '@/pages/Products/ProductsComponent/EditConfigurationProductLevel.vue'
import ConfigurationProtocol from '@/pages/Products/Mdesign/ConfigurationProtocol.vue'
import RolesTable from '@/pages/RoleManagement/RolesTable.vue'
import EditRole from '@/pages/RoleManagement/EditRole.vue'
import TwoFactorAuthCode from '@/pages/TwoFactorAuth/TwoFactorAuthCode.vue'
import ResetLockout from '@/pages/Lockout/ResetLockout.vue'
import Student2022 from '@/pages/Products/UserProducts/Student2022.vue'
import ConfirmCompanyContact from '@/pages/Registration/ConfirmCompanyContact.vue'
import AddStudentDomain from '@/pages/UniversityManagement/AddStudentDomain.vue'
import ComanyCloudList from '@/pages/CompanyList/ComanyCloudList.vue'
import CreateEvent from '@/pages/Events/CreateEvent.vue'
let pagesMenu = {
  path: '/',
  component: DashboardLayout,
  name: 'Pages',
  children: [
    {
      path: '/password',
      name: null,
      components: { default: Password },
    },
    {
      path: '/user',
      name: 'User',
      component: User,
    },
    {
      path: '/orders',
      name: 'Orders',
      component: Orders,
    },
    {
      path: '/contacts',
      name: 'Contacts List',
      meta: {
        permissions: ['{367b3416-5022-4a41-bb19-e4c4dd51ddb3}'],
      },
      component: ContactsList,
    },
    {
      path: '/company_contacts',
      name: 'Company Contacts List',
      component: CompanyContactsList,
    },
    {
      path: '/companies',
      name: 'Companies',
      meta: {
        permissions: ['{5b5bc606-7854-4e66-ab3d-3015ed6a43f3}'],
      },
      component: Companies,
    },
    {
      path: '/cloudcompanies',
      name: 'Cloud Companies',
      meta: {
        permissions: ['{5b5bc606-7854-4e66-ab3d-3015ed6a43f3}'],
      },
      component: ComanyCloudList,
    },
    {
      path: '/company',
      name: 'Company',
      meta: {
        permissions: ['{5b5bc606-7854-4e66-ab3d-3015ed6a43f3}'],
      },
      component: Company,
    },
    {
      path: '/createcompany',
      name: 'Create Company',
      meta: {
        permissions: ['{4e2393ce-bbd5-4347-a68d-e8869353c7b2}'],
      },
      component: CreateCompany,
    },
    {
      path: '/createcompanycontact',
      name: 'Create Company Contact',
      meta: {
        permissions: ['{d77ac470-301f-4631-b33a-d3cae6844c8c}'],
      },
      component: CreateCompanyContact,
    },
    {
      path: '/editcompanycontact',
      name: 'Edit Company Contact',
      meta: {
        permissions: ['{66f669e2-c677-436c-9afa-0bb0c7f95633}'],
      },
      component: EditCompanyContact,
    },
    {
      path: '/contactproducts',
      name: 'Contact Products',
      component: ContactProducts,
    },
    {
      path: '/editcontact',
      name: 'Edit Contact',
      meta: {
        permissions: ['{66f669e2-c677-436c-9afa-0bb0c7f95633}', '{367b3416-5022-4a41-bb19-e4c4dd51ddb3}'],
      },
      component: EditContact,
    },
    {
      path: '/createnewcontact',
      name: 'Create New Contact',
      meta: {
        permissions: ['{d77ac470-301f-4631-b33a-d3cae6844c8c}'],
      },
      component: CreateNewContact,
    },
    {
      path: '/awsaccount',
      name: 'Aws Account',
      meta: {
        permissions: ['{1a0be966-f650-45a4-a8b6-2f4aa41f55ca}'],
      },
      component: EditAwsAccount,
    },
    {
      path: '/editawsusers',
      name: 'Edit Aws Users',
      meta: {
        permissions: ['{d77ac470-301f-4631-b33a-d3cae6844c8c}'],
      },
      component: EditAwsUsers,
    },
    {
      path: '/awsselect',
      name: 'Aws Select',
      meta: {
        permissions: ['{1a0be966-f650-45a4-a8b6-2f4aa41f55ca}'],
      },
      component: AwsSelect,
    },
    {
      path: '/createawsconfig',
      name: 'Create Aws Config',
      meta: {
        permissions: ['{60d57c5c-bd17-405c-9e1e-290038937b07}'],
      },
      component: CreateAwsConfig,
    },
    {
      path: 'createevent',
      name: 'Create Event',
      meta: {},
      component: CreateEvent,
    },
  ],
}
let productsMenu = {
  path: '/products',
  component: DashboardLayout,
  name: 'ProductsData',
  redirect: '/products/myproducts',

  children: [
    {
      path: 'myproducts',
      name: 'My Products',
      meta: {
        permissions: ['{832fc4e4-1ca0-4830-a020-23282203749d}'],
      },
      component: MyProducts,
    },
    {
      path: 'companyproducts',
      name: 'Company Products',
      meta: {
        permissions: ['{1a0be966-f650-45a4-a8b6-2f4aa41f55ca}'],
      },
      component: CompanyProducts,
    },
    {
      path: 'RoloffMatek',
      name: 'RoloffMatek',
      component: RoloffMatek,
    },
    {
      path: 'Student',
      name: 'Student Product',
      component: Student,
    },
    {
      path: 'student2022',
      name: 'Student 2022',
      component: Student2022,
    },
    {
      path: 'editproduct',
      name: 'Edit Product',
      meta: {
        permissions: ['{9867a942-1704-4b2c-ad63-b369d0405856}'],
      },
      component: EditProductForm,
    },
    {
      path: 'allproducts',
      name: 'All Products',
      meta: {
        permissions: ['{9867a942-1704-4b2c-ad63-b369d0405856}'],
      },
      component: AllProducts,
    },
    {
      path: 'editconfigurationproductlevel',
      name: 'Edit Configuration Product Level',
      meta: {
        permissions: ['{9867a942-1704-4b2c-ad63-b369d0405856}'],
      },
      component: EditConfigurationProductLevel,
    },
  ],
}

let mdesignConfiguration = {
  path: '/mdesignconfiguration',
  component: DashboardLayout,
  name: 'Mdesign Configuration',
  redirect: '/mdesignconfiguration/licencecreator',

  children: [
    {
      path: 'licencecreator',
      name: 'Mdesign Licence Creator',
      meta: {
        permissions: ['{25d56e8b-9168-4664-8990-127bd761911e}'],
      },
      component: MdesignLicenceCreator,
    },
    {
      path: 'select',
      name: 'Mdesign Configuration Select',
      meta: {
        permissions: ['{25d56e8b-9168-4664-8990-127bd761911e}'],
      },
      component: MdesignConfigurationSelect,
    },
    {
      path: 'manager',
      name: 'Mdesign Configuration Manager',
      meta: {
        permissions: ['{25d56e8b-9168-4664-8990-127bd761911e}'],
      },
      component: MdesignConfiguration,
    },
    {
      path: 'addproduct',
      name: 'Mdesign Configuration Add Product',
      meta: {
        permissions: ['{25d56e8b-9168-4664-8990-127bd761911e}'],
      },
      component: MdesignConfigurationAddProduct,
    },
    {
      path: 'overview',
      name: 'Mdesign Overview',
      meta: {
        permissions: ['{25d56e8b-9168-4664-8990-127bd761911e}'],
      },
      component: MdesignOverview,
    },
    {
      path: 'configurationprotocol',
      name: 'Configuration Protocol',
      meta: {
        permissions: ['{25d56e8b-9168-4664-8990-127bd761911e}'],
      },
      component: ConfigurationProtocol,
    },
  ],
}

let rolemanagement = {
  path: '/management',
  component: DashboardLayout,
  name: 'Role Management',
  redirect: '/management/rolestable',

  children: [
    {
      path: 'rolestable',
      name: 'Roles Table',
      meta: {
        permissions: ['{b6a393e9-33a0-424c-bdb9-b4a61ce9ee23}'],
      },
      component: RolesTable,
    },
    {
      path: 'editrole',
      name: 'Edit Role',
      meta: {
        permissions: ['{b6a393e9-33a0-424c-bdb9-b4a61ce9ee23}'],
      },
      component: EditRole,
    },
    {
      path: 'addstudentdomain',
      name: 'Add Student Domain',
      meta: {
        permissions: ['{7570126c-4a71-4971-aa7f-950977f49729}'],
      },
      component: AddStudentDomain,
    },
  ],
}

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Login',
  },
  pagesMenu,
  productsMenu,
  mdesignConfiguration,
  rolemanagement,
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        components: { default: Login },
      },
      {
        path: '/registration',
        name: 'Registration',
        components: { default: Registration },
      },
      {
        path: '/confirmationRegistration',
        name: 'Confirmation Registration',
        components: { default: ConfirmationRegistration },
      },
      {
        path: '/forgotPassword',
        name: 'Forgot Password',
        components: { default: ForgotPassword },
      },
      {
        path: '/setNewPassword',
        name: 'Set New Password',
        components: { default: SetNewPassword },
      },
      {
        path: '/confirmationStudentEmail',
        name: 'Student Email Confirmation',
        components: { default: StudentEmailConfirmation },
      },
      {
        path: '/awslogin',
        name: 'Aws Login',
        component: AwsLogin,
      },
      {
        path: '/awsforgotpassword',
        name: 'Aws Forgot Password',
        component: AwsForgotPassword,
      },
      {
        path: '/entertfacode',
        name: 'Enter Tfa Code',
        component: TwoFactorAuthCode,
      },
      {
        path: '/resetlockout',
        name: 'Reset Lockout',
        component: ResetLockout,
      },
      {
        path: '/confirmcompanycontact',
        name: 'Confirm Company Contact',
        component: ConfirmCompanyContact,
      },
    ],
  },
]

export default routes

<template>
  <md-card class="md-card-tfa" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header :class="getClass(headerColor)">
      <slot name="title"></slot>
    </md-card-header>

    <md-card-content>
      <slot name="content"></slot>
    </md-card-content>

    <md-card-actions>
      <slot name="footer"></slot>
    </md-card-actions>
  </md-card>
</template>

<script>
export default {
  name: 'tfa',
  props: {
    headerColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cardHidden: true,
    }
  },
  beforeMount() {
    setTimeout(this.showCard, 400)
  },
  methods: {
    showCard: function () {
      this.cardHidden = false
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
  },
}
</script>

<style lang="scss">
[data-background-color='white'] {
  .md-card-tfa {
    h3 {
      color: black !important;
    }
  }
}
.md-card-tfa {
  max-width: 380px;
  .md-card-header {
    margin-top: -40px;
    margin-bottom: 20px;
  }
  .md-card-actions {
    border-top: none !important;
  }
}
</style>

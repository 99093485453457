<template>
  <ValidationObserver ref="observer">
    <form @submit.prevent="handleSubmit" class="change-pass-card">
      <md-card class="change-pass-card">
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>https</md-icon>
          </div>
          <h4 class="title">{{ $t('changePassword') }}</h4>
        </md-card-header>

        <md-card-content>
          <ValidationProvider
            name="oldpassword"
            :rules="{
              required: true,
              regex: regExValid,
            }"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t('oldPassword') }}</label>
              <md-input v-model="oldpassword" type="password"> </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>

          <ValidationProvider
            name="password"
            :rules="{
              required: true,
              regex: regExValid,
            }"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t('newPassword') }}</label>
              <md-input v-model="password" type="password"> </md-input>

              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <div :class="[{ 'show-validation': oldAndNewPasswordAreTheSame }, { 'password-validation': true }]">
            {{ $t('newAndOldPasswordAreSame') }}
          </div>
          <div>{{ $t('setPasswordRule') }}</div>
        </md-card-content>

        <md-card-actions>
          <md-button type="submit" class="md-primary update-password md-round" :disabled="isFormValid">
            {{ $t('updatePassword') }}
          </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from 'vee-validate'
import { required, confirmed, regex } from 'vee-validate/dist/rules'
import { useUserStore } from '../../stores/user'
extend('required', required)
extend('confirmed', confirmed)
extend('regex', regex)
import Swal from 'sweetalert2'
import { httpPost, ApiNames } from '../../libs/httpHelper'

export default {
  data() {
    return {
      oldpassword: '',
      password: '',
      regExValid: /.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[&"'(\-_)=~#{[|`\\^@\]}^$*¨£µ%,;:!?./§+]).*/,
      isFormValid: true,
      oldAndNewPasswordAreTheSame: false,
    }
  },
  methods: {
    async handleSubmit() {
      const oldPasswordValidation = await this.userStore.checkOldPassowrd(
        this.userStore.userData.Email,
        this.oldpassword,
      )
      if (
        oldPasswordValidation.data.Message != 'wrong password' &&
        oldPasswordValidation.data.Message != 'The request is invalid.'
      ) {
        var obj = {
          Email: this.userStore.userData.Email,
          Password: this.password,
        }

        var base64 = btoa(JSON.stringify(obj))

        const customConfig = {
          headers: {
            'Content-Type': 'application/json',
          },
        }

        var setNewPassword = await httpPost(ApiNames.resetpassword, base64, customConfig)

        if ((setNewPassword.status = 200)) {
          Swal.fire({
            icon: 'success',
            title: this.$t('success'),
            text: this.$t('passwordChanged'),
          })
          this.$router.push({ name: 'Login' })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.$t('profileNotUpdated'),
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('oldPasswordIsNotCorrect'),
        })
      }
    },
  },

  watch: {
    async password() {
      const newPasswordUnique = this.password !== this.oldpassword

      const passRegValid = await this.$refs.observer.validate()

      if (passRegValid && newPasswordUnique) {
        this.isFormValid = false
      }
      if (!passRegValid || !newPasswordUnique) {
        this.isFormValid = true
      }
      if (!this.password && !this.oldpassword) {
        this.oldAndNewPasswordAreTheSame = false
      } else if (this.password === this.oldpassword) {
        this.oldAndNewPasswordAreTheSame = true
      } else {
        this.oldAndNewPasswordAreTheSame = false
      }
    },
    async oldpassword() {
      const newPasswordUnique = this.password !== this.oldpassword
      const passRegValid = await this.$refs.observer.validate()
      if (passRegValid && newPasswordUnique) {
        this.isFormValid = false
      }
      if (!passRegValid || !newPasswordUnique) {
        this.isFormValid = true
      }
      if (this.password === this.oldpassword) {
        this.oldAndNewPasswordAreTheSame = true
      } else {
        this.oldAndNewPasswordAreTheSame = false
      }
    },
  },

  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  created: function () {
    if (!this.userStore.valid) {
      this.$router.push({ name: 'Login' })
    }
  },
  beforeMount() {
    this.$changePageTitle(this.$t('pageTitleChangePassword'))
  },
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

form {
  margin: 0;
}

.password-validation {
  display: none;
  color: red;
}

.show-validation {
  display: block;
}
.change-pass-card {
  display: flex;
  justify-content: center;
}
.md-layout {
  display: flex !important;
  justify-content: center !important;
}
</style>

<template>
  <md-field class="country">
    <label for="country">{{ $t('country') }}</label>
    <md-select v-model="country" name="country" id="country" @click="addCustomClass()">
      <md-option v-for="c in this.countries" :value="c.value" :key="c.value" class="country-option">{{
        c.label
      }}</md-option>
    </md-select>
  </md-field>
</template>

<script>
import { useUserStore } from '../../stores/user'
import { countriesEN, countriesDE } from './getCountries.js'

export default {
  name: 'select-country',
  props: {
    value: String,
  },
  data() {
    return {
      country: '',
      countries: [{}],
    }
  },
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  beforeMount() {
    this.countries = this.$i18n.locale === 'de-DE' ? countriesDE : countriesEN
    if (this.value) this.country = this.value
    if (this.value === '') this.country = 'DE'
    document.documentElement.style.setProperty(
      '--list-background',
      this.userStore.theme === 'black' ? '#b5b5b5' : '#fff',
    )
  },
  methods: {
    addCustomClass() {
      setTimeout(function () {
        const selectMenu = document.querySelector('.md-select-menu')
        if (selectMenu) selectMenu.classList.add('country-select-menu')
      }, 0)
    },
  },
  watch: {
    country(newValue, oldValue) {
      var con = this.countries.find((c) => c.value === newValue)
      if (this.$route.fullPath === '/user') this.userStore.userAddress.Country = con.value
      this.$emit('changeCountry', con)
    },
    'userStore.theme': function (newTheme, oldTheme) {
      document.documentElement.style.setProperty('--list-background', newTheme === 'black' ? '#b5b5b5' : '#fff')
    },
  },
  updated() {
    this.countries = this.$i18n.locale === 'de-DE' ? countriesDE : countriesEN
  },
}
</script>
<style scoped lang="scss">
.drop-down {
  margin-right: 20px;
}

:root {
  --list-background: #fff;
  /* Default background color */
}
</style>

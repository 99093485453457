<template>
  <div></div>
</template>

<script>
import { useUserStore } from '../../stores/user'

export default {
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  mounted() {
    if (this.userStore.role === 'Student' || this.userStore.role === 'User') this.$router.push({ name: 'Login' })
  },
}
</script>

<style scoped></style>

<template>
  <div class="md-layout md-size-33 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
    <loader :loader="loader" />
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-default">
        <h2 class="title text-center">
          {{ $t('resetTitle') }}
        </h2>
      </md-card-header>
      <md-card-content class="sucess-text">
        <h3 class="text-center">
          {{ $t('resetText') }}
        </h3>

        <ValidationObserver v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit()">
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }" class="text-center">
              <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, 'password-input']" required>
                <label>{{ $t('email') }}</label>
                <md-input v-model="email" type="email" v-on:keyup.enter="submitResetEmail()"> </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            <div class="button-container">
              <md-button
                class="md-primary md-round mt-4"
                slot="footer"
                :disabled="invalid"
                @click.native="submitResetEmail()"
                >{{ $t('submit') }}</md-button
              >
            </div>
          </form>
        </ValidationObserver>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { httpGetTokenAws, httpPostAwsResetPw } from '../../libs/httpHelper'
import Swal from 'sweetalert2'
import { errors } from 'jose'

extend('required', required)

export default {
  data() {
    return {
      loader: false,
      email: 'simon.kissling@tedata.de',
      errors: [
        {
          errCode: 1,
          text: this.$t('err_user_not_found'),
        },
        {
          errCode: 2,
          text: this.$t('err_user_AWS'),
        },
      ],
    }
  },
  methods: {
    async submitResetEmail() {
      var response = await httpGetTokenAws()

      if (response.status !== 200) {
        Swal.fire({
          icon: 'error',
          html: this.$t('networkErrorMsg'),
          animation: false,
          showConfirmButton: true,
        }).then(() => {
          return
        })
      }

      var token = response.data

      var data = {
        email: this.email,
        betreff: this.$t('restoreMailRef'),
      }

      var response = await httpPostAwsResetPw(data, token)

      if (response.data.errCode > 0) {
        var msg = this.errors.find((err) => err.errCode === response.data.errCode)
        Swal.fire({
          icon: 'error',
          html: msg.text,
          animation: false,
          showConfirmButton: true,
        }).then(() => {
          return
        })
      }
    },
  },
}
</script>

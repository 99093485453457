<template>
  <div class="md-layout">
    <loader :loader="loader" />

    <modal v-if="showCreatePermission" @close="closeModal" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('addPermissionTitle') }}</h4>
      </template>
      <template slot="footer">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-button class="md-simple md-round" @click="closeModal">{{ $t('cancel') }}</md-button>
          </div>
          <div class="md-layout-item md-size-50 text-right">
            <md-button class="md-primary md-round" @click="handleCreatePermission">{{ $t('save') }}</md-button>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field>
              <label for="">{{ $t('name') }}</label>
              <md-input type="text" v-model="addPermission.Name"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <label for="">{{ $t('description') }}</label>
              <md-textarea v-model="addPermission.Description"></md-textarea>
            </md-field>
          </div>
        </div>
      </template>
    </modal>

    <div class="md-layout-item md-size-50">
      <ValidationObserver v-slot="{ invalid }">
        <form>
          <loader :loader="loader" />
          <md-card class="md-layout-item card-content edit-role-card">
            <md-card-header class="md-card-header-icon header-style md-card-header-primary">
              <div>
                <div class="card-icon">
                  <md-icon>vpn_key</md-icon>
                </div>
                <h3 class="title">
                  {{ cardTitle }}
                </h3>
                <h4>{{ $t('createProductText') }}</h4>
              </div>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <ValidationProvider name="roleN" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('roleName') }}</label>
                      <md-input v-model="mdRole.Name" type="text"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100">
                  <ValidationProvider name="roleDesc" rules="required" v-slot="{ passed, failed }">
                    <md-field class="md-has-value no-bg" :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('roleDescription') }}</label>
                      <md-textarea v-model="mdRole.Description"></md-textarea>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
                <div v-if="!edit" class="md-layout-item md-size-100 text-right pt-15">
                  <md-button :disabled="invalid" class="md-primary md-round" @click="handleCreate"
                    >{{ $t('save') }}
                  </md-button>
                </div>
                <div v-else class="md-layout-item md-size-100 text-right pt-15">
                  <md-button :disabled="invalid" class="md-primary md-round" @click="handleSave"
                    >{{ $t('save') }}
                  </md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </ValidationObserver>
    </div>

    <div v-if="this.mdRole.ID" class="md-layout-item md-size-50" :key="renderKey">
      <role-permissions @showModal="openModal" :role="this.mdRole"></role-permissions>
    </div>
  </div>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import { useManagementStore } from '@/stores/management'
import RolePermissions from './RolePermissions.vue'
import Swal from 'sweetalert2'
import Modal from '@/components/Modal.vue'
import { extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'

export default {
  props: {},
  components: { RolePermissions, Modal },
  data() {
    return {
      loader: false,
      renderKey: 0,
      cardTitle: '',
      edit: false,
      mdRole: {},
      showCreatePermission: false,
      addPermission: {},
    }
  },
  async beforeMount() {
    this.loader = true
    if (!this.userStore.role.includes('Admin')) this.$router.push({ name: 'Companies' })
    if (this.managementStore.manageRole.edit) {
      this.mdRole = this.managementStore.manageRole.role
      this.edit = true
    }

    this.cardTitle = this.edit ? this.$t('editRoleCardTitle') : this.$t('createRoleCardTitle')
    const translateKey = this.edit ? this.$t('pageTitleEditRole') : this.$t('pageTitleCreateRole')
    this.$changePageTitle(this.$t(translateKey))

    this.loader = false
  },
  methods: {
    handleCreate() {
      Swal.fire({
        title: this.$t('askCreateRole'),
        html: this.mdRole.Name,
        icon: 'question',
        confirmButtonText: this.$t('ok'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.createRole()
        }
      })
    },
    async createRole() {
      this.mdRole.ID = ''
      var res = await this.managementStore.createUserRole(this.mdRole, this.userStore.token)
      if (res.status === 200) {
        Swal.fire({
          title: this.$t('succesCreateRole'),
          html: this.mdRole.Name,
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        })
        this.mdRole.ID = res.data
        this.managementStore.manageRole = {
          edit: true,
          role: this.mdRole,
        }
        this.edit = true
        this.renderKey++
      } else {
        Swal.fire({
          title: this.$t('errorCreateRole'),
          html: res.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      }
    },
    handleSave() {
      Swal.fire({
        title: this.$t('askUpdateRole'),
        html: this.mdRole.Name,
        icon: 'question',
        confirmButtonText: this.$t('ok'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateRole()
        }
      })
    },
    async updateRole() {
      var res = await this.managementStore.updateUserRole(this.mdRole, this.userStore.token)
      if (res.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('succesUpdateRole'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        })
      } else {
        Swal.fire({
          title: this.$t('errorUpdateRole'),
          html: res.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      }
    },
    async handleCreatePermission() {
      var res = await this.managementStore.createPermission(this.addPermission, this.userStore.token)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          html: this.$t('successCreatePermission'),
          showConfirmButton: true,
        })
        this.$refs.RolePermissions.getAllPermissions()
      } else {
        Swal.fire({
          icon: 'error',
          text: this.$t('errorCreatePermission'),
          showConfirmButton: true,
        })
      }

      this.addPermission = {}
      this.closeModal()
    },
    closeModal() {
      this.addPermission = {}
      this.showCreatePermission = false
    },
    openModal() {
      this.showCreatePermission = true
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const managementStore = useManagementStore()
    return { companyStore, userStore, configurationProductLevelStore, managementStore }
  },
}
</script>

<style scoped lang="scss">
.pt-15 {
  padding-top: 15px;
}
textarea {
  padding-left: 0 !important;
}
.md-field {
  &.no-bg:after {
    background: transparent !important;
  }
}
</style>

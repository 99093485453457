<template>
  <ValidationObserver v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="md-layout-item md-medium-size-66 md-size-66 md-small-size-100 center-located">
        <loader :loader="loader" />
        <md-card class="card-content">
          <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
            <div class="card-icon">
              <md-icon>factory</md-icon>
            </div>
            <h4 class="title">
              {{ $t('customerProfileTitle') }}
            </h4>
            <!-- <h3>{{ $t("profileText") }}</h3> -->
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('vatNr') }}</label>
                  <md-input v-model="company.VATnr" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="customerNr" rules="required|numeric" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('customerNumber') }}</label>
                      <md-input v-model="company.CustomerNr" type="text" required></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="companyName" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('companyName') }}</label>
                      <md-input v-model="company.Name" type="text" required></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="companyType" rules="required" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label for="companyType">{{ $t('companyType') }}</label>
                    <md-select v-model="company.CompanyTypeID" name="companyType" id="companyType" required>
                      <md-option v-for="cType in companyTypesData" :value="cType.ID" :key="cType.ID">{{
                        cType.Name
                      }}</md-option>
                    </md-select>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <div>
                  <ValidationProvider name="companyStreet" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('street') }}</label>
                      <md-input v-model="company.Street" type="text" required></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <div>
                  <ValidationProvider name="companyHsNo" rules="required|numeric" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('houseNumber') }}</label>
                      <md-input v-model="company.Housenumber" type="text" required></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <div>
                  <ValidationProvider name="zipcode" rules="required|numeric" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label> {{ $t('zipCode') }} </label>
                      <md-input v-model="company.ZipCode" type="text" required> </md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="companyCity" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('city') }}</label>
                      <md-input v-model="company.City" type="text" required></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <select-country :value="company.Language.toUpperCase()" @changeCountry="handleCountry"></select-country>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="email" rules="email" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('email') }}</label>
                    <md-input v-model="company.Email" type="email"> </md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('phoneNumber') }}</label>
                  <md-input v-model="company.Phone" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('faxNumber') }}</label>
                  <md-input v-model="company.Fax" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('website') }}</label>
                  <md-input v-model="company.Website" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>{{ $t('serviceContract') }}</label>
                  <md-select v-model="company.ServiceContract" name="serviceContract" id="serviceContract">
                    <md-option value="true">{{ $t('yes') }}</md-option>
                    <md-option value="false">{{ $t('no') }}</md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field v-if="company.ServiceContract === 'true'">
                  <label>{{ $t('serviceContractStart') }}</label>
                  <md-input type="date" v-model="company.ServiceContractStart" />
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33 has-icon">
                <md-field v-if="company.ServiceContract === 'true'">
                  <label>{{ $t('serviceContractEnd') }}</label>
                  <md-input
                    v-if="company.ServiceContractEnd !== '9999-12-31'"
                    type="date"
                    v-model="company.ServiceContractEnd"
                  />
                  <md-input type="text" v-else :value="$t('unlimited')" disabled></md-input>
                  <div
                    class="datepicker-action-icon"
                    :class="{
                      selected: company.ServiceContractEnd === '9999-12-31',
                      'not-selected': company.ServiceContractEnd !== '9999-12-31',
                    }"
                  >
                    <span class="animated-icon" @click="setUnlimited"> hourglass_full </span>
                    <span id="tooltip1" class="tooltip">{{ $t('unlimited') }}</span>
                  </div>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('createdBy') }}</label>
                  <md-input v-model="company.CreatedBy" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('createdOn') }}</label>
                  <md-input v-model="company.Created" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-size-100 text-right">
                <md-button id="submit" class="md-raised md-primary mt-4" @click="askCreate()" :disabled="invalid"
                  >{{ $t('save') }}
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import SelectCountry from '../../components/SelectCountry/SelectCountry.vue'
import { useCompanyStore } from '../../stores/company'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

export default {
  components: { SelectCountry, ValidationObserver, ValidationProvider },
  name: 'create-company-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()

    return { companyStore, userStore }
  },
  data() {
    return {
      company: {
        ID: '',
        CompanyTypeID: '',
        CustomerNr: '',
        VATnr: '',
        Name: '',
        Street: '',
        Housenumber: '',
        ZipCode: '',
        City: '',
        Country: '',
        Phone: '',
        Fax: '',
        Email: '',
        Website: '',
        Language: '',
        Image: 0,
        ServiceContract: false,
        ServiceContractStart: '',
        ServiceContractEnd: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      companyTypesData: [
        {
          ID: '{32456e70-c313-4ca5-ac48-e4980fff092f}',
          Name: this.$t('vocationalSchool'),
          key: 'vocationalSchool',
        },
        {
          ID: '{4e3e30e5-582e-4392-9d6a-8e3510518c08}',
          Name: this.$t('company'),
          key: 'company',
        },
        {
          ID: '{5e0c53b2-f4bd-45e4-8993-418a267d7091}',
          Name: this.$t('university'),
          key: 'university',
        },
        {
          ID: '{9a4d6dd7-ea25-4ebf-8dc5-dd44fb9b1262}',
          Name: this.$t('college'),
          key: 'college',
        },
        {
          ID: '{cd0b2330-4476-4af0-b3c1-b12b4598967f}',
          Name: this.$t('groupOfCompanies'),
          key: 'groupOfCompanies',
        },
      ],
      loader: false,
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateCompanyTypesData()
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.$changePageTitle(this.$t('createNewCompany'))
    this.updateCompanyTypesData()
    if (this.$route.params.companyTypes) {
      this.companyTypes = this.$route.params.companyTypes
      this.company.CreatedBy = this.userStore.userData.LastName
      this.company.ServiceContract = 'true'
      this.company.ServiceContractStart = this.companyStore.formatDate(new Date()).split('T')[0]
      this.company.ServiceContractEnd = this.companyStore.formatDate(new Date()).split('T')[0]
      this.company.Created = this.companyStore.formatDate(new Date())
      // this.company.Country =
    } else {
      this.$router.push({ name: 'Companies' })
    }
  },
  methods: {
    setUnlimited() {
      this.company.ServiceContractEnd =
        this.company.ServiceContractEnd === '9999-12-31'
          ? this.companyStore.formatDate(new Date()).split('T')[0]
          : '9999-12-31'
    },
    updateCompanyTypesData() {
      this.companyTypesData.forEach((type) => {
        type.Name = this.$t(type.key)
      })
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    handleCountry(val) {
      this.company.Language = val.value.toLowerCase()
      this.company.Country = val.label
    },
    askCreate() {
      Swal.fire({
        title: this.$t('askCreateNewCompany'),
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t('cancel'),
        confirmButtonText: this.$t('confirm'),
      }).then((result) => {
        if (result.dismiss) {
          return
        } else {
          this.createNewCompany()
        }
      })
    },
    async createNewCompany() {
      this.company.CreatedBy = this.userStore.userData.LastName
      this.company.Created = this.companyStore.formatDate(new Date())
      this.company.ModifiedBy = this.userStore.userData.LastName
      this.company.Modified = this.companyStore.formatDate(new Date())

      this.company.ServiceContractStart =
        this.company.ServiceContract === 'true' ? this.company.ServiceContractStart : '1970-01-01'
      this.company.ServiceContractEnd =
        this.company.ServiceContract === 'true' ? this.company.ServiceContractEnd : '1970-01-01'

      this.loader = true
      var res = await this.companyStore.createCompany(this.company, this.userStore.token)
      this.loader = false

      if (res.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('companyWasCreated'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        }).then((result) => {
          this.company.ID = res.data
          this.companyStore.company = this.company
          this.$router.push('company')
        })
      } else {
        var error =
          res.data.Message === 'This company is already exists' ? this.$t('companyAlredyExists') : res.data.Message
        Swal.fire({
          title: this.$t('error'),
          html: error,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      }
    },
  },
}
</script>

<style scoped>
.center-located {
  margin: 0 auto;
}
.datepicker-action-icon {
  height: fit-content !important;
  max-height: 26px !important;
  margin: auto;
}
.tooltip {
  visibility: hidden;
  position: absolute;
  width: auto;
  max-width: 200px; /* Adjust as needed */
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 1000;
  left: 100%;
  top: -70%;
  font-size: 13px;
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.datepicker-action-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>

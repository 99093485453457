import Vue from 'vue'

export function unsetStatsGA() {
  // Find the scipt tag with the Google Analytics code
  const scripts = document.querySelectorAll('script[src^="https://www.googletagmanager.com/gtag/js?id="]')
  scripts.forEach((script) => {
    // Modify the script tag attributes
    script.remove()
  })

  let environment = window.location.hostname
  let analyticsID = 'NJL0T2PYH0' // Default for test environment
  if (environment === 'my.mdesign.de') {
    analyticsID = 'B0R9Z0F5TL'
  }
  Vue.$cookies.remove('_ga_' + analyticsID)
  if (window.dataLayer) {
    window.dataLayer = []
  }
}
export function changePageTitle(title) {
  const titleElement = document.querySelector('title')
  titleElement.innerHTML = title
}

export function getUsedLocalStorage() {
  var _lsTotal = 0,
    _xLen,
    _x
  var obj = []

  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
      continue
    }
    _xLen = (localStorage[_x].length + _x.length) * 2
    _lsTotal += _xLen
    obj.push(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB')
  }
  obj.push('Total = ' + (_lsTotal / 1024).toFixed(2) + ' KB')
  return obj
}

<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-66 md-size-33 md-small-size-100">
        <change-password-form header-color="green"> </change-password-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ChangePasswordForm } from './index.js'
export default {
  components: {
    ChangePasswordForm,
  },
}
</script>
<style lang="scss">
.text-right {
  display: flex;
}
.md-layout {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div class="md-layout scroll-auto md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100">
    <loader :loader="loader" />
    <div :key="renderKey">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary md-flex">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">{{ $t('customers') }}</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <div class="toolbar-left">
                <md-field>
                  <div>
                    <label for="pages" id="perPage">{{ this.$t('perPage') }}</label>
                  </div>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>

                <md-field class="ml-10">
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    :placeholder="searchRecords"
                    v-model="searchQuery"
                  >
                  </md-input>
                </md-field>
              </div>

              <md-field>
                <md-button class="md-primary md-md md-round" @click.prevent="handleCreate()"
                  >{{ this.$t('createNewCustomer') }}
                </md-button>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
              <md-table-cell :md-label="'#'">
                {{ calculatePosition(index) }}
              </md-table-cell>

              <md-table-cell :md-label="$t('customerNumber')" md-sort-by="CustomerNr"
                ><strong>{{ item.Name }}</strong
                ><br />{{ item.CustomerNr }}</md-table-cell
              >

              <md-table-cell :md-label="$t('customerType')" md-sort-by="CompanyTypeID">{{
                getCompanyType(item.CompanyTypeID)
              }}</md-table-cell>

              <md-table-cell :md-label="$t('actions')">
                <md-button class="md-just-icon md-primary md-simple" @click.native="handleEdit(item)">
                  <md-icon>edit</md-icon>
                  <md-tooltip>{{ $t('edit') }}</md-tooltip>
                </md-button>

                <md-button
                  v-if="allow('{52b5e28f-35a2-4d87-96f3-de8bfdd4bb0b}')"
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                >
                  <md-icon>close</md-icon>
                  <md-tooltip>{{ $t('delete') }}</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              {{ $t('show') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { useCompanyStore } from '../../stores/company.js'
import { useUserStore } from '../../stores/user.js'
import { useConfigurationStore } from '../../stores/configuration'
import { permissionMixin } from '../RoleManagement/permissionMixin.js'

export default {
  mixins: [permissionMixin],
  components: {
    Pagination,
  },
  computed: {
    // return userStore
    // }
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      // if (this.searchedData.length > 0) {
      result = this.searchedData
      // }

      return result.slice(this.from, this.to)
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length
      // return this.searchedData.length > 0
      //   ? this.searchedData.length
      //   : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: 'Name',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
        active: false,
      },
      searchQuery: '',
      propsToSearch: ['CustomerNumber', 'Name', 'Email', 'CompanyType'],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      searchRecords: this.$t('searchRecords'),
      perPage: this.$t('perPage'),
      renderKey: 1,
      userFilter: 1,
      companyTypes: [
        {
          ID: '',
          Name: '',
          Description: '',
        },
      ],
      loader: false,
    }
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    handleEdit(item) {
      Swal.fire({
        title: this.$t('editTitle') + '?',
        html: `${item.Name}`,
        icon: 'question',
        showCancelButton: true,
      }).then((result) => {
        if (result.isDismissed) return
        this.configurationStore.resetState()
        this.companyStore.company = item
        this.$router.push({
          name: 'Company',
          params: { company: item },
        })
      })
    },
    async handleDelete(item) {
      var result = await Swal.fire({
        icon: 'question',
        title: this.$t('delete'),
        html: this.$t('deleteCompanyText').replace('%company%', item.Name),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        return result
      })
      if (!result.isConfirmed) return
      var sure = await Swal.fire({
        icon: 'warning',
        title: this.$t('deleteTitle'),
        html: this.$t('deleteText'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        return result
      })
      if (!sure.isConfirmed) return
      this.loader = true
      var responseDel = await this.companyStore.deleteCompany(item.ID, this.userStore.token)
      if (responseDel.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successDeleteCompany'),
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: `${this.$t('errorDeleteCompany')} ${responseDel.data.Message}`,
        })
      }
      this.loader = false

      await this.getCompanies()
    },
    handleCreate() {
      Swal.fire({
        title: this.$t('askCreateNewCustomer'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isDismissed) return
        this.$router.push({
          name: 'Create Company',
          params: { companyTypes: this.companyTypes },
        })
      })
    },
    handleNetError(error) {
      Swal.fire({
        title: this.$t('networkErrorMsg'),
        text: error,
        icon: 'error',
      })
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.ID === item.ID)
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1)
      }
    },
    calculatePosition(index) {
      return index + 1 + (this.pagination.currentPage - 1) * this.pagination.perPage
    },
    getReadableDate(d) {
      var date = new Date(d)
      return date.toLocaleDateString()
    },
    getCompanyType(id) {
      var type = this.companyTypes.find((type) => type.ID == id)
      var name = type.Name
      const nameTranslations = {
        Berufsschule: 'vocationalSchool',
        Universität: 'university',
        Firma: 'company',
        Hochschule: 'college',
        Konzern: 'groupOfCompanies',
      }
      name = this.$t(nameTranslations[name])
      return name
    },
    async getCompanies() {
      this.loader = true
      var response = await this.companyStore.getCompanies()
      if (response.status === 200) {
        this.tableData = response.data
        this.searchedData = this.tableData
      } else {
        this.handleNetError(response.statusText)
      }
      this.loader = false
    },
  },
  async mounted() {
    var response = await this.companyStore.getCompanies()
    if (response.status === 200) {
      this.tableData = response.data
      this.searchedData = this.tableData
    } else {
      this.handleNetError(response.statusText)
    }
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['Name', 'Email', 'CustomerNr', 'CompanyType'],
      findAllMatches: true,
      // minMatchCharLength: 1,
      useExtendedSearch: true,
      shouldSort: true,
      ignoreLocation: true,
      threshold: 0.1,
    })
    this.$changePageTitle(this.$t('pageTitleListOfCompanies'))

    this.companyTypes = this.companyStore.companyTypes
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
        //get access to result items
        result = result.map((record) => record.item)
      }
      this.searchedData = result
    },
    perPage() {
      this.renderKey++
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationStore = useConfigurationStore()
    return { companyStore, userStore, configurationStore }
  },
  updated() {
    if (this.perPage != this.$t('perPage')) {
      this.perPage = this.$t('perPage')
      this.searchRecords = this.$t('searchRecords')
      this.createNewCustomer = this.$t('createNewCustomer')
      this.renderKey++
    }
  },
}
</script>

<style lang="css" scoped>
@media (max-width: 500px) {
  .hide {
    display: none;
  }

  .toolbar-left {
    flex-direction: column;
  }
}

@media (min-width: 501px) {
  .toolbar-left {
    display: flex;
    flex-direction: row;
  }

  .ml-10 {
    margin-left: 10px;
  }
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.truncate-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.scroll-auto {
  scroll-behavior: smooth !important;
}
.md-field::after {
  height: 0;
}
.toolbar-left {
  .md-field::after {
    height: 1px;
  }
}
</style>

<template>
  <div class="full-page" :class="{ 'nav-open': $sidebar.showSidebar }" :data-background-color="userStore.theme">
    <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
      <div class="md-toolbar-row md-offset">
        <div class="md-collapse">
          <div class="theme-switch">
            <div class="theme-color">{{ $t('darkTheme') }}</div>
            <md-switch v-model="isWhiteTheme"></md-switch>
            <div class="theme-color">{{ $t('lightTheme') }}</div>
          </div>
          <lang-select class="navbar-lang"></lang-select>
        </div>
        <cookie-notice></cookie-notice>
      </div>
    </md-toolbar>
    <div class="wrapper wrapper-full-page" @click="toggleSidebarPage">
      <div class="page-header header-filter">
        <div class="container md-offset">
          <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <router-view> </router-view>
          </zoom-center-transition>
        </div>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions'
import ContentFooter from '../UserLayout/ContentFooter.vue'
import { useUserStore } from '../../stores/user'
import LangSelect from '../../components/LangSelect.vue'
import CookieNotice from '../../components/Cookie/CookieNotice.vue'

export default {
  components: {
    ZoomCenterTransition,
    ContentFooter,
    LangSelect,
    CookieNotice,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'purple',
    },
  },
  inject: {
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      responsive: false,
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 300,
      year: new Date().getFullYear(),
      lang: 'de',
      isWhiteTheme: this.userStore.theme === 'white',
    }
  },
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  methods: {
    toggleSidebarPage() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    linkClick() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false)
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
      this.showMenu = !this.showMenu
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      this.showMenu = false
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
  },
  mounted() {
    const myBody = document.body
    myBody.setAttribute('data-background-color', this.userStore.theme)
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    if (this.userStore.valid) {
      this.lang = this.userStore.userData.Language
    }
    document.documentElement.style.setProperty(
      '--list-background',
      this.userStore.theme === 'black' ? '#b5b5b5' : '#fff',
    )
  },
  beforeDestroy() {
    this.closeMenu()
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu()
      setTimeout(() => {
        next()
      }, this.menuTransitionDuration)
    } else {
      next()
    }
  },
  watch: {
    isWhiteTheme() {
      this.userStore.theme = this.isWhiteTheme ? 'white' : 'black'
      const myBody = document.body
      myBody.setAttribute('data-background-color', this.userStore.theme)
    },

    'userStore.theme': function (newTheme, oldTheme) {
      document.documentElement.style.setProperty('--list-background', newTheme === 'black' ? '#b5b5b5' : '#fff')
    },
  },
}
</script>
<style lang="scss">
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;

@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
    transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
  }

  to {
    opacity: 0;
    transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}

.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 1111;
}

.lang-style {
  z-index: 10000 !important;
}

.page-header.header-filter {
  display: flex;
  flex-direction: column;
}

.footer {
  //width: calc(100% - 70px) !important;
  position: unset !important;
  align-self: start;
}

footer ul li:first-child a {
  margin-left: 0;
}

.theme-switch {
  font-size: 16px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 20px;
  min-width: 100px;
}

.md-switch {
  margin: 16px !important;
  &.field-switch {
    margin-top: 0 !important;
    margin-left: 0 !important;
    label {
      color: black;
    }
  }
}

[data-background-color='black'] .md-menu.md-select > input {
  -webkit-text-fill-color: #ffffff !important;
  fill: #ffffff !important;
}

[data-background-color='black'] .md-menu.md-select > .md-icon-image > svg {
  -webkit-text-fill-color: #ffffff !important;
}

body
  > div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default.country-select-menu,
body
  > div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default.product-select-menu {
  max-width: 300px;
}

body > div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  max-width: 195px;
}

body
  > div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default.lang-select-menu {
  right: 10px;
  top: 10px;
  left: unset !important;
}

.md-select-menu {
  .md-list {
    background-color: var(--list-background) !important;
  }
}

.navbar-lang {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
}
#lang input[name='lang'] {
  height: 100%;
}
#lang .md-icon {
  margin-bottom: 15px;
}
</style>

<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <md-button
        class="md-just-icon md-round md-simple md-toolbar-toggle"
        :class="{
          toggled: $sidebar.showSidebar,
          'show-mobile-menu-button': $sidebar.showSidebar,
        }"
        @click="toggleSidebar"
      >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </md-button>
      <div class="mymdesign-logo"><img :src="myMdesignLogo" alt="company_small_logo" /></div>
      <div class="md-collapse">
        <div class="theme-switch">
          <div class="theme-color">{{ $t('darkTheme') }}</div>
          <md-switch v-model="isWhiteTheme"></md-switch>
          <div class="theme-color">{{ $t('lightTheme') }}</div>
        </div>
        <div @mouseover="openLangDropDown()" class="lang-select">
          <lang-select class="navbar-lang"></lang-select>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import myMdesignBlueLogo from '../../../public/img/my_mdesign_blue.svg'
import myMdesignWhiteLogo from '../../../public/img/my_mdesign_white.svg'
import LangSelect from '../../components/LangSelect.vue'
import { useUserStore } from '../../stores/user'
import headerCover from '../../../public/img/header-cover.jpg'

export default {
  components: {
    LangSelect,
  },
  data() {
    return {
      isWhiteTheme: this.userStore.theme === 'white',
      myMdesignLogo: myMdesignWhiteLogo,
      headerCoverImg: headerCover,
    }
  },
  props: {
    backgroundColor: {
      type: String,
      default: '',
      validator: (value) => {
        let acceptedValues = ['', 'black', 'white', 'red']
        return acceptedValues.indexOf(value) !== -1
      },
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
    openLangDropDown() {
      const dropdown = document.querySelector('.navbar-lang>div>input')
      if (dropdown) {
        dropdown.click()
      }
    },
  },
  setup() {
    const userStore = useUserStore()
    return {
      userStore,
    }
  },
  watch: {
    isWhiteTheme() {
      this.userStore.theme = this.isWhiteTheme ? 'white' : 'black'
      this.myMdesignLogo = myMdesignWhiteLogo
    },
    'userStore.theme': function (newTheme, oldTheme) {
      this.sidebarBackgroundColor = newTheme
      document.documentElement.style.setProperty('--list-background', newTheme === 'black' ? '#b5b5b5' : '#fff')
    },
  },
  mounted() {
    this.sidebarBackgroundColor = this.userStore.theme
    document.documentElement.style.setProperty(
      '--list-background',
      this.sidebarBackgroundColor === 'black' ? '#b5b5b5' : '#f2f2f2',
    )
    this.myMdesignLogo = myMdesignWhiteLogo
  },
}
</script>
<style scoped>
/deep/ .md-icon.md-theme-default.md-icon-image svg {
  fill: #3c4858 !important;
}

/deep/ div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 1111 !important;
}

.theme-switch {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 20px;
  min-width: 100px;
}

/deep/.md-switch {
  margin: 16px !important;
}

/deep/ .md-menu.md-select > input {
  -webkit-text-fill-color: #ffffff !important;
  fill: #ffffff !important;
}

/deep/ .theme-switch {
  -webkit-text-fill-color: #ffffff !important;
  fill: #ffffff !important;
}

[data-background-color='black'] /deep/.md-menu.md-select > .md-icon-image > svg {
  fill: #ffffff !important;
}

.md-select-menu {
  .md-list {
    background-color: var(--list-background) !important;
  }
}

.navbar-lang {
  height: 100%;
}

@media (max-width: 991px) {
  .navbar-lang {
    display: none;
  }

  .show-mobile-menu-button {
    transform: translateX(210px);
    transition: transform 1s;
  }
}

.md-toolbar-row {
  padding-left: 0 !important;
}

div .md-collapse {
  max-width: 258px;
  height: 100%;
  display: flex;
  align-items: baseline;
}

img {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 300px;
}

.md-toolbar {
  display: flex;
  justify-content: flex-end;
  background-image: url('../../../public/img/header-cover.jpg') !important;
  height: auto;
  background-size: cover;
  background-position: center;
  min-height: 300px;
}

.md-toolbar-row {
  width: 85%;
  justify-content: space-between;
  height: 280px;
}

@media screen and (max-width: 991px) {
  .md-toolbar-row {
    width: 100%;
    justify-content: flex-start;
    padding: 0;
  }

  img {
    margin-left: 150px;
  }

  .md-collapse {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 992px) {
  .md-toolbar-row {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }

  img {
    margin-left: 0px;
  }

  .md-toolbar-toggle {
    position: absolute;
    left: 10px;
    border: none !important;

    &:hover {
      background-color: transparent !important;
      border: none !important;
      color: #00325b !important;
    }

    [data-background-color='black'] & {
      color: #ffffff !important;
    }
  }

  .md-collapse {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.lang-select {
  display: flex;
  justify-content: center;
  align-items: center;
}

#lang .md-icon {
  margin-bottom: 15px;
}

.navbar-lang {
  margin-bottom: 2px;
}
</style>

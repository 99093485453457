<template>
  <ul class="nav nav-mobile-menu">
    <lang-select class="sidebar-language"></lang-select>
  </ul>
</template>
<script>
import LangSelect from '../LangSelect.vue'
export default {
  components: {
    LangSelect,
  },
}
</script>
<style scoped>
[data-background-color='black'] /deep/ #lang > div > i > svg {
  fill: #ffffff !important;
}
[data-background-color='black'] /deep/.sidebar-language .md-menu.md-select > input {
  -webkit-text-fill-color: #ffffff !important;
}
</style>

<template>
  <div
    :class="{
      'sidebar sidebar-first sidebar-mini': true,
      'show-mob-menu': $sidebar.showSidebar,
    }"
    :data-color="activeColor"
    :data-background-color="backgroundColor"
  >
    <div class="logo" v-if="!$sidebar.showSidebar">
      <a href="http://my.mdesign.de/" class="simple-text" target="_blank">
        <div class="logo-img company-logo">
          <img :src="logoMini" alt="company_small_logo" v-if="!$sidebar.showProductMenu" />
        </div>
      </a>
    </div>
    <div class="logo" v-if="$sidebar.showSidebar">
      <a href="http://my.mdesign.de/" class="simple-text" target="_blank">
        <div class="company-logo">
          <img :src="logoBig" alt="company_big_logo" class="logo-big" />
        </div>
      </a>
    </div>
    <div class="logo" v-if="this.firstname">
      <a class="simple-text logo-mini cursor-default" @click="goToUserProfile">
        <div class="logo-img profile-image tooltip">
          <img :src="profileImage" />
          <span class="tooltiptext">{{ $t('profileTitle') }}</span>
        </div>
      </a>
      <div class="logo" v-if="this.firstname && $sidebar.showSidebar">
        <a class="simple-text logo-normal" @click="goToUserProfile" :renderKey="updateName">
          <div class="name-surname">{{ firstname }} {{ lastname }}</div>
        </a>
      </div>
    </div>
    <div class="logo" v-if="!this.firstname" @click="goToUserProfile">
      <a class="simple-text logo-mini cursor-default">
        <div class="logo-img profile-image tooltip">
          <img :src="profileImage" />
        </div>
      </a>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <md-list class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '../../stores/user'
import companyBigLogo from '../../../public/img/logo_long_white_theme.png'
import companySmallLogo from '../../../public/img/logo_short_white_theme.png'
import companySmallLogoDark from '../../../public/img/logo_short.png'
import myMdesignBlueLogo from '../../../public/img/my_mdesign_blue.svg'
import myMdesignWhiteLogo from '../../../public/img/my_mdesign_white.svg'

export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'MDesign',
    },
    activeColor: {
      type: String,
      default: 'green',
      validator: (value) => {
        let acceptedValues = ['', 'purple', 'azure', 'green', 'orange', 'danger', 'rose']
        return acceptedValues.indexOf(value) !== -1
      },
    },
    backgroundColor: {
      type: String,
      default: 'white',
      validator: (value) => {
        let acceptedValues = ['', 'black', 'white', 'red']
        return acceptedValues.indexOf(value) !== -1
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      profileImage: this.userStore.userData.AvatarImage || '/Default_profileImage.png',
      lastname: this.userStore.userData.LastName,
      firstname: this.userStore.userData.FirstName,
      email: null,
      logoBig: myMdesignBlueLogo,
      logoMini: companySmallLogo,
      theme: this.userStore.theme,
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  methods: {
    async getAvatarImg() {
      const reposnse = await this.userStore.getAvatar()
      const reader = new FileReader()
      reader.readAsDataURL(reposnse.data)
      reader.onload = () => {
        this.profileImage = reader.result
        this.userStore.userData.AvatarImage = reader.result
      }
      this.profileImage = this.userStore.userData.AvatarImage
    },
    goToUserProfile() {
      this.$router.push({ name: 'User' })
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
    updateSideBar() {
      this.lastname = this.userStore.userData.LastName
      this.firstname = this.userStore.userData.FirstName
    },
    themeImg(theme) {
      if (theme === 'white') {
        this.logoBig = myMdesignBlueLogo
        this.logoMini = companySmallLogo
      } else {
        this.logoBig = myMdesignWhiteLogo
        this.logoMini = companySmallLogoDark
      }
    },
  },
  setup() {
    const userStore = useUserStore()
    return {
      userStore,
    }
  },
  async beforeMount() {
    this.$eventBus.$on('updateAvatar', () => {
      this.getAvatarImg()
    })

    if (this.userStore.userData.Image) {
      await this.getAvatarImg()
    }
    if (this.userStore.valid) {
      this.lastname = this.userStore.userData.LastName
      this.firstname = this.userStore.userData.FirstName
    } else this.$router.push({ name: 'Login' })
    this.themeImg(this.userStore.theme)
  },
  computed: {
    updateName() {
      return this.updateSideBar()
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
  watch: {
    'userStore.theme': function (newTheme, oldTheme) {
      this.theme = newTheme
      this.themeImg(newTheme)
    },
  },
}
</script>

<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.cursor-default {
  cursor: default !important;
}

.name-surname {
  text-transform: none !important;
  width: 180px;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
}

.hide-mini-logo {
  opacity: 0 !important;
  display: none !important;
}

.sidebar-mini {
  .hide-mini-logo {
    opacity: 1 !important;
    display: block !important;
  }

  .hide-big-logo {
    padding-left: 0px !important;
  }
}

.sidebar-mini {
  @media screen and (max-width: 991px) {
    .hide-mini-logo {
      opacity: 0 !important;
      display: none !important;
    }

    .hide-big-logo {
      padding-left: 30px !important;
    }

    .show-mob-menu {
      transform: translateX(1px);
      transition: transform 1s;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    }
  }
}

.hide-big-logo {
  max-width: 180px !important;
  padding-left: 30px !important;
}

.profile-image img {
  border-radius: 50%;
}

.sidebar-first a.simple-text {
  display: flex !important;
  justify-content: center !important;
  padding: 0 !important;
  padding-top: 3px !important;
}

div.logo {
  padding: 0px !important;
  height: 70px !important;
  display: flex !important;
  justify-content: center;
  /* Horizontal alignment */
  align-items: center !important;
}

.sidebar-first .logo-big {
  width: 150px !important;
}

.sidebar-first .name-surname {
  margin-left: 0px !important;
  padding-bottom: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    display: none;
    position: fixed;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 100;
    left: 70px;
    font-size: 13px;
    text-transform: none !important;
  }

  &:hover {
    .tooltiptext {
      display: block;
    }
  }
}
</style>

<template>
  <div class="sidebar sidebar-second" :data-color="activeColor" :data-background-color="backgroundColor">
    <div class="logo second">
      <a href="http://my.mdesign.de/" style="color: aliceblue" class="simple-text logo-normal" target="_blank">
        <img :src="logoBig" alt="company_big_logo" />
      </a>
      <div class="navbar-minimize">
        <md-button id="minimizeSidebar" class="md-round md-just-icon md-transparent" @click="minimizeSidebar">
          <i class="material-icons">close</i>
        </md-button>
      </div>
    </div>
    <div class="logo second" v-if="this.firstname">
      <a class="simple-text logo-normal" @click="goToUserProfile" :renderKey="updateName">
        <div class="name-surname">{{ firstname }} {{ lastname }}</div>
      </a>
    </div>
    <div class="logo second" v-if="!this.firstname">
      <a class="simple-text logo-normal" @click="goToUserProfile" :renderKey="updateName">
        <div class="name-surname">{{ $t('userProfile') }}</div>
      </a>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <md-list class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import { useUserStore } from '../../stores/user'
import companyBigLogo from '../../../public/img/logo_long_white_theme.png'
import companySmallLogo from '../../../public/img/logo_short_white_theme.png'
import myMdesignBlueLogo from '../../../public/img/my_mdesign_blue.svg'
import myMdesignWhiteLogo from '../../../public/img/my_mdesign_white.svg'

export default {
  name: 'side-bar-second',
  props: {
    title: {
      type: String,
      default: 'MDesign',
    },
    activeColor: {
      type: String,
      default: 'green',
      validator: (value) => {
        let acceptedValues = ['', 'purple', 'azure', 'green', 'orange', 'danger', 'rose']
        return acceptedValues.indexOf(value) !== -1
      },
    },
    backgroundColor: {
      type: String,
      default: 'white',
      validator: (value) => {
        let acceptedValues = ['', 'black', 'white', 'red']
        return acceptedValues.indexOf(value) !== -1
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      profileImage: this.userStore.userData.AvatarImage || '/Default_profileImage.png',
      lastname: this.userStore.userData.LastName,
      firstname: this.userStore.userData.FirstName,
      email: null,
      logoBig: myMdesignBlueLogo,
      logoMini: companySmallLogo,
      theme: this.userStore.theme,
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  methods: {
    async getAvatarImg() {
      const reposnse = await this.userStore.getAvatar()
      const reader = new FileReader()
      reader.readAsDataURL(reposnse.data)
      reader.onload = () => {
        this.profileImage = reader.result
        this.userStore.userData.AvatarImage = reader.result
      }
      this.profileImage = this.userStore.userData.AvatarImage
    },
    goToUserProfile() {
      this.$router.push({ name: 'User' })
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize()
    },
    updateSideBar() {
      this.lastname = this.userStore.userData.LastName
      this.firstname = this.userStore.userData.FirstName
    },
    themeImg(theme) {
      if (theme === 'white') {
        this.logoBig = myMdesignBlueLogo
        this.logoMini = companySmallLogo
      } else {
        this.logoBig = myMdesignWhiteLogo
        this.logoMini = 'img/logo_short.png'
      }
    },
  },
  setup() {
    const userStore = useUserStore()
    return {
      userStore,
    }
  },
  async beforeMount() {
    this.$eventBus.$on('updateAvatar', () => {
      this.getAvatarImg()
    })

    if (this.userStore.userData.Image) {
      await this.getAvatarImg()
    }
    if (this.userStore.valid) {
      this.lastname = this.userStore.userData.LastName
      this.firstname = this.userStore.userData.FirstName
    } else this.$router.push({ name: 'Login' })
    this.themeImg(this.userStore.theme)
  },
  computed: {
    updateName() {
      return this.updateSideBar()
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
  watch: {
    'userStore.theme': function (newTheme, oldTheme) {
      this.theme = newTheme
      this.themeImg(newTheme)
    },
  },
}
</script>
<style lang="scss">
@media (min-width: 991px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.cursor-default {
  cursor: default !important;
}

.name-surname {
  text-transform: none !important;
  width: 180px;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
}

.sidebar {
  .hide-mini-logo {
    opacity: 0 !important;
    display: none !important;
  }
}

.profile-image img {
  border-radius: 50%;
}

.logo-normal img {
  width: 150px;
  margin-left: 50px;
}

.name-surname {
  margin-left: 50px;
  text-align: center;
}

.sidebar-second .sidebar-wrapper {
  margin-left: 20px;
}
.logo.second::after {
  width: calc(100% - 50px) !important;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.awsConfigurationID !== ""
    ? _c(
        "md-card",
        { key: _vm.renderKey, staticClass: "card-content" },
        [
          _c("loader", { attrs: { loader: _vm.loader } }),
          _c(
            "md-card-header",
            {
              staticClass: "md-card-header-icon",
              class: _vm.getClass(_vm.headerColor),
            },
            [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("share")])],
                1
              ),
              _c("h4", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("awsLinkedProducts")) + " "),
              ]),
            ]
          ),
          _c(
            "md-card-content",
            [
              _c(
                "md-table",
                {
                  staticClass: "paginated-table table-striped table-hover",
                  attrs: {
                    value: _vm.queriedData,
                    "md-sort": _vm.currentSort,
                    "md-sort-order": _vm.currentSortOrder,
                    "md-sort-fn": _vm.customSort,
                  },
                  on: {
                    "update:mdSort": function ($event) {
                      _vm.currentSort = $event
                    },
                    "update:md-sort": function ($event) {
                      _vm.currentSort = $event
                    },
                    "update:mdSortOrder": function ($event) {
                      _vm.currentSortOrder = $event
                    },
                    "update:md-sort-order": function ($event) {
                      _vm.currentSortOrder = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "md-table-row",
                        fn: function (ref) {
                          var item = ref.item
                          var index = ref.index
                          return _c(
                            "md-table-row",
                            {},
                            [
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": "#" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.calculatePosition(index)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "md-table-cell",
                                {
                                  attrs: {
                                    "md-label": _vm.$t("name"),
                                    "md-sort-by": "Name",
                                  },
                                },
                                [_c("strong", [_vm._v(_vm._s(item.Name))])]
                              ),
                              _c(
                                "md-table-cell",
                                {
                                  attrs: {
                                    "md-label": _vm.$t("awsInfo"),
                                    "md-sort-by": "Info",
                                  },
                                },
                                [_vm._v(_vm._s(item.Info))]
                              ),
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": _vm.$t("actions") } },
                                [
                                  _c(
                                    "md-switch",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleActivationProduct(
                                            item
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.IsActive,
                                        callback: function ($$v) {
                                          _vm.$set(item, "IsActive", $$v)
                                        },
                                        expression: "item.IsActive",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("accessActive")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    3683395802
                  ),
                },
                [
                  _c("md-table-toolbar", [
                    _c(
                      "div",
                      { staticClass: "toolbar-left" },
                      [
                        _c(
                          "md-field",
                          [
                            _c("div", [
                              _c(
                                "label",
                                { attrs: { for: "pages", id: "perPage" } },
                                [_vm._v(_vm._s(_vm.$t("perPage")))]
                              ),
                            ]),
                            _c(
                              "md-select",
                              {
                                attrs: { name: "pages" },
                                model: {
                                  value: _vm.pagination.perPage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pagination, "perPage", $$v)
                                  },
                                  expression: "pagination.perPage",
                                },
                              },
                              _vm._l(
                                _vm.pagination.perPageOptions,
                                function (item) {
                                  return _c(
                                    "md-option",
                                    {
                                      key: item,
                                      attrs: { label: item, value: item },
                                    },
                                    [_vm._v(" " + _vm._s(item) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "md-field",
                          { staticClass: "ml-10" },
                          [
                            _c("md-input", {
                              staticClass: "mb-3",
                              staticStyle: { width: "200px" },
                              attrs: {
                                type: "search",
                                clearable: "",
                                placeholder: _vm.searchRecords,
                              },
                              model: {
                                value: _vm.searchQuery,
                                callback: function ($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("md-card-actions", [
            _c("div", { staticClass: "card-actions-container" }, [
              _c(
                "div",
                { staticClass: "button-actions" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-raised md-primary mt-4 md-round",
                      attrs: { id: "submit" },
                      on: {
                        click: function ($event) {
                          return _vm.askLinkProduct()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("selectProductToLink")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-actions" },
                [
                  _c("div", {}, [
                    _c("p", { staticClass: "card-category" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("show")) +
                          " " +
                          _vm._s(_vm.from + 1) +
                          " " +
                          _vm._s(_vm.$t("to")) +
                          " " +
                          _vm._s(_vm.to) +
                          " " +
                          _vm._s(_vm.$t("of")) +
                          " " +
                          _vm._s(_vm.total) +
                          " " +
                          _vm._s(_vm.$t("entries")) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("pagination", {
                    staticClass: "pagination-no-border pagination-success",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      total: _vm.total,
                    },
                    model: {
                      value: _vm.pagination.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "currentPage", $$v)
                      },
                      expression: "pagination.currentPage",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="text-center">
    <div class="modal-content">
      <modal v-if="classicModal" :no-close-on-backdrop="true">
        <template slot="header">
          <h4 class="modal-title">{{ $t('cookieSettingsTitle') }}</h4>
        </template>
        <template slot="body" class="modal-body">
          <div>
            {{ $t('cookiesNoticeText') }}
            <a :href="$t('privacyLink')" target="_blank" class="privacy-policy">{{ $t('privacyPolicy') }}</a
            >. {{ $t('cookiesNoticeText2') }}
            <cookies-settings :linkToSettings="$t('settings')" class="cookies-settings"></cookies-settings>
          </div>
        </template>

        <template slot="footer">
          <md-button class="md-primary md-round button-width" @click="classicModalSaveAccept">{{
            $t('accept')
          }}</md-button>
          <md-button class="md-primary md-round button-width" @click="classicModalSaveDecline">{{
            $t('decline')
          }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Modal } from '..'
import VueCookies from 'vue-cookies'
import CookiesSettings from '../../components/Cookie/CookieSettings.vue'
import { unsetStatsGA } from '../../utils'
import CookieModal from './CookieModal.vue'

export default {
  components: {
    Modal,
    CookiesSettings,
  },
  data() {
    return {
      type: ['', 'info', 'success', 'warning', 'danger'],
      classicModal: false,
      essis: true,
      stats: true,
    }
  },
  methods: {
    classicModalHide: function () {
      this.classicModal = false
    },
    classicModalSaveAccept: function () {
      this.classicModal = false
      VueCookies.set('my.mdesign-klaro', { essis: this.essis, stats: this.stats })
      this.stats && CookieModal.methods.setStatsGA.call(this)
    },
    classicModalSaveDecline: function () {
      this.classicModal = false
      this.stats = false
      VueCookies.set('my.mdesign-klaro', { essis: this.essis, stats: this.stats })
      !this.stats && unsetStatsGA()
    },
  },
  mounted: function () {
    //switch off the model
    VueCookies.get('my.mdesign-klaro') || (this.classicModal = true)
  },
  created() {
    this.$root.$refs.CookieNotice = this
  },
}
</script>
<style scoped>
a:hover {
  cursor: pointer;
}

.modal-container {
  color: #3c4858;
}

.privacy-policy {
  all: unset !important;
  text-decoration: underline !important;
}

.privacy-policy:hover {
  cursor: pointer !important;
}

.essential-cookies,
.marketing-cookies {
  display: flex;
}

.essential-cookies-descrp,
.marketing-cookies-descrp {
  text-align: left;
  margin-left: 16px;
}

.es-required,
.purposes {
  font-size: 0.8em;
  color: #878989;
  opacity: 0.5;
}

/deep/ .modal-footer {
  justify-content: space-around;
}

/deep/ .modal-wrapper {
  vertical-align: bottom;
}

@media screen and (max-width: 576px) {
  .cookies-settings /deep/.modal-container {
    margin: 0 auto !important;
  }
}
.cookies-settings {
  .modal-header > button {
    border: none !important;
  }
}
.button-width {
  width: 100px;
}
</style>

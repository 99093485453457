import { defineStore } from 'pinia'
import {
  ApiNames,
  httpGetAuth,
  httpPost,
  httpGetByUrl,
  httpGetAuthFileDownload,
  httpDelete,
  httpGetAws,
  httpPostProductImage,
} from '@/libs/httpHelper'
import { useUserStore } from './user.js'
import { httpGet } from '../libs/httpHelper.js'
import { useCompanyStore } from './company.js'
import Swal from 'sweetalert2'
import { i18n } from '@/main.js'

export const useManagementStore = defineStore({
  id: 'managementState',
  state: () => {
    // const userStore = ref('useUserStore')
    return {
      manageRole: {
        editRole: false,
        role: {},
      },
      resendMailData: {
        Firstname: '',
        Lastname: '',
        Email: '',
      },
      dailySalesChart: {
        data: {
          labels: [],
          series: [[0, 0, 0, 0, 0, 0, 0]],
        },
      },
      updatedDailySales: null,
      updatedMonthlyRegister: null,
      salesToday: 0,
      emailsSubscriptionChart: {
        data: {
          labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
          series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
        },
        options: {
          high: 100,
        },
      },
      registrationsPerYear: 0,
      yearRev: 0,
    }
  },
  persist: true,
  getters: {
    // ...
  },
  actions: {
    resetManagementStore() {
      this.manageRole = {
        editRole: false,
        role: {},
      }
      this.resendMailData = {
        Firstname: '',
        Lastname: '',
        Email: '',
      }

      this.dailySalesChart = {
        data: {
          labels: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          series: [[0, 0, 0, 0, 0, 0, 0]],
        },
      }
      this.updatedDailySales = null
      this.updatedMonthlyRegister = null
      ;(this.emailsSubscriptionChart = {
        data: {
          labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
          series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
        },
        options: {
          high: 100,
        },
      }),
        (this.salesToday = 0)
      this.registrationsPerYear = 0
      this.yearRev = 0
    },
    getDayInitials() {
      const dayInitials = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

      const today = new Date().getDay()

      const rotatedArray = [...dayInitials.slice(today + 1), ...dayInitials.slice(0, today + 1)]

      this.dailySalesChart.data.labels = rotatedArray
    },
    getPastSevenDaysCounts(registrations) {
      // Initialize an array with 7 counts for each day of the week
      const counts = [0, 0, 0, 0, 0, 0, 0]
      // Get today's date and time
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      registrations.forEach((item) => {
        // Parse the Created date
        // console.log(item)*
        const createdDate = new Date(item.Created)
        createdDate.setHours(0, 0, 0, 0)
        // Calculate the difference in days between today and the created date
        const diffInTime = today.getTime() - createdDate.getTime()
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24))

        // Check if the created date is within the past seven days
        if (diffInDays >= 0 && diffInDays < 7) {
          // Increment the corresponding count
          counts[6 - diffInDays]++
        }
      })

      // Find the maximum count
      const highValue = Math.max(...counts)

      // Update chart options
      this.dailySalesChart.options.high = this.getNext10th(highValue)

      // Update chart data
      this.dailySalesChart.data.series = [counts]
    },
    getMonthlyOrderings(year, registrations, orderings) {
      var seriesArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.yearRev = 0
      var counter = 0
      //console.log('yearRev', year, registrations, orderings)

      orderings.forEach((order) => {
        var index = registrations.findIndex((obj) => obj.ContactID === order.ContactID)
        if (index !== -1 && !order.Testorder) {
          var contact = registrations[index]
          registrations[index].Price =
            order.PriceWithoutTax > order.Price ? order.PriceWithoutTax + order.Tax : order.Price

          if (contact.Created.includes(year) && registrations[index].Price > 0) {
            counter++
            const created = contact.Created.split('T')[0]
            const monthOffsett = parseInt(created.split('-')[1]) - 1
            this.yearRev = this.yearRev + parseInt(registrations[index].Price)
            seriesArray[monthOffsett]++
          }
        }
      })
      const high = Math.max(...seriesArray)

      let summe = seriesArray.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)
      this.registrationsPerYear = 0
      this.registrationsPerYear = summe
      this.emailsSubscriptionChart.options.high = this.getNext50th(high)
      this.emailsSubscriptionChart.data.series = [seriesArray]
    },
    async getRegistrations(token) {
      const companyStore = useCompanyStore()
      var res = await companyStore.getAllContacts(token)

      if (res.status === 200) {
        var allOrders = res.data
        var registrations = []

        allOrders.forEach((order) => {
          if (!(order.Testorder === true || order.Price === -1) && order.CompanyID === 'NULL') {
            var obj = {
              Created: order.Created,
              ContactID: order.ID,
            }
            registrations.push(obj)
          }
        })
        return { status: '200', data: registrations }
      } else {
        return res.data.Message
      }
    },
    async refreshDailyRegistrations(token) {
      var res = await this.getRegistrations(token)

      if (res.status === '200') {
        var registrations = res.data
        this.getDayInitials()
        this.getPastSevenDaysCounts(registrations)
        this.countItemsCreatedToday(registrations)
        this.updatedDailySales = new Date()
        return '200'
      } else {
        return res
      }
    },
    async refreshMonthlyRegistrations(token, year) {
      var res = await this.getRegistrations(token)
      if (res.status !== '200') return res
      var registrations = res.data
      var res = await this.getOrderings(token)
      if (res.status !== 200) return res.data.Message
      var orderings = res.data

      this.getMonthlyOrderings(year, registrations, orderings)
      this.updatedMonthlyRegister = new Date()
      return '200'
    },
    getNext10th(value) {
      if (value % 10 === 0) {
        return value
      }
      return Math.ceil(value / 10) * 10
    },
    getNext50th(value) {
      if (value % 50 === 0) {
        return value + 50
      }
      return Math.ceil(value / 50) * 50
    },
    countItemsCreatedToday(registrations) {
      // Get today's date
      const today = new Date()
      const todayDate = today.toISOString().slice(0, 10) // Format: 'YYYY-MM-DD'

      // Filter items that were created today
      this.salesToday = registrations.filter((item) => {
        const createdDate = item.Created.split('T')[0] // Extract 'YYYY-MM-DD' from 'created' string
        return createdDate === todayDate
      }).length
    },
    async getProductImages(token) {
      const companyStore = useCompanyStore()

      var resProds = await companyStore.getProducts(token)

      if (resProds.status !== 200) return false

      var prodArray = resProds.data
      var temp = {}
      const promises = prodArray.map(async (p) => {
        try {
          var resRef = await companyStore.getFileReferenceByTable(p.ID, 'product', token)

          if (resRef.status !== 200) return

          var resDataFile = await companyStore.getDataFileByID(resRef.data[0].DataFileID, token)

          if (resDataFile.status !== 200) return

          var resDownload = await companyStore.getDownloadFile(resDataFile.data[0].ID, false, token)

          if (resDownload.status !== 200) return

          let reader = new FileReader()
          reader.readAsDataURL(resDownload.data)
          reader.onload = () => {
            temp[p.ID] = reader.result
          }
        } catch (error) {
          // console.log('error Product ', p.ID, error)
          return false
        }
      })
      await Promise.all(promises)

      return temp
    },
    async getBlacklistDomains(token) {
      var response = await httpGetAuth(ApiNames.blacklistdomains, {}, {}, token)

      return response
    },
    async getBasicPropertyByKey(key, token) {
      const params = { key: key }
      var response = await httpGetAuth(ApiNames.basicpropertyvaluebykey, params, {}, token)

      return response
    },
    async createUserRole(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createuserrole, obj, headerConfig)

      return response
    },
    async createStudentDomain(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createstudentdomain, obj, headerConfig)

      return response
    },
    async updateUserRole(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateuserrole, obj, headerConfig)

      return response
    },
    async deleteUserRole(roleName, token) {
      const params = {
        name: roleName,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteuserrole, params, header)

      return response
    },
    async getAllPermissions(token) {
      const params = {}
      // const header = { Authorization: `Bearer ${token}` }
      var response = await httpGetAuth(ApiNames.permissions, params, {}, token)

      return response
    },
    async getPermissionsByRoleID(roleID, token) {
      const params = {
        id: roleID,
      }
      var response = await httpGetAuth(ApiNames.userrolehaspermission_userroleid, params, {}, token)
      return response
    },
    async getOrderings(token) {
      var response = await httpGetAuth(ApiNames.orderings, {}, {}, token)
      return response
    },
    async createUserRoleHasPermission(roleID, permissionID, token) {
      const headerConfig = { Authorization: `Bearer ${token}` }
      const params = {
        UserRoleID: roleID,
        PermissionID: permissionID,
      }
      var response = await httpPost(ApiNames.createuserrolehaspermission, params, { headers: headerConfig })
      return response
    },
    async createPermission(permission, token) {
      const headerConfig = { Authorization: `Bearer ${token}` }

      var response = await httpPost(ApiNames.createpermission, permission, { headers: headerConfig })
      return response
    },
    async deleteRolePermission(roleID, permissionID, token) {
      const params = {
        userRoleId: roleID,
        permissionId: permissionID,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteuserrolehaspermission_primary, params, header)

      return response
    },

    async deleteAllRolePermission(roleID, token) {
      const params = {
        id: roleID,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deleteuserrolehaspermission_all_userroleid, params, header)

      return response
    },

    async deleteContact(contactID, token) {
      const params = {
        id: contactID,
      }
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.delete, params, header)

      return response
    },

    async fireUnauthorized() {
      await Swal.fire({
        title: 'Fehlende Berechtigung<br>Missing authorization<br><br>',
        html: 'Sie haben für diese Seite keine Berechtigung.<br>You do not have permission to use this page.',
        icon: 'error',
      }).then((result) => {
        return
      })
    },
  },
})

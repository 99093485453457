<template>
  <div class="md-layout scroll-behavior">
    <loader :loader="loader" />
    <div class="md-layout-item company-contacts-content" :key="renderKey">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">{{ $t('customerContacts') }}</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <div class="toolbar-left">
                <md-field>
                  <div>
                    <label for="pages" id="perPage">{{ $t('perPage') }}</label>
                  </div>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
                <md-field class="ml-10">
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    :placeholder="searchRecords"
                    v-model="searchQuery"
                  >
                  </md-input>
                </md-field>
              </div>

              <md-field>
                <md-button class="md-primary" @click.native="handleCreate()">{{ $t('createNewContact') }} </md-button>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
              <md-table-cell :md-label="'#'">
                {{ calculatePosition(index) }}
              </md-table-cell>

              <md-table-cell :md-label="$t('name')"
                ><strong>{{ item.FirstName + ' ' + item.LastName }}</strong
                ><br />{{ item.Email }}</md-table-cell
              >

              <md-table-cell :md-label="$t('function')"
                ><strong>{{ $t('position') }}</strong
                ><br />{{ item.Position }}</md-table-cell
              >

              <md-table-cell :md-label="$t('actions')">
                <md-button
                  v-if="notVerifiedIndex.includes(index) && allow('{66f669e2-c677-436c-9afa-0bb0c7f95633}')"
                  class="md-just-icon edit-icon"
                  @click.native="handleResendMail(item)"
                >
                  <md-icon>email</md-icon>
                  <md-tooltip md-direction="bottom">{{ $t('resenMailTooltip') }}</md-tooltip>
                </md-button>

                <md-button class="md-just-icon edit-icon" @click.native="handleEdit(item)">
                  <md-icon>edit</md-icon>
                  <md-tooltip md-direction="bottom">{{ $t('edit') }}</md-tooltip>
                </md-button>

                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item, index)"
                  v-if="allow('{134a5c9f-25c6-41e7-a417-08b18f1caad2}')"
                >
                  <md-icon>close</md-icon>
                  <md-tooltip md-direction="bottom">{{ $t('delete') }}</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div v-if="queriedData.length === 0">{{ $t('noContactsFound') }}</div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              {{ $t('show') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { useUserStore } from '../../stores/user'
import { useCompanyStore } from '../../stores/company'
import { permissionMixin } from '../RoleManagement/permissionMixin'
import CreateNewCustomerContact from './CreateNewCustomerContact.vue'

export default {
  mixins: [permissionMixin],
  components: {
    Pagination,
  },
  props: {
    companyID: { type: String, default: '' },
    // companyTypes: { type: Array },
  },
  computed: {
    // return userStore
    // }
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      // only show the data that was actually found
      // if (this.searchedData.length > 0) {
      result = this.searchedData
      // }
      return result.slice(this.from, this.to)
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length
      // only show data that was actually found
      // if nothing is found $t("noContactsFound") will show up
      //> 0
      // ? this.searchedData.length
      //   : this.tableData.length;
    },
  },
  data() {
    return {
      loader: false,
      currentSort: 'LastName',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
        active: false,
      },
      searchQuery: '',
      //propsToSearch: ["FirstName", "LastName", "Email", "Position"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      searchRecords: this.$t('searchRecords'),
      perPage: this.$t('perPage'),
      renderKey: 1,
      userFilter: 1,
      companyTypes: [],
      notVerifiedIndex: [],
    }
  },
  methods: {
    changeTranslation() {
      this.renderKey += 1
      this.perPage = this.$t('perPage')
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    handleEdit(item) {
      Swal.fire({
        title: this.$t('editTitle') + `?`,
        html: item.FirstName + ' ' + item.LastName,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isDismissed) return
        this.companyStore.companyContact = item
        this.$router.push({ name: 'Edit Company Contact' })
      })
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.ID === item.ID)
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1)
      }
    },
    async handleDelete(item) {
      var ask = Swal.fire({
        icon: 'warning',
        title: this.$t('delete'),
        html: `${this.$t('deleteContact')}? <br><br>${item.FirstName} ${item.LastName}<br><br>${item.Email}`,
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
        reverseButtons: true,
      }).then((result) => {
        return result
      })
      if (!(await ask).isConfirmed) return

      var sure = Swal.fire({
        icon: 'error',
        title: this.$t('deleteTitle'),
        html: this.$t('deleteText'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
        reverseButtons: true,
      }).then((result) => {
        return result
      })
      if (!(await sure).isConfirmed) return
      this.loader = true
      var resDel = await this.managementStore.deleteContact(item.ID, this.userStore.token)
      this.loader = false
      if (resDel.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: `${this.$t('errorDeleteContact')}:<br><br>${resDel.data.Message}`,
        })
        return
      } else {
        this.deleteRow(item)
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successDeleteContact'),
        })
      }
    },
    handleCreate() {
      // Swal.fire({
      //   title: this.$t('askCreateNewCustomer'),
      //   icon: 'question',
      //   showCancelButton: true,
      //   cancelButtonText: this.$t('cancel'),
      // }).then((result) => {
      //   if (result.isDismissed) return
      this.$router.push({
        name: 'Create Company Contact',
        params: {
          companyID: this.companyID,
          companyTypes: this.companyTypes,
        },
        // })
      })
    },
    calculatePosition(index) {
      return index + 1 + (this.pagination.currentPage - 1) * this.pagination.perPage
    },
    async checkVerification() {
      this.notVerifiedIndex = []
      const promises = this.tableData.map(async (item, index) => {
        try {
          var resUser = await this.userStore.getUserDataByEmail(item.Email, this.userStore.token)

          if (resUser.status !== 404) return

          this.notVerifiedIndex.push(index)
        } catch (e) {
          //console.log('error ', e)
        }
      })
      await Promise.all(promises)
    },
    async handleResendMail(item) {
      Swal.fire({
        icon: 'question',
        title: this.$t('resendMailTitle'),
        html: `${this.$t('resendMailtext').replace('%name%', item.Email)}`,
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then(async (result) => {
        if (!(await result).isConfirmed) return
        this.loader = true
        var res = await CreateNewCustomerContact.methods.sendConfirmEmail.call(this, item)
        this.loader = false
        if (res === 'Email is sended.') {
          Swal.fire({
            icon: 'success',
            title: this.$t('success'),
            html: this.$t('emailSentOnly'),
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'error',
            html: res.data.Message,
          })
        }
      })
    },
  },
  async mounted() {
    if (this.userStore.role === 'Student' || this.userStore.role === 'User') this.$router.push({ name: 'Login' })

    var typesResponse = await this.companyStore.getCompanyTypes(this.userStore.token)
    if (!typesResponse.status === 200) {
    }

    this.companyTypes = typesResponse.data

    // Fuse search initialization.
    if (this.userStore.valid) {
      if (this.companyID === '') return

      var response = await this.companyStore.getContactsByCompanyID(this.companyID)
      if (response.status === 200) {
        this.tableData = response.data

        await this.checkVerification()
        // set searchedData initially, otherwhise the table will show no data
        this.searchedData = this.tableData
      } else if (response.status === 404) {
        // no contacts found
      } else {
        Swal.fire({
          title: this.$t('errorMsgLoading'),
          text: response.statusText,
          icon: 'warning',
          buttonsStyling: false,
        })
      }
    } else this.$router.push({ name: 'Login' })

    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['FirstName', 'LastName', 'Email', 'Position'],
      findAllMatches: true,
      useExtendedSearch: true,
      shouldSort: true,
      ignoreLocation: true,
      threshold: 0.1,
    })
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        //if you only want to show perfect matches use .search('=' + this.searchQuery)
        result = this.fuseSearch.search(this.searchQuery)
        //get access to result items
        result = result.map((record) => record.item)
      }
      this.searchedData = result
    },
    perPage() {
      this.renderKey++
    },
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    return { userStore, companyStore }
  },
  updated() {
    if (this.perPage != this.$t('perPage')) {
      this.perPage = this.$t('perPage')
      this.searchRecords = this.$t('searchRecords')
    }
  },
}
</script>

<style lang="css" scoped>
@media (max-width: 500px) {
  .toolbar-left {
    flex-direction: column;
  }
}

@media (min-width: 501px) {
  .toolbar-left {
    display: flex;
    flex-direction: row;
  }

  .ml-10 {
    margin-left: 10px;
  }
}

.md-card {
  margin: 0 auto;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.md-field::after {
  height: 0;
}

.toolbar-left {
  .md-field::after {
    height: 1px;
  }
}

.edit-icon {
  box-shadow: none;
}
</style>

<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submit)">
        <loader :loader="loader" />
        <md-card class="card-content">
          <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
            <div class="card-icon">
              <md-icon>cloud</md-icon>
            </div>
            <h4 class="title">
              {{ $t('awsAccountData') }}
            </h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('companyName') }}</label>
                  <md-input v-model="company.Name" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="configType" rules="required" v-slot="{ failed, passed }">
                  <md-field :class="[{ 'md-error': failed, 'md-valid': passed }]">
                    <label for="configType">{{ $t('accountType') }}</label>

                    <md-select v-model="configurationAws.ConfigurationAccountTypeID" name="configType" id="configType">
                      <md-option
                        v-for="configType in configurationAccountTypes"
                        :value="configType.ID"
                        :key="configType.ID"
                      >
                        {{ configType.Name }}
                      </md-option>
                    </md-select>

                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="runtimeinmonth" rules="required" v-slot="{ failed, passed }">
                  <md-field :class="[{ 'md-error': failed, 'md-valid': passed }]">
                    <label for="runtimeinmonth">{{ $t('runtime') }}</label>
                    <md-select v-model="configurationAws.RuntimeInMonth" name="runtimeinmonth" id="runtimeinmonth">
                      <md-option v-for="runtime in runtimes" :value="runtime.value" :key="runtime.value">
                        {{ runtime.label }}
                      </md-option>
                    </md-select>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('agency') }}</label>
                  <md-input v-model="userName" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="fleet" rules="required" v-slot="{ failed, passed }">
                  <md-field :class="[{ 'md-error': failed, 'md-valid': passed }]">
                    <label for="stack">{{ $t('stackInformation') }}</label>
                    <md-select v-model="configurationAws.FleetNormal" name="stack" id="fleet">
                      <md-option v-for="stack in stacks" :value="stack.Stacks" :key="stack.Stacks">
                        {{ stack.Description }}
                      </md-option>
                    </md-select>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="countCustomer" rules="required|integer" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed, 'md-valid': passed }]">
                    <label>{{ $t('countCustomer') }}</label>
                    <md-input v-model="configurationAws.Count" type="text"></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('startDate') }}</label>
                  <md-input @input="calculateEndDate" type="date" v-model="configurationAws.StartDate" />
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field :key="renderKey">
                  <label>{{ $t('EndDate') }}</label>
                  <md-input type="date" v-model="configurationAws.EndDate" :disabled="true" />
                </md-field>
              </div>

              <div class="md-layout-item md-size-100 text-right">
                <md-button
                  id="submit"
                  class="md-raised md-primary mt-4 md-round"
                  @click="askCreate()"
                  :disabled="invalid"
                  >{{ $t('save') }}
                </md-button>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33"></div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, min_value, email, numeric, integer } from 'vee-validate/dist/rules'
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '../../stores/user'
import { useConfigurationStore } from '../../stores/configuration'
import Swal from 'sweetalert2'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)
extend('integer', integer)

export default {
  props: {
    headerColor: { type: String, default: 'primary' },
  },
  data() {
    return {
      loader: false,
      company: {},
      renderKey: 0,
      configuration: {},
      userName: '',
      configurationAws: {
        Count: '1',
      },
      configurationAccountTypes: [{}],
      runtimes: [
        {
          value: 0.5,
          label: `2 ${this.$t('weeks')} - ${this.$t('testPosition')}`,
        },
        {
          value: 1,
          label: '1 ' + this.$t('month'),
        },
        {
          value: 3,
          label: '3 ' + this.$t('months'),
        },
        {
          value: 6,
          label: '6 ' + this.$t('months'),
        },
        {
          value: 12,
          label: '12 ' + this.$t('months'),
        },
      ],
      stacks: [],
    }
  },
  beforeMount() {
    this.company = this.companyStore.company
    if (this.company.ID === '') this.$router.push({ name: 'Companies' })
    this.configurationAccountTypes = this.configurationStore.configurationAccountTypes
    this.configuration = this.configurationStore.configuration
    this.configurationAws.ConfigurationID = this.configuration.ID
    this.configurationAws.CreatedBy = this.userStore.userData.ID
    this.userName = `${this.userStore.userData.FirstName} ${this.userStore.userData.LastName}`
    this.configurationAws.ModifiedBy = this.userStore.userData.ID
    this.stacks = this.configurationStore.awsStacks
    const date = new Date()
    if (this.configurationAws.RuntimeInMonth === '' || this.configurationAws.RuntimeInMonth === undefined)
      this.configurationAws.EndDate = this.companyStore.getToday()
    this.configurationAws.StartDate = this.companyStore.formatDate(date).split('T')[0]
  },
  methods: {
    askCreate() {
      Swal.fire({
        icon: 'question',
        title: this.$t('askCreateNewAwsConfig'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.dismiss) return
        this.createAwsConfiguration()
        //this.$router.push({ name: 'Aws Select' })
      })
    },
    async createAwsConfiguration() {
      const token = this.userStore.token
      const currentDate = new Date()
      const formattedDate = this.companyStore.formatDate(currentDate)
      this.configurationAws.Created = formattedDate
      this.configurationAws.Modified = formattedDate
      this.configurationAws.CreatedBy = this.userStore.userData.ID
      const stack = this.stacks.find((stack) => stack.Stacks === this.configurationAws.FleetNormal)
      this.configurationAws.FleetReadable = stack.Description

      var response = await this.configurationStore.createConfigurationAWS(this.configurationAws, token)
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successNewCloud'),
        }).then(() => {
          this.$router.push({ name: 'Aws Select' })
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: response.data.Message,
        })
      }
    },
    calculateEndDate() {
      if (this.configurationAws.StartDate && this.configurationAws.RuntimeInMonth) {
        let startDate = new Date(this.configurationAws.StartDate)
        const runtimeInMonth = this.configurationAws.RuntimeInMonth

        if (runtimeInMonth >= 1) {
          startDate.setMonth(startDate.getMonth() + runtimeInMonth)
        } else {
          const daysToAdd = 14
          startDate.setDate(startDate.getDate() + daysToAdd) // Add the calculated number of days
        }

        // Update the EndDate property
        this.$set(this.configurationAws, 'EndDate', startDate.toISOString().split('T')[0])
        this.renderKey++
      }
    },
    setFleetReadable() {
      // const fleetNormal = this.configurationAws.FleetNormal
      // const fleet = this.fleets.find((f) => f.value === fleetNormal)
      // this.configurationAws.FleetReadable = fleet.label
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
  },
  watch: {
    'configurationAws.StartDate': 'calculateEndDate',
    'configurationAws.RuntimeInMonth': 'calculateEndDate',
    'configurationAws.FleetNormal': 'setFleetReadable',
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationStore = useConfigurationStore()

    return { companyStore, userStore, configurationStore }
  },
}
</script>

<style scoped>
body > div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  max-width: unset !important;
}

.error,
.success {
  margin-right: 24px;
}
</style>

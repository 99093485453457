var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "mdesign-config-content": !_vm.viewStyling,
        "mdesign-config-content-view": _vm.viewStyling,
      },
    },
    [
      !_vm.viewStyling
        ? _c(
            "div",
            { staticClass: "loader-wrapper" },
            [_c("loader", { attrs: { loader: _vm.loader } })],
            1
          )
        : _vm._e(),
      _vm.showModal
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "template-modal" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("inputsTemplate"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "md-layout modal-content" }, [
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-small-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", [_vm._v(_vm._s(_vm.$t("name")))]),
                                  _c("md-input", {
                                    attrs: { type: "text", id: "templateName" },
                                    model: {
                                      value: _vm.newTemplateName,
                                      callback: function ($$v) {
                                        _vm.newTemplateName = $$v
                                      },
                                      expression: "newTemplateName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                349930310
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "space-evenly" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-primary",
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v(_vm._s(_vm.$t("cancel")))]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-simple",
                        on: { click: _vm.saveTemplate },
                      },
                      [_vm._v(_vm._s(_vm.$t("save")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.showModalCreate
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { modalWidth: "600", name: "template-modal" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("infoText"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "md-layout modal-content" }, [
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-size-100" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100",
                                },
                                [
                                  _c(
                                    "md-field",
                                    {
                                      staticClass: "has-count-textarea",
                                      class: { "too-long": _vm.getTooLong },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("name"))),
                                      ]),
                                      _c("md-input", {
                                        attrs: {
                                          type: "text",
                                          id: "infoName",
                                          maxlength: "30",
                                        },
                                        model: {
                                          value: _vm.infoName,
                                          callback: function ($$v) {
                                            _vm.infoName = $$v
                                          },
                                          expression: "infoName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-small-size-100",
                                },
                                [
                                  _c(
                                    "md-field",
                                    {
                                      staticClass: "has-count-textarea",
                                      class: { "too-long": _vm.getTooLong },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("description"))),
                                      ]),
                                      _c("md-textarea", {
                                        attrs: {
                                          placeholder: _vm.placeholderText,
                                          type: "text",
                                          id: "infoText",
                                          maxlength: "222",
                                        },
                                        model: {
                                          value: _vm.infoText,
                                          callback: function ($$v) {
                                            _vm.infoText = $$v
                                          },
                                          expression: "infoText",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                205923851
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "space-evenly" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-primary",
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v(_vm._s(_vm.$t("cancel")))]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-simple",
                        attrs: { disabled: _vm.getTooLong },
                        on: { click: _vm.createConfiguration },
                      },
                      [_vm._v(_vm._s(_vm.$t("save")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.showModalTestCount
        ? _c(
            "modal",
            {
              staticClass: "modal-content",
              attrs: { name: "template-modal" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.$t("testCount"))),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "md-layout modal-content" }, [
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-small-size-100" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("count"))),
                                  ]),
                                  _c("md-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.testCount,
                                      callback: function ($$v) {
                                        _vm.testCount = $$v
                                      },
                                      expression: "testCount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3404971598
              ),
            },
            [
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "space-evenly" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-primary",
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v(_vm._s(_vm.$t("cancel")))]
                    ),
                    _c(
                      "md-button",
                      {
                        staticClass: "md-round md-simple",
                        on: { click: _vm.confirmTestCount },
                      },
                      [_vm._v(_vm._s(_vm.$t("confirm")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c("MdesignConfigNavCard", {
        attrs: { view: _vm.viewStyling },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "header-div" },
                  [
                    _c("h4", [_vm._v(_vm._s(_vm.$t("mdesignScope")))]),
                    !_vm.viewStyling &&
                    _vm.allow("{14e7f1ca-fb12-4460-9f87-a3c21ad16d05}") &&
                    !_vm.noDependenciesFound
                      ? _c(
                          "md-switch",
                          {
                            model: {
                              value: _vm.showDependencies,
                              callback: function ($$v) {
                                _vm.showDependencies = $$v
                              },
                              expression: "showDependencies",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("showDependencies")))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "contentNav",
            fn: function () {
              return [
                _c("md-table", {
                  key: _vm.renderKey,
                  staticClass: "md-table-mdesign-configuration",
                  class: { "display-contents": _vm.viewStyling },
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function (ref) {
                        var item = ref.item
                        return _c(
                          "md-table-row",
                          {
                            class: {
                              "md-selected md-has-selection": item.isSelected,
                              "hidden-dep":
                                (item.isDependency && !_vm.showDependencies) ||
                                (_vm.viewStyling && item.Count < 0),
                              "cursor-auto": _vm.viewStyling,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleRowClick_level_1($event, item)
                              },
                            },
                          },
                          [
                            _c("md-table-cell", { attrs: { "md-label": "" } }, [
                              _c(
                                "div",
                                { staticClass: "image-cell-container" },
                                [
                                  item.isDependency
                                    ? _c("div", [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.techDepIcon,
                                            title: "",
                                          },
                                        }),
                                      ])
                                    : !_vm.imageData[item.ProductLevelOneID]
                                    ? _c("div", [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.regularImg,
                                            title: "",
                                          },
                                        }),
                                      ])
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "image-container config-overview",
                                          class: {
                                            "has-items":
                                              _vm.getNames(item).length > 0,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.imageData[
                                                item.ProductLevelOneID
                                              ],
                                            },
                                          }),
                                        ]
                                      ),
                                ]
                              ),
                            ]),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "pl-8",
                                class: {
                                  "has-items": _vm.getNames(item).length > 0,
                                },
                                attrs: { "md-label": _vm.$t("name") },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(item.Name) +
                                      " " +
                                      _vm._s(item.isSelected ? _vm.navName : "")
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "module-info": !_vm.viewStyling,
                                      "module-info-view": _vm.viewStyling,
                                    },
                                  },
                                  _vm._l(_vm.getNames(item), function (name) {
                                    return _c(
                                      "div",
                                      {
                                        key: name,
                                        staticClass: "info-name",
                                        class: {
                                          "less-selected": item.lessSelected,
                                        },
                                      },
                                      [_c("p", [_vm._v(_vm._s(name))])]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                            _c("md-table-cell", { staticClass: "mw-20" }, [
                              !_vm.readonly && item.Count !== 9999
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "animated-icon",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.reduceCount(1, item)
                                        },
                                      },
                                    },
                                    [_vm._v("remove")]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "center mw-80",
                                attrs: { "md-label": _vm.$t("count") },
                              },
                              [
                                _c("md-field", { staticClass: "input-field" }, [
                                  item.Count !== 9999
                                    ? _c(
                                        "span",
                                        [
                                          _c("md-input", {
                                            class: {
                                              "view-input": _vm.viewStyling,
                                            },
                                            attrs: {
                                              inputmode: "numeric",
                                              min: "0",
                                              max: "100",
                                              type: "Number",
                                              disabled: _vm.readonly,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleSetMany(
                                                  1,
                                                  item
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.Count,
                                              callback: function ($$v) {
                                                _vm.$set(item, "Count", $$v)
                                              },
                                              expression: "item.Count",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "md-input",
                                          class: {
                                            "view-input": _vm.viewStyling,
                                          },
                                        },
                                        [_vm._v("uncounted")]
                                      ),
                                ]),
                              ],
                              1
                            ),
                            _c("md-table-cell", { staticClass: "mw-20" }, [
                              !_vm.readonly && item.Count !== 9999
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "animated-icon pr-8",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.addCount(1, item)
                                        },
                                      },
                                    },
                                    [_vm._v("add")]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "md-table-cell",
                              {
                                staticClass: "fix-width-25",
                                attrs: {
                                  id: item.Name,
                                  "md-label": _vm.$t("limited"),
                                },
                              },
                              [
                                _c(
                                  "md-field",
                                  { staticClass: "input-field just-cont-sb" },
                                  [
                                    item.Limited !== "9999-12-31"
                                      ? _c("span", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.Limited,
                                                expression: "item.Limited",
                                              },
                                            ],
                                            class: {
                                              "view-input": _vm.viewStyling,
                                            },
                                            attrs: {
                                              disabled: _vm.readonly,
                                              type: "date",
                                            },
                                            domProps: { value: item.Limited },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "Limited",
                                                    $event.target.value
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.setDate(item, 1)
                                                },
                                              ],
                                            },
                                          }),
                                        ])
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "md-input",
                                            class: {
                                              "view-input": _vm.viewStyling,
                                            },
                                            attrs: { type: "text" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("unlimited")))]
                                        ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            !_vm.readonly
                              ? _c(
                                  "md-table-cell",
                                  [
                                    _c(
                                      "md-field",
                                      { staticClass: "input-field" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: {
                                              selected:
                                                item.Limited === "9999-12-31",
                                              "icon-container":
                                                !_vm.viewStyling,
                                              "not-selected":
                                                item.Limited !== "9999-12-31",
                                            },
                                          },
                                          [
                                            !_vm.readonly !== "9999-12-31"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "animated-icon",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setUnlimited(
                                                          item,
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" hourglass_full ")]
                                                )
                                              : _vm._e(),
                                            _c("md-tooltip", [
                                              _vm._v(
                                                _vm._s(_vm.$t("unlimited"))
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        item.Test !== -1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "icon-container",
                                                class: {
                                                  selected: item.Test === 1,
                                                  "not-selected":
                                                    item.Test === 0,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "animated-icon",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setTest50(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" looks_5 ")]
                                                ),
                                                _c("md-tooltip", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("test50") +
                                                        " - " +
                                                        _vm.$t("count") +
                                                        ": " +
                                                        item.TestCount
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.Student !== -1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "icon-container",
                                                class: {
                                                  selected: item.Student === 1,
                                                  "not-selected":
                                                    item.Student === 0,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "animated-icon",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStudent(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("school")]
                                                ),
                                                _c("md-tooltip", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("student"))
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.Connect !== -1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "icon-container",
                                                class: {
                                                  selected: item.Connect === 1,
                                                  "not-selected":
                                                    item.Connect === 0,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "animated-icon",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setConnect(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("cable")]
                                                ),
                                                _c("md-tooltip", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("connect"))
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.EditModules !== -1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "icon-container",
                                                class: {
                                                  selected:
                                                    item.EditModules === 1,
                                                  "not-selected":
                                                    item.EditModules === 0,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "animated-icon",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setEditModules(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("edit_note")]
                                                ),
                                                _c("md-tooltip", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("editModules")
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.Uncounted !== -1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "icon-container",
                                                class: {
                                                  selected: item.Count === 9999,
                                                  "not-selected":
                                                    item.Count !== 9999,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "animated-icon",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setUncounted(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("all_inclusive")]
                                                ),
                                                _c("md-tooltip", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("uncounted"))
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "icon-container last-icon-container",
                                          },
                                          [
                                            item.ProductsLevel_2.length > 1
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.expand(item)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "animated-icon",
                                                      },
                                                      [_vm._v("navigate_next")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.configurationModel.ProductsLevel_1,
                    callback: function ($$v) {
                      _vm.$set(_vm.configurationModel, "ProductsLevel_1", $$v)
                    },
                    expression: "configurationModel.ProductsLevel_1",
                  },
                }),
                _c("div", { staticClass: "md-layout-item md-size-100 spacer" }),
                !_vm.readonly
                  ? _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-25" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-simple md-round btn-custom",
                              on: {
                                click: function ($event) {
                                  return _vm.back()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("back")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-25" },
                        [
                          _vm.showControls && !_vm.readonly
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-simple md-round btn-custom",
                                  on: { click: _vm.handleSaveTemplate },
                                },
                                [_vm._v(_vm._s(_vm.$t("saveAsTemplate")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-25 text-right" },
                        [
                          !_vm.readonly
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-simple md-round btn-custom",
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.addLevel_1()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("addProduct")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-25 text-right" },
                        [
                          !_vm.readonly
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-primary btn-custom",
                                  on: { click: _vm.handleCreate },
                                },
                                [_vm._v(_vm._s(_vm.btnName))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "transition",
        { staticClass: "no-animation", attrs: { name: "slide-right-to-left" } },
        [
          _vm.editable > 1 && !_vm.readonly
            ? _c("MdesignConfigEditCard", {
                key: _vm.transitionKey,
                staticClass: "sticky-top",
                attrs: { imgData: _vm.imageData[_vm.productLvlOneID] },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c(
                            "h4",
                            { class: { "action-header": _vm.editable === 3 } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "action-span",
                                  on: {
                                    click: function ($event) {
                                      _vm.editable === 3 &&
                                        _vm.expand(_vm.editCardHeader)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.editCardHeader.Name))]
                              ),
                            ]
                          ),
                          _c("h5", [_vm._v(_vm._s(_vm.editTableName))]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "contentEdit",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              key: _vm.renderKey,
                              staticClass: "mdesign-configuration-edit",
                            },
                            [
                              _vm.editable === 2
                                ? _c("md-table", {
                                    staticClass:
                                      "md-table-mdesign-configuration",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "md-table-row",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return _c(
                                              "md-table-row",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRowClick_level_2(
                                                      $event,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "md-table-cell",
                                                  {
                                                    attrs: {
                                                      "md-sort-by": "id",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.Sorting.toString().padStart(
                                                              2,
                                                              "0"
                                                            ) + "."
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  {
                                                    attrs: {
                                                      "md-sort-by": "id",
                                                      "md-label":
                                                        _vm.$t("name"),
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.Name) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  { staticClass: "mw-20" },
                                                  [
                                                    item.Count !== 9999
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "animated-icon",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.reduceCount(
                                                                  2,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("remove")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  {
                                                    staticClass: "mw-80",
                                                    attrs: {
                                                      "md-label":
                                                        _vm.$t("count"),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "md-field",
                                                      {
                                                        staticClass:
                                                          "input-field",
                                                      },
                                                      [
                                                        item.Count !== 9999
                                                          ? _c(
                                                              "span",
                                                              [
                                                                _c("md-input", {
                                                                  attrs: {
                                                                    inputmode:
                                                                      "numeric",
                                                                    min: "0",
                                                                    max: "100",
                                                                    type: "number",
                                                                    disabled:
                                                                      _vm.readonly,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.setMany(
                                                                          2,
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.Count,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "Count",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.Count",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "md-input",
                                                                class: {
                                                                  "view-input":
                                                                    _vm.viewStyling,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "uncounted"
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  { staticClass: "mw-20" },
                                                  [
                                                    item.Count !== 9999
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "animated-icon pr-8",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.addCount(
                                                                  2,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("add")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  {
                                                    attrs: {
                                                      "md-label":
                                                        _vm.$t("limited"),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "md-field",
                                                      {
                                                        staticClass:
                                                          "input-field just-cont-sb",
                                                      },
                                                      [
                                                        item.Limited !==
                                                        "9999-12-31"
                                                          ? _c("span", [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item.Limited,
                                                                    expression:
                                                                      "item.Limited",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.readonly,
                                                                  type: "date",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    item.Limited,
                                                                },
                                                                on: {
                                                                  input: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        item,
                                                                        "Limited",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setDate(
                                                                        item,
                                                                        2
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "unlimited"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  [
                                                    _c(
                                                      "md-field",
                                                      {
                                                        staticClass:
                                                          "input-field",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon-container",
                                                            class: {
                                                              selected:
                                                                item.Limited ===
                                                                "9999-12-31",
                                                              "not-selected":
                                                                item.Limited !==
                                                                "9999-12-31",
                                                            },
                                                          },
                                                          [
                                                            !_vm.readonly
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "animated-icon",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.setUnlimited(
                                                                            item,
                                                                            2
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " hourglass_full "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        item.ProductsLevel_3
                                                          .length > 0
                                                          ? _c(
                                                              "span",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.expand_2(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "animated-icon",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "navigate_next"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2600314593
                                    ),
                                    model: {
                                      value: _vm.edit_level_2,
                                      callback: function ($$v) {
                                        _vm.edit_level_2 = $$v
                                      },
                                      expression: "edit_level_2",
                                    },
                                  })
                                : _vm._e(),
                              _vm.editable === 3
                                ? _c("md-table", {
                                    staticClass:
                                      "md-table-mdesign-configuration",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "md-table-row",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return _c(
                                              "md-table-row",
                                              {},
                                              [
                                                _c(
                                                  "md-table-cell",
                                                  {
                                                    attrs: {
                                                      "md-sort-by": "id",
                                                      "md-label":
                                                        _vm.$t("name"),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.Name) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  { staticClass: "mw-20" },
                                                  [
                                                    item.Count !== 9999
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "animated-icon",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.reduceCount(
                                                                  3,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("remove")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  {
                                                    staticClass: "mw-80",
                                                    attrs: {
                                                      "md-label":
                                                        _vm.$t("count"),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "md-field",
                                                      {
                                                        staticClass:
                                                          "input-field",
                                                      },
                                                      [
                                                        item.Count !== 9999
                                                          ? _c(
                                                              "span",
                                                              [
                                                                _c("md-input", {
                                                                  attrs: {
                                                                    inputmode:
                                                                      "numeric",
                                                                    min: "0",
                                                                    max: "100",
                                                                    type: "number",
                                                                    disabled:
                                                                      _vm.readonly,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.setMany(
                                                                          3,
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.Count,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "Count",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.Count",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "md-input",
                                                                class: {
                                                                  "view-input":
                                                                    _vm.viewStyling,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "uncounted"
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  { staticClass: "mw-20" },
                                                  [
                                                    item.Count !== 9999
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "animated-icon pr-8",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.addCount(
                                                                  3,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("add")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  {
                                                    attrs: {
                                                      "md-label":
                                                        _vm.$t("limited"),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "md-field",
                                                      {
                                                        staticClass:
                                                          "input-field just-cont-sb",
                                                      },
                                                      [
                                                        item.Limited !==
                                                        "9999-12-31"
                                                          ? _c("span", [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item.Limited,
                                                                    expression:
                                                                      "item.Limited",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.readonly,
                                                                  type: "date",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    item.Limited,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        item,
                                                                        "Limited",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ])
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "md-input",
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "unlimited"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  [
                                                    _c(
                                                      "md-field",
                                                      {
                                                        staticClass:
                                                          "input-field just-cont-sb",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon-container",
                                                            class: {
                                                              selected:
                                                                item.Limited ===
                                                                "9999-12-31",
                                                              "not-selected":
                                                                item.Limited !==
                                                                "9999-12-31",
                                                            },
                                                          },
                                                          [
                                                            !_vm.readonly
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "animated-icon",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.setUnlimited(
                                                                            item,
                                                                            3
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " hourglass_full "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2212047835
                                    ),
                                    model: {
                                      value: _vm.edit_level_3,
                                      callback: function ($$v) {
                                        _vm.edit_level_3 = $$v
                                      },
                                      expression: "edit_level_3",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  888008460
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
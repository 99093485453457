<template>
  <span>
    <a class="md-primary md-round classic-modal link-settings" @click="settingsModal = true">{{ linkToSettings }}</a>

    <div class="mmodal-content">
      <modal v-if="settingsModal">
        <template slot="header">
          <h4 class="modal-title">{{ $t('cookieSettingsTitle') }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="settingsModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body" class="modal-body">
          <div>
            {{ $t('cookiesSettingsText') }}
            <a :href="$t('privacyLink')" target="_blank" class="privacy-policy">{{ $t('privacyPolicy') }}</a
            >.
            <hr />
            <div class="essential-cookies">
              <md-switch v-model="essis" disabled></md-switch>
              <div class="essential-cookies-descrp">
                <div>
                  {{ $t('essentialCookiesTitle') }}
                  <span class="es-required">{{ $t('alwaysRequired') }}</span>
                </div>
                <div>{{ $t('essentialCookiesText') }}</div>
                <div class="purposes">{{ $t('purposeEssential') }}</div>
              </div>
            </div>
            <div class="marketing-cookies">
              <md-switch v-model="stats"></md-switch>
              <div class="marketing-cookies-descrp">
                <div>{{ $t('marketingCookies') }}</div>
                <div>{{ $t('marketingCookiesText') }}</div>
                <div class="purposes">{{ $t('purposeMarketing') }}</div>
              </div>
            </div>
          </div>
        </template>

        <template slot="footer">
          <md-button class="md-primary md-round button-width" @click=";[settingsModalSave(), classicModalHide()]">{{
            $t('saveSelection')
          }}</md-button>
        </template>
        <template slot="footer">
          <md-button class="md-primary md-round button-width" @click="settingsModalDecline">{{
            $t('decline')
          }}</md-button>
        </template>
      </modal>
    </div>
  </span>
</template>

<script>
import { Modal } from '..'
import VueCookies from 'vue-cookies'
import { unsetStatsGA } from '../../utils'

export default {
  props: {
    linkToSettings: String,
  },
  components: {
    Modal,
  },
  data() {
    return {
      type: ['', 'info', 'success', 'warning', 'danger'],
      settingsModal: false,
      essis: true,
      stats: false,
    }
  },
  methods: {
    classicModalHide: function () {
      this.$root.$refs.CookieNotice.classicModalHide()
    },
    settingsModalHide: function () {
      this.settingsModal = false
    },
    settingsModalSave: function () {
      this.settingsModal = false
      VueCookies.set('my.mdesign-klaro', { essis: this.essis, stats: this.stats })
      !this.stats && unsetStatsGA()
    },
    settingsModalDecline: function () {
      this.stats = false
      VueCookies.set('my.mdesign-klaro', { essis: this.essis, stats: this.stats })
      setTimeout(() => (this.settingsModal = false), 500)
      setTimeout(() => this.classicModalHide(), 500)
    },
  },
}
</script>
<style>
a:hover {
  cursor: pointer;
}

.modal-container {
  color: #3c4858;
  @media screen and (min-width: 768px) {
    min-width: 500px;
  }
}

.privacy-policy {
  all: unset !important;
  text-decoration: underline !important;
}
a.privacy-policy {
  color: #3c4858 !important;
}
.privacy-policy:hover {
  cursor: pointer !important;
}

.essential-cookies,
.marketing-cookies {
  display: flex;
}

.essential-cookies-descrp,
.marketing-cookies-descrp {
  text-align: left;
  margin-left: 16px;
}

.es-required,
.purposes {
  font-size: 0.8em;
  color: #878989;
  opacity: 0.5;
}

.link-settings {
  color: #3c4858 !important;
  text-decoration: underline !important;
}
.button-width {
  width: 120px;
}
</style>

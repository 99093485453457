<template>
  <div :class="{ 'mdesign-config-content': !viewStyling, 'mdesign-config-content-view': viewStyling }">
    <div v-if="!viewStyling" class="loader-wrapper">
      <loader :loader="loader" />
    </div>
    <modal v-if="showModal" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('inputsTemplate') }}</h4>
      </template>
      <template slot="footer">
        <div class="space-evenly">
          <md-button @click="closeModal" class="md-round md-primary">{{ $t('cancel') }}</md-button>
          <md-button @click="saveTemplate" class="md-round md-simple">{{ $t('save') }}</md-button>
        </div>
      </template>
      <template v-slot:body>
        <div class="md-layout modal-content">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label>{{ $t('name') }}</label>
              <md-input v-model="newTemplateName" type="text" id="templateName"></md-input>
            </md-field>
          </div>
        </div>
      </template>
    </modal>
    <modal :modalWidth="'600'" v-if="showModalCreate" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('infoText') }}</h4>
      </template>
      <template slot="footer">
        <div class="space-evenly">
          <md-button @click="closeModal" class="md-round md-primary">{{ $t('cancel') }}</md-button>
          <md-button @click="createConfiguration" class="md-round md-simple" :disabled="getTooLong">{{
            $t('save')
          }}</md-button>
        </div>
      </template>
      <template v-slot:body>
        <div class="md-layout modal-content">
          <div class="md-layout-item md-size-100">
            <div class="md-layout-item md-small-size-100">
              <md-field class="has-count-textarea" :class="{ 'too-long': getTooLong }">
                <label>{{ $t('name') }}</label>
                <md-input v-model="infoName" type="text" id="infoName" maxlength="30"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="has-count-textarea" :class="{ 'too-long': getTooLong }">
                <label>{{ $t('description') }}</label>
                <md-textarea
                  :placeholder="placeholderText"
                  v-model="infoText"
                  type="text"
                  id="infoText"
                  maxlength="222"
                ></md-textarea>
              </md-field>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <modal v-if="showModalTestCount" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('testCount') }}</h4>
      </template>
      <template slot="footer">
        <div class="space-evenly">
          <md-button @click="closeModal" class="md-round md-primary">{{ $t('cancel') }}</md-button>
          <md-button @click="confirmTestCount" class="md-round md-simple">{{ $t('confirm') }}</md-button>
        </div>
      </template>
      <template v-slot:body>
        <div class="md-layout modal-content">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label>{{ $t('count') }}</label>
              <md-input v-model="testCount" type="number"></md-input>
            </md-field>
          </div>
        </div>
      </template>
    </modal>

    <MdesignConfigNavCard :view="viewStyling">
      <template #header>
        <div class="header-div">
          <h4>{{ $t('mdesignScope') }}</h4>
          <md-switch
            v-if="!viewStyling && allow('{14e7f1ca-fb12-4460-9f87-a3c21ad16d05}') && !noDependenciesFound"
            v-model="showDependencies"
            >{{ $t('showDependencies') }}</md-switch
          >
        </div>
      </template>

      <template v-slot:contentNav>
        <md-table
          v-model="configurationModel.ProductsLevel_1"
          :key="renderKey"
          class="md-table-mdesign-configuration"
          :class="{ 'display-contents': viewStyling }"
        >
          <md-table-row
            :class="{
              'md-selected md-has-selection': item.isSelected,
              'hidden-dep': (item.isDependency && !showDependencies) || (viewStyling && item.Count < 0),
              'cursor-auto': viewStyling,
            }"
            slot="md-table-row"
            slot-scope="{ item }"
            @click="handleRowClick_level_1($event, item)"
          >
            <md-table-cell md-label="" class="">
              <!-- v-if="viewStyling"-->
              <div class="image-cell-container">
                <div v-if="item.isDependency">
                  <img :src="techDepIcon" title="" />
                </div>
                <div v-else-if="!imageData[item.ProductLevelOneID]">
                  <img :src="regularImg" title="" />
                </div>
                <div v-else class="image-container config-overview" :class="{ 'has-items': getNames(item).length > 0 }">
                  <img :src="imageData[item.ProductLevelOneID]" />
                </div>
              </div>
            </md-table-cell>

            <md-table-cell :md-label="$t('name')" class="pl-8" :class="{ 'has-items': getNames(item).length > 0 }">
              <div>{{ item.Name }} {{ item.isSelected ? navName : '' }}</div>
              <div class="" :class="{ 'module-info': !viewStyling, 'module-info-view': viewStyling }">
                <div
                  class="info-name"
                  :class="{ 'less-selected': item.lessSelected }"
                  v-for="name in getNames(item)"
                  :key="name"
                >
                  <p>{{ name }}</p>
                </div>
              </div>
            </md-table-cell>

            <md-table-cell class="mw-20">
              <span v-if="!readonly && item.Count !== 9999" class="animated-icon" @click.prevent="reduceCount(1, item)"
                >remove</span
              >
            </md-table-cell>

            <md-table-cell class="center mw-80" :md-label="$t('count')">
              <md-field class="input-field">
                <span v-if="item.Count !== 9999">
                  <md-input
                    @change="handleSetMany(1, item)"
                    inputmode="numeric"
                    min="0"
                    max="100"
                    type="Number"
                    v-model="item.Count"
                    :disabled="readonly"
                    :class="{ 'view-input': viewStyling }"
                  ></md-input
                ></span>
                <span class="md-input" :class="{ 'view-input': viewStyling }" v-else>uncounted</span>
              </md-field>
            </md-table-cell>

            <md-table-cell class="mw-20">
              <span
                v-if="!readonly && item.Count !== 9999"
                class="animated-icon pr-8"
                @click.prevent="addCount(1, item)"
                >add</span
              >
            </md-table-cell>

            <md-table-cell class="fix-width-25" :id="item.Name" :md-label="$t('limited')">
              <md-field class="input-field just-cont-sb">
                <span v-if="item.Limited !== '9999-12-31'"
                  ><input
                    :disabled="readonly"
                    type="date"
                    @input="setDate(item, 1)"
                    v-model="item.Limited"
                    :class="{ 'view-input': viewStyling }"
                  />
                </span>
                <span class="md-input" v-else type="text" :class="{ 'view-input': viewStyling }">{{
                  $t('unlimited')
                }}</span>
              </md-field>
            </md-table-cell>
            <md-table-cell v-if="!readonly">
              <md-field class="input-field">
                <!-- md-pr-30 -->
                <div
                  class=""
                  :class="{
                    selected: item.Limited === '9999-12-31',
                    'icon-container': !viewStyling,
                    'not-selected': item.Limited !== '9999-12-31',
                  }"
                >
                  <span v-if="!readonly !== '9999-12-31'" class="animated-icon" @click="setUnlimited(item, 1)">
                    hourglass_full
                  </span>
                  <md-tooltip>{{ $t('unlimited') }}</md-tooltip>
                </div>
                <div
                  v-if="item.Test !== -1"
                  class="icon-container"
                  :class="{ selected: item.Test === 1, 'not-selected': item.Test === 0 }"
                >
                  <span class="animated-icon" @click="setTest50(item)"> looks_5 </span>

                  <md-tooltip>{{ $t('test50') + ' - ' + $t('count') + ': ' + item.TestCount }}</md-tooltip>
                </div>
                <div
                  v-if="item.Student !== -1"
                  class="icon-container"
                  :class="{ selected: item.Student === 1, 'not-selected': item.Student === 0 }"
                >
                  <span class="animated-icon" @click="setStudent(item)">school</span>
                  <md-tooltip>{{ $t('student') }}</md-tooltip>
                </div>
                <div
                  v-if="item.Connect !== -1"
                  class="icon-container"
                  :class="{ selected: item.Connect === 1, 'not-selected': item.Connect === 0 }"
                >
                  <span class="animated-icon" @click="setConnect(item)">cable</span>
                  <md-tooltip>{{ $t('connect') }}</md-tooltip>
                </div>
                <div
                  v-if="item.EditModules !== -1"
                  class="icon-container"
                  :class="{ selected: item.EditModules === 1, 'not-selected': item.EditModules === 0 }"
                >
                  <span class="animated-icon" @click="setEditModules(item)">edit_note</span>
                  <md-tooltip>{{ $t('editModules') }}</md-tooltip>
                </div>
                <div
                  v-if="item.Uncounted !== -1"
                  class="icon-container"
                  :class="{ selected: item.Count === 9999, 'not-selected': item.Count !== 9999 }"
                >
                  <span class="animated-icon" @click="setUncounted(item)">all_inclusive</span>
                  <md-tooltip>{{ $t('uncounted') }}</md-tooltip>
                </div>
                <div class="icon-container last-icon-container">
                  <span v-if="item.ProductsLevel_2.length > 1" @click="expand(item)">
                    <span class="animated-icon">navigate_next</span>
                  </span>
                </div>
              </md-field>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <div class="md-layout-item md-size-100 spacer"></div>
        <div class="md-layout" v-if="!readonly">
          <div class="md-layout-item md-size-25">
            <md-button class="md-simple md-round btn-custom" @click="back()">{{ $t('back') }}</md-button>
          </div>
          <div class="md-layout-item md-size-25">
            <md-button
              v-if="showControls && !readonly"
              class="md-simple md-round btn-custom"
              @click="handleSaveTemplate"
              >{{ $t('saveAsTemplate') }}</md-button
            >
          </div>
          <div class="md-layout-item md-size-25 text-right">
            <md-button class="md-simple md-round btn-custom" @click.native="addLevel_1()" v-if="!readonly">{{
              $t('addProduct')
            }}</md-button>
          </div>
          <div class="md-layout-item md-size-25 text-right">
            <md-button class="md-primary btn-custom" @click="handleCreate" v-if="!readonly">{{ btnName }}</md-button>
          </div>
        </div>
      </template>
    </MdesignConfigNavCard>
    <transition :name="'slide-right-to-left'" class="no-animation">
      <MdesignConfigEditCard
        class="sticky-top"
        :imgData="imageData[productLvlOneID]"
        v-if="editable > 1 && !readonly"
        :key="transitionKey"
      >
        <template #header>
          <h4 :class="{ 'action-header': editable === 3 }">
            <span class="action-span" @click="editable === 3 && expand(editCardHeader)">{{ editCardHeader.Name }}</span>
          </h4>
          <h5>{{ editTableName }}</h5>
        </template>
        <template v-slot:contentEdit>
          <div :key="renderKey" class="mdesign-configuration-edit">
            <md-table v-if="editable === 2" v-model="edit_level_2" class="md-table-mdesign-configuration">
              <md-table-row slot="md-table-row" slot-scope="{ item }" @click="handleRowClick_level_2($event, item)">
                <md-table-cell md-sort-by="id">
                  <div>
                    {{ `${item.Sorting.toString().padStart(2, '0')}.` }}
                  </div>
                </md-table-cell>

                <md-table-cell md-sort-by="id" :md-label="$t('name')">
                  <div>
                    {{ item.Name }}
                  </div>
                </md-table-cell>

                <md-table-cell class="mw-20">
                  <span v-if="item.Count !== 9999" class="animated-icon" @click.prevent="reduceCount(2, item)"
                    >remove</span
                  >
                </md-table-cell>

                <md-table-cell class="mw-80" :md-label="$t('count')">
                  <md-field class="input-field">
                    <span v-if="item.Count !== 9999">
                      <md-input
                        @change="setMany(2, item)"
                        inputmode="numeric"
                        min="0"
                        max="100"
                        type="number"
                        v-model="item.Count"
                        :disabled="readonly"
                      ></md-input
                    ></span>

                    <span class="md-input" :class="{ 'view-input': viewStyling }" v-else>uncounted</span>
                  </md-field>
                </md-table-cell>

                <md-table-cell class="mw-20">
                  <span v-if="item.Count !== 9999" class="animated-icon pr-8" @click.prevent="addCount(2, item)"
                    >add</span
                  >
                </md-table-cell>

                <md-table-cell :md-label="$t('limited')">
                  <md-field class="input-field just-cont-sb">
                    <span v-if="item.Limited !== '9999-12-31'"
                      ><input :disabled="readonly" @input="setDate(item, 2)" type="date" v-model="item.Limited" />
                    </span>
                    <span v-else>{{ $t('unlimited') }}</span>
                  </md-field>
                </md-table-cell>

                <md-table-cell>
                  <md-field class="input-field">
                    <div
                      class="icon-container"
                      :class="{
                        selected: item.Limited === '9999-12-31',
                        'not-selected': item.Limited !== '9999-12-31',
                      }"
                    >
                      <span v-if="!readonly" class="animated-icon" @click="setUnlimited(item, 2)">
                        hourglass_full
                      </span>
                    </div>

                    <span v-if="item.ProductsLevel_3.length > 0" @click="expand_2(item)">
                      <span class="animated-icon">navigate_next</span>
                    </span>
                  </md-field>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <md-table v-if="editable === 3" v-model="edit_level_3" class="md-table-mdesign-configuration">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-sort-by="id" :md-label="$t('name')">
                  {{ item.Name }}
                </md-table-cell>

                <md-table-cell class="mw-20">
                  <span v-if="item.Count !== 9999" class="animated-icon" @click.prevent="reduceCount(3, item)"
                    >remove</span
                  >
                </md-table-cell>

                <md-table-cell class="mw-80" :md-label="$t('count')">
                  <md-field class="input-field">
                    <span v-if="item.Count !== 9999">
                      <md-input
                        @change="setMany(3, item)"
                        inputmode="numeric"
                        min="0"
                        max="100"
                        type="number"
                        v-model="item.Count"
                        :disabled="readonly"
                      ></md-input>
                    </span>
                    <span class="md-input" :class="{ 'view-input': viewStyling }" v-else>uncounted</span>
                  </md-field>
                </md-table-cell>

                <md-table-cell class="mw-20">
                  <span v-if="item.Count !== 9999" class="animated-icon pr-8" @click.prevent="addCount(3, item)"
                    >add</span
                  >
                </md-table-cell>

                <md-table-cell :md-label="$t('limited')">
                  <md-field class="input-field just-cont-sb">
                    <span v-if="item.Limited !== '9999-12-31'"
                      ><input :disabled="readonly" type="date" v-model="item.Limited" />
                    </span>
                    <span class="md-input" v-else type="text">{{ $t('unlimited') }}</span>
                  </md-field>
                </md-table-cell>

                <md-table-cell>
                  <md-field class="input-field just-cont-sb">
                    <div
                      class="icon-container"
                      :class="{
                        selected: item.Limited === '9999-12-31',
                        'not-selected': item.Limited !== '9999-12-31',
                      }"
                    >
                      <span v-if="!readonly" class="animated-icon" @click="setUnlimited(item, 3)">
                        hourglass_full
                      </span>
                    </div>
                  </md-field>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </template>
      </MdesignConfigEditCard>
    </transition>
  </div>
</template>

<script>
import MdesignConfigNavCard from '../../../components/Cards/MdesignConfigNavCard.vue'
import MdesignConfigEditCard from '../../../components/Cards/MdesignConfigEditCard.vue'
import { useConfigurationProductLevelStore } from '../../../stores/configurationProductLevel.js'
import { useUserStore } from '../../../stores/user.js'
import { useCompanyStore } from '../../../stores/company.js'
import Swal from 'sweetalert2'
import Modal from '../../../components/Modal.vue'
import defaultIcon from '@/../public/img/default_icon_128-128.png'
import technicalDependencyIcon from '@/../public/img/md_icon_128_128_technical_dependencies.png'
import { permissionMixin } from '@/pages/RoleManagement/permissionMixin'

export default {
  name: 'mdesign-configuration-form',
  mixins: [permissionMixin],
  components: { MdesignConfigNavCard, MdesignConfigEditCard, Modal },
  props: {
    propConfiguration: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      imageTable: {},
      imageRegular: '',
      regularImg: defaultIcon,
      techDepIcon: technicalDependencyIcon,
      switchValue: null,
      checked: false,
      renderKey: 0,
      editable: 0,
      navName: '',
      vorlageID: '',
      edit_level_2: [
        {
          ProductsLevel_3: [],
        },
      ],
      edit_level_3: [{}],
      configurationModel: {
        ProductsLevel_1: [
          {
            ProductsLevel_2: [
              {
                ProductsLevel_3: [{}],
              },
            ],
          },
        ],
      },
      selectedId: '',
      loader: false,
      showModal: false,
      newTemplateName: '',
      showControls: false,
      readonly: false,
      editTemplate: false,
      showModalCreate: false,
      showModalTestCount: false,
      infoText: '',
      infoName: '',
      placeholderText: '',
      viewStyling: false,
      btnName: '',
      testCountItem: {},
      testCount: 0,
      imageData: null,
      productLvlOneID: '',
      show: false,
      transitionKey: 0,
      transitionName: 'slide-right-to-left',
      showDependencies: false,
      noDependenciesFound: false,
    }
  },
  async beforeMount() {
    this.loader = true
    this.btnName = this.$t('save')
    if (this.propConfiguration !== null) {
      this.viewStyling = true
      this.readonly = true
    } else {
      this.viewStyling = false
      this.readonly = false
    }
    const token = this.userStore.token
    if (this.userStore.role.includes('Administrator')) this.showControls = true

    if (this.configurationProductLevelStore.product.ID === '') {
      this.$router.push({ name: 'Company Products' })
      return
    }
    this.vorlageID = this.configurationProductLevelStore.configurationVorlage.ID

    this.configurationModel = this.configurationProductLevelStore.configurationModel
    this.configurationModel.ProductsLevel_1.sort((a, b) => a.Sorting - b.Sorting)
    this.configurationModel.ProductsLevel_1.forEach((item) => ((item.isSelected = false), (item.lessSelected = false)))

    this.imageData = this.configurationProductLevelStore.imageData

    this.tableData = this.configurationModel

    this.addInvisibleTechnicalDependencies()

    this.loader = false
  },
  methods: {
    async saveTemplate() {
      const token = this.userStore.token
      if (this.newTemplateName === '') {
        await Swal.fire({
          title: this.$t('error'),
          text: this.$t('templateNameEmpty'),
          icon: 'warning',
        })
        return
      }
      this.loader = true
      var res = await this.configurationProductLevelStore.createConfigurationTemplate(token, this.newTemplateName)
      await this.configurationProductLevelStore.getTemplatesByProdHasConfig(token)
      this.loader = false
      this.showModal = false
      Swal.fire({
        title: this.$t('success'),
        text: this.$t('createTemplateSuccess'),
        icon: 'success',
      })
      return
    },
    handleSaveTemplate() {
      this.newTemplateName = this.generatePlaceholderText()
      this.showModal = true
      return
    },
    closeModal() {
      this.showModal = false
      this.showModalCreate = false
      this.showModalTestCount = false
    },
    addCount(level, config) {
      this.configurationModel = this.configurationProductLevelStore.addCount(level, config)
      if (config.LicName === 'E') this.explorerCountDependency(config)
    },
    reduceCount(level, config) {
      if (config.Count <= 0) return
      if (config.isDependency) {
        config.Count = -1
        this.setMany(1, config)
        return
      }
      if (config.LicName === 'E') {
        this.configurationModel.ProductsLevel_1.forEach((level_1) => {
          if (level_1.LicNameParent === 'E' || level_1.LicName === 'E') {
            this.configurationModel = this.configurationProductLevelStore.reduceCount(level, level_1)
          }
        })
      } else {
        this.configurationModel = this.configurationProductLevelStore.reduceCount(level, config)
      }
    },
    selectAll() {
      this.configurationModel.ProductsLevel_1.forEach((level_1) => {
        if (level_1.Count <= 0) {
          this.addCount(1, level_1)
        }
      })
    },
    deselectAll() {
      this.configurationModel.ProductsLevel_1.forEach((level_1) => {
        while (level_1.Count >= 0) {
          this.reduceCount(1, level_1)
        }
      })
    },
    async handleCreate() {
      var text = ''
      // for error message (limited must not be empty)
      this.configurationModel.ProductsLevel_1.forEach((level_1) => {
        if (level_1.isDependency) {
          return
        }
        if (level_1.Limited === '') text = level_1.Name
        level_1.ProductsLevel_2.forEach((level_2) => {
          if (level_2.Limited === '') text = level_1.Name + ' -> ' + level_2.Name
          level_2.ProductsLevel_3.forEach((level_3) => {
            if (level_3.Limited === '') text = level_1.Name + ' - ' + level_2.Name + ' - ' + level_3.Name
          })
        })
      })
      var checkCount = this.checkExplorerCount()
      if (!checkCount.pass) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('error'),
          html: this.$t('explCountError').replace('%levelOneName%', checkCount.levelOneName),
        })
        return
      }

      this.setPlaceholderText()
      this.infoText = this.placeholderText
      if (text !== '') {
        await Swal.fire({
          title: this.$t('error'),
          text: text + this.$t('limitedError'),
          icon: 'error',
          confirmButtonText: this.$t('confirm'),
        })
        return
      } else {
        this.showModalCreate = true
      }
      return
    },
    async createConfiguration() {
      const token = this.userStore.token
      this.loader = true
      if (this.infoText === '') {
        await Swal.fire({
          title: this.$t('error'),
          text: this.$t('errorInfoText'),
          icon: 'error',
          confirmButtonText: this.$t('confirm'),
        }).then((result) => {
          // this.showModalCreate = false
        })
        return
      }
      if (this.infoName !== '') this.infoText = this.infoName + '/*\\' + this.infoText
      var success = await this.configurationProductLevelStore.createProductConfiguration(token, this.infoText)
      this.loader = false
      if (success) {
        var responseAllProducts = await this.companyStore.getProductsByCompanyID(
          this.companyStore.company.ID,
          this.userStore.token,
        )
        await Swal.fire({
          title: this.$t('success'),
          text: this.$t('successConfigurationText'),
          icon: 'success',
        }).then((result) => {
          this.showModalCreate = false
          this.$router.push({ name: 'Mdesign Overview' })
        })
      } else {
        await Swal.fire({
          title: this.$t('error'),
          text: this.$t('errorConfigurationText'),
          icon: 'error',
          confirmButtonText: this.$t('confirm'),
        }).then((result) => {
          this.showModalCreate = false
          return
        })
      }
    },
    back() {
      this.$router.back()
    },
    checkExplorerCount() {
      const explorer = this.configurationModel.ProductsLevel_1.find(
        (product) => product.LicName === 'E' && product.LicNameParent === '',
      )
      let pass = true
      let levelOne = ''

      for (let level_1 of this.configurationModel.ProductsLevel_1) {
        if (!explorer) {
          pass = false
          levelOne = level_1.Name
          break
        }
        if (level_1.Count > explorer.Count) {
          pass = false
          levelOne = level_1.Name
          break
        }
      }
      return { pass: pass, levelOneName: levelOne }
    },
    addLevel_1() {
      this.$router.push({ name: 'Mdesign Configuration Add Product', params: { navigateTo: 'Manager' } })
    },

    getNavName(ProductLevelOneID, ProductLevelTwoID) {
      this.navName = ''
      var prod_level_1 = this.configurationProductLevelStore.configurationModel.ProductsLevel_1.find(
        (el) => el.ProductLevelOneID === ProductLevelOneID,
      )
      var prod_level_2 = prod_level_1.ProductsLevel_2.find((el) => el.ProductLevelTwoID === ProductLevelTwoID)
      return prod_level_2.Name
    },
    async expand(item) {
      if (item.ProductsLevel_2.length < 1) {
        this.editable = 0
        this.productLvlOneID = ''
        this.editCardHeader = { Name: '', ID: '' }
        this.configurationModel.ProductsLevel_1.forEach((level_1) => (level_1.isSelected = false))
        item.isSelected = true
        this.edit_level_2 = [
          {
            ProductsLevel_3: [],
          },
        ]
        return
      }
      if (item.isSelected === true && this.editable < 3) {
        item.isSelected = false
        this.editable = 0
        this.productLvlOneID = ''
        this.edit_level_2 = [
          {
            ProductsLevel_3: [],
          },
        ]
        this.edit_level_3 = [{}]
        //this.renderKey++
      } else if (item.isSelected === true && this.editable === 3) {
        this.navName = ''
        this.editCardHeader = item
        this.editable = 2
        this.productLvlOneID = item.ProductLevelOneID
        this.edit_level_3 = [{}]
        this.transitionKey++
      } else {
        this.navName = ''
        this.configurationModel.ProductsLevel_1.forEach((level_1) => (level_1.isSelected = false))
        item.isSelected = true
        this.edit_level_2 = item.ProductsLevel_2
        if (this.edit_level_2.length > 0) {
          this.editable = 2
          this.productLvlOneID = item.ProductLevelOneID
          this.editCardHeader = item
          this.transitionKey++
        }
      }
    },
    async expand_2(item) {
      this.edit_level_3 = item.ProductsLevel_3
      if (this.edit_level_3.length > 0) {
        this.editable = 3
        this.edit_level_3.forEach((level_3) => {
          level_3.Count = level_3.Count === -1 ? 0 : level_3.Count
        })
        this.navName = '/ ' + this.getNavName(item.ProductLevelOneID, item.ProductLevelTwoID)
        this.transitionKey++
      }
    },
    getCount(i) {
      return i < 0 ? '0' : i
    },
    handleRowClick_level_1(event, item) {
      if (this.readonly) return
      if (!event.target.closest('span')) {
        this.expand(item)
      }
    },
    handleRowClick_level_2(event, item) {
      if (!event.target.closest('span')) {
        this.expand_2(item)
      }
    },
    setMany(level, item) {
      item.Count = parseInt(item.Count)
      switch (level) {
        case 1:
          if (item.Count === 0) this.configurationProductLevelStore.removeProdFromModel(item)
          if (item.ProductsLevel_2.length > 0)
            item.ProductsLevel_2.forEach((level_2) => {
              if (level_2.Count > 0) {
                level_2.Count = item.Count
              }
              if (level_2.ProductsLevel_3.length > 0)
                level_2.ProductsLevel_3.forEach((level_3) => {
                  if (level_3.Count > 0) level_3.Count = item.Count
                })
            })
          break
        case 2:
          this.configurationProductLevelStore.configurationModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.ProductLevelOneID === item.ProductLevelOneID) {
              if (level_1.Count < item.Count) {
                level_1.Count = item.Count
              }
            }
          })
          if (item.ProductsLevel_3.length > 0)
            item.ProductsLevel_3.forEach((level_3) => {
              if (level_3.Count > 0) level_3.Count = item.Count
            })
          break
        case 3:
          var config_2 = this.configurationProductLevelStore.masterVorlage.configurationLevel_2.find(
            (el) => el.ProductLevelTwoID === item.ProductLevelTwoID,
          )
          var config_1 = this.configurationProductLevelStore.masterVorlage.configurationLevel_1.find(
            (el) => el.ProductLevelOneID === config_2.ProductLevelOneID,
          )

          this.configurationProductLevelStore.configurationModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.ProductLevelOneID === config_1.ProductLevelOneID) {
              if (level_1.Count < item.Count) level_1.Count = item.Count
              level_1.ProductsLevel_2.forEach((level_2) => {
                if (level_2.ProductLevelTwoID === item.ProductLevelTwoID) {
                  if (level_2.Count < item.Count) level_2.Count = item.Count
                }
              })
            }
          })

          break
      }
    },
    setDate(item, level) {
      switch (level) {
        case 1:
          item.ProductsLevel_2.forEach((level_2) => {
            level_2.Limited = item.Limited
            level_2.ProductsLevel_3.forEach((level_3) => {
              level_3.Limited = level_2.Limited
            })
          })
          break
        case 2:
          item.ProductsLevel_3.forEach((level_3) => {
            level_3.Limited = item.Limited
          })
          break
        case 3:
          break
      }
    },
    setUnlimited(item, level) {
      if (this.configurationProductLevelStore.licenceCreatorModel.Enddate !== '') {
        item.Limited === '9999-12-31'
          ? (item.Limited = this.configurationProductLevelStore.licenceCreatorModel.Enddate)
          : (item.Limited = '9999-12-31')
      } else {
        item.Limited = item.Limited === '9999-12-31' ? this.companyStore.getInOneYear() : '9999-12-31'
      }

      switch (level) {
        case 1:
          item.ProductsLevel_2.forEach((level_2) => {
            level_2.Limited = item.Limited
            level_2.ProductsLevel_3.forEach((level_3) => {
              level_3.Limited = level_2.Limited
            })
          })
          break
        case 2:
          item.ProductsLevel_3.forEach((prod) => {
            prod.Limited = item.Limited
          })
          break
      }
      this.renderKey++
    },
    setUncounted(item) {
      item.Uncounted = item.Uncounted === 0 ? 1 : 0
      item.Count = item.Uncounted === 1 ? 9999 : 1
      if (item.LicName === 'E') {
        this.explorerCountDependency(item)
      } else this.setMany(1, item)
      this.renderKey++
    },
    handleSetMany(level, item) {
      if (item.LicName === 'E') {
        this.explorerCountDependency(item)
      } else this.setMany(level, item)
    },
    explorerCountDependency(item) {
      this.configurationModel.ProductsLevel_1.forEach((level_1) => {
        if (level_1.LicNameParent === 'E' || level_1.LicName === 'E') {
          level_1.Count = item.Count
          this.setMany(1, level_1)
        }
      })
    },
    setStudent(item) {
      item.Student = item.Student === 0 ? 1 : 0
      this.renderKey++
    },
    setTest50(item) {
      if (!item.Test) {
        this.showModalTestCount = true
        this.testCountItem = item
      } else {
        item.Test = 0
        item.TestCount = 0
      }
    },
    confirmTestCount() {
      this.testCount = parseInt(this.testCount)
      this.testCountItem.TestCount = this.testCount
      this.testCountItem.Test = 1
      this.testCountItem = {}
      this.testCount = 0
      this.renderKey++

      this.closeModal()
    },
    setConnect(item) {
      item.Connect = item.Connect === 0 ? 1 : 0
      this.renderKey++
    },
    setEditModules(item) {
      item.EditModules = item.EditModules === 0 ? 1 : 0
      this.renderKey++
    },
    generatePlaceholderText() {
      var text = ''
      this.configurationModel.ProductsLevel_1.forEach((level_1) => {
        if (level_1.isDependency) return
        var level_1_name = level_1.Name.includes('MDESIGN ') ? level_1.Name.split('MDESIGN ')[1] : level_1.Name

        text = text + level_1.Count + ' x ' + level_1_name

        var level_2_names_with = []
        var level_2_names_without = []

        if (level_1.ProductsLevel_2.length > 0) {
          level_1.ProductsLevel_2.forEach((level_2) => {
            if (level_2.Count > 0) level_2_names_with.push(level_2.Name)
            else level_2_names_without.push(level_2.Name)
          })

          if (level_2_names_with.length === 0) {
            text = text + ' ohne alles, '
          } else if (level_2_names_without.length < level_2_names_with.length && level_2_names_without.length > 0) {
            text = text + ' ohne: '
            level_2_names_without.forEach((without) => {
              text = text + ' ' + without + ', '
            })
          } else if (level_2_names_with.length < level_2_names_without.length) {
            text = text + ' mit: '
            level_2_names_with.forEach((with_) => {
              text = text + ' ' + with_ + ', '
            })
          } else {
            text = text + ', '
          }
        } else {
          text = text + ', '
        }
      })
      text = text.slice(0, -2)
      return text
    },
    setPlaceholderText() {
      this.placeholderText = this.generatePlaceholderText()
    },
    getNames(item) {
      var array = []
      var level_2_names_with = []
      var level_2_names_without = []

      if (item.ProductsLevel_2.length > 1) {
        item.ProductsLevel_2.forEach((l2) => {
          if (l2.Count > 0) level_2_names_with.push(`${l2.Sorting}. ${l2.Name}`)
          else level_2_names_without.push(`${l2.Sorting}. ${l2.Name}`)
        })

        if (
          level_2_names_with.length < level_2_names_without.length ||
          level_2_names_with.length === level_2_names_without.length
        ) {
          level_2_names_with.forEach((name) => {
            array.push(name)
            item.lessSelected = false
          })
        } else if (level_2_names_without.length < level_2_names_with.length) {
          level_2_names_without.forEach((name) => {
            array.push(name)
            item.lessSelected = true
          })
        }
      } else {
        item.ProductsLevel_2.forEach((l2) => {
          l2.ProductsLevel_3.forEach((l3) => {
            if (l3.Count > 0) level_2_names_with.push(`${l3.Sorting}. ${l3.Name}`)
            else level_2_names_without.push(`${l3.Sorting}. ${l3.Name}`)
          })
        })

        if (
          level_2_names_with.length < level_2_names_without.length ||
          level_2_names_with.length === level_2_names_without.length
        ) {
          level_2_names_with.forEach((name) => {
            array.push(name)
            item.lessSelected = false
          })
        } else if (level_2_names_without.length < level_2_names_with.length) {
          level_2_names_without.forEach((name) => {
            array.push(name)
            item.lessSelected = true
          })
        }
      }

      return array
    },
    addInvisibleTechnicalDependencies() {
      const copyMasterModel = JSON.parse(JSON.stringify(this.configurationProductLevelStore.masterModel))
      const copyDependencies = JSON.parse(JSON.stringify(this.configurationProductLevelStore.productHasProductLevelOne))
      if (copyDependencies.length < 1) {
        this.noDependenciesFound = true
        return
      }
      try {
        copyDependencies.forEach((dependency) => {
          this.noDependenciesFound = false
          if (!this.tableData.ProductsLevel_1.some((l1) => l1.ProductLevelOneID === dependency.ProductLevelOneID)) {
            var productLevelOne = copyMasterModel.ProductsLevel_1.find(
              (l1) => l1.ProductLevelOneID === dependency.ProductLevelOneID,
            )
            if (productLevelOne !== undefined) {
              productLevelOne.Count = 1
              this.setMany(1, productLevelOne)
              productLevelOne.isDependency = true
              this.tableData.ProductsLevel_1.push(productLevelOne)
            }
          }
        })
      } catch (e) {}
    },
  },
  computed: {
    getTooLong() {
      return this.infoText.length > 222 ? true : false
    },
    editTableName() {
      return this.editable > 1 ? this.navName.split('/')[1] : ''
    },
  },
  setup() {
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    return { configurationProductLevelStore, userStore, companyStore }
  },
}
</script>

<style scoped lang="scss">
@media (max-width: 900px) {
  .mdesign-config-content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
  .mdesign-config-content-view {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
  .md-card-nav-mdesign {
    margin: auto;
    width: 95% !important;
  }

  .md-card-nav-mdesign-view {
    margin: auto;
    width: 100%;
  }

  .md-card-edit-mdesign {
    margin: 2rem auto;
    width: 95% !important;
  }
}
@media (min-width: 901px) {
  .mdesign-config-content {
    z-index: 1;
    display: flex !important;
    // padding-right: 1rem;
  }
  .mdesign-config-content-view {
    display: flex !important;
    padding-right: 0;
  }
}
.btn-custom {
  width: 150px;
}

.md-icon {
  margin: 0 0.5rem 0 0.5rem;
}

.md-table-row:hover {
  cursor: pointer;
}

.md-select-menu {
  max-width: none;
}

.spacer {
  min-height: 3rem !important;
}

.md-table-cell {
  .md-table-cell-container {
    span {
      display: flex !important;
      align-items: center !important;
    }
  }
}

@keyframes changeGradient {
  /* Start from the left */
  0% {
    background-position: -100% 0;
  }

  /* Move to the right */
  100% {
    background-position: 100% 0;
  }
}

.md-input {
  padding: 0;
}

.just-cont-sb {
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.modal-content {
  z-index: 99 !important;
}

.space-evenly {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.last-icon-container {
  margin-left: auto; /* Push the last icon container to the end of the table cell */
}
.module-info {
  display: flex;
  flex-wrap: wrap;
  width: 580px;
}
.module-info-view {
  display: flex;
  flex-wrap: wrap;
}
.info-name {
  flex: 0 0 33%;
  padding: 5px 5px 0 0;
  box-sizing: border-box;
  p {
    margin: 0;
  }
}
.less-selected {
  text-decoration: line-through;
}
.md-field:before {
  background-color: transparent !important;
}
.image-cell {
  display: flex;
  padding-right: 12px !important;
  justify-content: center;
  padding: 0 !important;
}
.display-contents {
  display: contents !important;
}
.view-input {
  opacity: 1 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  cursor: default !important;
}
.md-pl-30 {
  padding-left: 30px;
}
.has-items {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.image-cell-container {
  justify-content: center;
  max-width: fit-content;
  width: 42px !important;
  // height: 42px;
}
textarea {
  padding-left: 0 !important;
}
.sticky-top {
  position: sticky !important;
  top: 50px !important;
}
/* Slide in from right to left */
.slide-right-to-left-enter-active {
  transition: transform 0.5s;
}
.slide-right-to-left-enter,
.slide-right-to-left-leave-to {
  transform: translateX(100%);
}
.slide-right-to-left-enter-to,
.slide-right-to-left-leave {
  transform: translateX(0);
}

.slide-right-to-left-leave-active {
  display: none;
}
.hidden-dep {
  display: none !important;
}
.header-div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.cursor-auto {
  cursor: auto !important;
}
</style>

<template>
  <div class="">
    <loader :loader="loader" />
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form
        @submit.prevent="handleSubmit(submit)"
        class="md-layout-item md-medium-size-66 md-size-66 md-small-size-100"
      >
        <md-card class="card-content">
          <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
            <div class="card-icon">
              <md-icon>perm_identity</md-icon>
            </div>
            <h4 class="title">
              {{ $t('contactData') }}
            </h4>
            <h3>{{ $t('createContactText') }}</h3>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label for="salutation">{{ $t('salutation') }}</label>
                  <md-select v-model="contact.Salutation" name="salutation" id="salutation">
                    <md-option value="Herr">{{ $t('mr') }}</md-option>
                    <md-option value="Frau">{{ $t('ms') }}</md-option>
                    <md-option value="NoSalutation">{{ $t('noSalutaition') }}</md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('title') }}</label>
                  <md-input v-model="contact.Title" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="firstname" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('firstName') }}</label>
                      <md-input v-model="contact.FirstName" type="text" required></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="lastname" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('lastName') }}</label>
                      <md-input v-model="contact.LastName" type="text" required></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('phoneNumber') }}</label>
                  <md-input v-model="contact.Phone" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('email') }}</label>
                    <md-input v-model="contact.Email" type="email" required> </md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('position') }}</label>
                  <md-input v-model="contact.Position" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <md-checkbox v-model="contact.MainContact" class="imo-checkbox">{{ $t('mainContact') }} </md-checkbox>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <select-country :value="contact.Language.toUpperCase()" @changeCountry="handleCountry"></select-country>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50"></div>

              <div class="md-layout-item md-size-100 text-right">
                <md-button
                  id="submit"
                  class="md-raised md-primary mt-4 md-round"
                  @click="askCreate()"
                  :disabled="invalid"
                  >{{ $t('save') }}
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import SelectCountry from '../../components/SelectCountry/SelectCountry.vue'
import { useCompanyStore } from '../../stores/company'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'
import { sendEmailConfirmationCompanyContact } from '@/libs/emailSender.js'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

export default {
  components: { SelectCountry, ValidationObserver, ValidationProvider },
  name: 'create-company-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()

    return { companyStore, userStore }
  },
  data() {
    return {
      contact: {
        ID: '',
        CompanyID: '',
        Salutation: '',
        Title: '',
        FirstName: '',
        LastName: '',
        Gender: '',
        Phone: '',
        Email: '',
        Position: '',
        MainContact: false,
        Language: '',
        Image: 0,
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      loader: false,
      fromAWS: false,
      configurationAws: {},
    }
  },
  beforeMount() {
    this.$changePageTitle(this.$t('pageTitleCreateNewComanyContact'))
    this.contact.Language === '' ? (this.contact.Language = 'DE') : undefined
    // company id über route
    if (this.$route.params.companyID && this.$route.params.companyTypes) {
      this.contact.CompanyID = this.$route.params.companyID
      this.companyTypes = this.$route.params.companyTypes
    } else {
      this.$router.push({ name: 'Companies' })
    }
    if (this.$route.params.configurationAws) {
      this.configurationAws = this.$route.params.configurationAws
      this.fromAWS = true
    }
  },
  methods: {
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    handleCountry(val) {
      this.contact.Language = val.value.toLowerCase()
    },
    askCreate() {
      Swal.fire({
        title: this.$t('askCreateNewContact'),
        // html: `${this.contact.FirstName} ${this.contact.LastName}`,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.dismiss) {
          return
        } else {
          this.createNewCompanyContact()
        }
      })
    },
    async createNewCompanyContact() {
      this.contact.CreatedBy = this.userStore.userData.LastName
      this.contact.Created = this.companyStore.formatDate(new Date())
      this.contact.ModifiedBy = this.userStore.userData.LastName
      this.contact.Modified = this.companyStore.formatDate(new Date())

      this.loader = true
      var res = await this.companyStore.createCompanyContact(this.contact, this.userStore.token)
      // var res = {status: 200}

      if (res.status === 200) {
        this.contact.ID = res.data
        await this.sendConfirmEmail(this.contact)
        this.loader = false
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('contactWasCreated'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        }).then(() => {
          if (this.fromAWS === true) {
            var params = {
              configAws: this.configurationAws,
            }
            this.$router.push({ name: 'Aws Account', params })
          } else {
            this.$router.push({ name: 'Company' })
          }
        })
      } else if (res.status === 400 && res.data.Message === 'Limit of main contacts reached') {
        this.loader = false
        Swal.fire({
          title: this.$t('mainContactLimit'),
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      } else {
        this.loader = false
        Swal.fire({
          title: this.$t('error'),
          html: this.$t('emailAlreadyExists'),
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      }
    },
    async sendConfirmEmail(contact) {
      var res = await sendEmailConfirmationCompanyContact(this.userStore.token, contact)
      return res
    },
  },
}
</script>
<style scoped>
form {
  margin: 0 auto !important;
}

.md-checkbox {
  margin-right: 0 !important;
  label {
    padding-left: 0 !important;
  }
}
</style>

import axios from 'axios'

export const ApiNames = {
  contacts: '/contacts',
  contact: '/contact',
  authorization: '/authorization',
  forgotpassword: 'authorization/forgotpassword',
  address: '/address',
  register: '/authorization/createuser',
  resetpassword: '/authorization/resetpassword',
  confirmemail: '/authorization/confirmemail',
  sendemail: '/sendemail',
  createtoken: '/authorization/createtoken',
  generatelecense: '/generate',
  updateuserdata: '/update',
  createuser: '/create',
  contacthasaddress: '/contacthasaddress',
  updateuseraddress: '/updateaddress',
  createaddress: '/createaddress',
  createcontacthasaddress: '/createcontacthasaddress',
  createcontacthasproduct: '/createcontacthasproduct',
  products: '/products',
  createfilereference: '/createfileReference',
  updatecontacthasproduct: '/updatecontacthasproduct',
  filereference: '/filereference',
  download: '/download',
  companies: '/companies',
  companytypes: '/companytypes',
  updatecompany: '/updatecompany',
  createcompany: '/createcompany',
  contactbycompanyid: '/contactbycompanyid',
  uploadavatar: '/uploadavatar',
  filereferencebytable: '/filereferencebytable',
  datafile: '/datafile',
  downloadfile: '/downloadfile',
  uploadima: '/uploadima',
  verification: '/verification',
  createverificationbystudentemail: '/createverificationbystudentemail',
  createcompanycontact: '/create',
  updatecompanycontact: '/update',
  companyhasproducts: '/companyhasproducts',
  createcompanyhasproduct: '/createcompanyhasproduct',
  deletecompanyhasproduct: '/deletecompanyhasproduct',
  contacthasproducts: '/contacthasproducts',
  createcontacthasproduct: '/createcontacthasproduct',
  deletecontacthasproduct: '/deletecontacthasproduct',
  awsuser: '/awsuser',
  awsalluser: '/awsusers',
  deletedatafile: '/deletedatafile',
  deletefilereferencebydatafileid: '/deletefilereferencebydatafileid',
  updateverification: '/updateverification',
  contactbyemail: '/contactbyemail',
  updateuser: '/updateuser',
  userbyusername: '/userbyusername',
  updateimacount: '/updateverification-imacount',
  confirmedmail: '/confirmed/mail',
  createconfiguration: '/createconfiguration',
  createconfigurationaws: '/createconfiguration-aws',
  deleteconfigurationaws: '/deleteconfiguration-aws',
  deleteconfiguration: '/deleteconfiguration',
  configuration_awsconfigurationid: '/configuration-awsconfigurationid',
  configuration: '/configuration',
  configurationawshascontact_configuration: '/configurationawshascontact-configuration',
  configurationawshascontact_primary: '/configurationawshascontact-primary',
  deleteconfigurationawshascontact_configuration: '/deleteconfigurationawshascontact-configuration',
  updateconfigurationawshascontact: '/updateconfigurationawshascontact',
  createconfigurationawshascontact: '/createconfigurationawshascontact',
  createconfigurationawshascontact_manycontacts: '/createconfigurationawshascontact-manycontacts',
  configurationaccounttypes: '/configurationaccounttypes',
  updateconfiguration_aws: '/updateconfiguration-aws',
  productlevels_1: '/productlevels-1',
  productlevels_2: '/productlevels-2',
  productlevels_3: '/productlevels-3',
  configurationhasproductlevel_1_configurationid: '/configurationhasproductlevel-1-configurationid',
  configurationhasproductlevel_2_configurationid: '/configurationhasproductlevel-2-configurationid',
  configurationhasproductlevel_3_configurationid: '/configurationhasproductlevel-3-configurationid',
  updateverification: '/updateverification',
  studentdomains: '/studentdomains',
  updatedownload: '/updatedownload',
  createdownload: '/createdownload',
  allverifications: '/verifications',
  studentdomainbydomain: '/studentdomainbydomain',
  createverification: '/createverification',
  producthasconfiguration: '/producthasconfiguration',
  createconfigurationhasproductlevel_1: '/createconfigurationhasproductlevel-1',
  createconfigurationhasproductlevel_2: '/createconfigurationhasproductlevel-2',
  createconfigurationhasproductlevel_3: '/createconfigurationhasproductlevel-3',
  createconfigurationhasproductlevel_1_many: '/createconfigurationhasproductlevel-1-many',
  createconfigurationhasproductlevel_2_many: '/createconfigurationhasproductlevel-2-many',
  createconfigurationhasproductlevel_3_many: '/createconfigurationhasproductlevel-3-many',
  createconfiguration_licence: '/createconfiguration-licence',
  company: '/company',
  createproducthasconfiguration: '/createproducthasconfiguration',
  producthasconfiguration_primary: '/producthasconfiguration-primary',
  deleteproducthasconfiguration: '/deleteproducthasconfiguration',
  deletecompleteconfiguration: '/deletecompleteconfiguration',
  orderingbycontact: '/orderingbycontact',
  createordering: '/createordering',
  configuration_licenceconfigurationid: '/configuration-licenceconfigurationid',
  updatecompanyhasproduct: '/updatecompanyhasproduct',
  configurationbycompanyid: '/configurationbycompanyid',
  configuration_licencecompanyhasproductid: '/configuration-licencecompanyhasproductid',
  updateconfiguration_licence: '/updateconfiguration-licence',
  deletedownload: '/deletedownload',
  product: '/product',
  uploadproductimage: '/uploadproductimage',
  createproduct: '/createproduct',
  updateproduct: '/updateproduct',
  producthasproductlevelone: '/producthasproductlevelone',
  productlevel_1: '/productlevel-1',
  productlevel_2: '/productlevel-2',
  productlevel_3: '/productlevel-3',
  createproductlevel_1: '/createproductlevel-1',
  createproductlevel_2: '/createproductlevel-2',
  createproductlevel_3: '/createproductlevel-3',
  updateproductlevel_1: '/updateproductlevel-1',
  updateproductlevel_2: '/updateproductlevel-2',
  updateproductlevel_3: '/updateproductlevel-3',
  deleteproductlevel_1: '/deleteproductlevel-1',
  deleteproductlevel_2: '/deleteproductlevel-2',
  deleteproductlevel_3: '/deleteproductlevel-3',
  configurationhasproductlevel_1_combi: '/configurationhasproductlevel-1-combi',
  configurationhasproductlevel_2_combi: '/configurationhasproductlevel-2-combi',
  configurationhasproductlevel_3_combi: '/configurationhasproductlevel-3-combi',
  updateconfigurationhasproductlevel_1: '/updateconfigurationhasproductlevel-1',
  updateconfigurationhasproductlevel_2: '/updateconfigurationhasproductlevel-2',
  updateconfigurationhasproductlevel_3: '/updateconfigurationhasproductlevel-3',
  deleteconfigurationhasproductlevel_1_combi: '/deleteconfigurationhasproductlevel-1-combi',
  deleteconfigurationhasproductlevel_2_combi: '/deleteconfigurationhasproductlevel-2-combi',
  deleteconfigurationhasproductlevel_3_combi: '/deleteconfigurationhasproductlevel-3-combi',
  producthasproductlevelone_primary: '/producthasproductlevelone-primary',
  createproducthasproductlevelone: '/createproducthasproductlevelone',
  updateproducthasproductlevelone: '/updateproducthasproductlevelone',
  uploadproductleveloneimage: '/uploadproductleveloneimage',
  userroles: '/userroles',
  licence: '/licence',
  createuserrole: '/createuserrole',
  updateuserrole: '/updateuserrole',
  deleteuserrole: '/deleteuserrole',
  updateconfiguration_licence_foreignkey: '/updateconfiguration-licence-foreignkey',
  enabletfa: '/authorization/enabletfa',
  disabletfa: '/authorization/disabletfa',
  getcode: '/authorization/getcode',
  verifycode: '/authorization/verifycode',
  resetlockout: '/authorization/resetlockout',
  createcontacthasconsenttype: '/createcontacthasconsenttype',
  contacthasconsenttype_contactid: '/contacthasconsenttype-contactid',
  permissions: '/permissions',
  userrolehaspermission_userroleid: '/userrolehaspermission-userroleid',
  createuserrolehaspermission: '/createuserrolehaspermission',
  deleteuserrolehaspermission_primary: '/deleteuserrolehaspermission-primary',
  deleteuserrolehaspermission_all_userroleid: '/deleteuserrolehaspermission-all-userroleid',
  createpermission: '/createpermission',
  createstudentdomain: '/createstudentdomain',
  orderings: '/orderings',
  copycompleteconfiguration: '/copycompleteconfiguration',
  configurations: '/configurations',
  configuration_licence: '/configuration-licence',
  delete: '/delete',
  updateordering: '/updateordering',
  licence_with_configurationlicenceid: '/licence-with-configurationlicenceid',
  companyhasproducts_id: '/companyhasproducts-id',
  deletecompanyhasproduct_id: '/deletecompanyhasproduct-id',
  deletecompany: '/deletecompany',
  awsstacks: '/awsstacks',
  createawsuser: '/createawsuser',
  createawsuserold: '/createawsuserold',
  createcontacthasawsuserold: '/createcontacthasawsuserold',
  createconfigurationhistory: '/createconfigurationhistory',
  configurationhistory_companyhasproduct: '/configurationhistory-companyhasproduct',
  configurationhistory_configuration: '/configurationhistory-configuration',
  deleteconfigurationhistory_configuration: '/deleteconfigurationhistory-configuration',
  updateconfiguration: '/updateconfiguration',
  protocolcomplete: '/protocolcomplete',
  protocolbyconfiguration: '/protocolbyconfiguration',
  protocoltechdependencys: '/protocoltechdependencys',
  protocolproductleveloneforproduct: '/protocolproductleveloneforproduct',
  uploadlicencefilesforconfiguration: '/uploadlicencefilesforconfiguration',
  upgradeconfigurationbyproductandconfigurationid: '/upgradeconfigurationbyproductandconfigurationid',
  blacklistdomains: '/blacklistdomains',
  basicpropertyvaluebykey: '/basicpropertyvaluebykey',
  companyhasproducthasconfigurationaws_configurationaws: '/companyhasproducthasconfigurationaws-configurationaws',
  createcompanyhasproducthasconfigurationaws: '/createcompanyhasproducthasconfigurationaws',
  updatecompanyhasproducthasconfigurationaws: '/updatecompanyhasproducthasconfigurationaws',
  licenseprofiles_old: '/licenseprofiles-old',
}

let origin = process.env.VUE_APP_API_URL
//let origin='https://my.mdesign.de' //windows.origin
//let origin = 'https://test-my.mdesign.de' //windows.origin
// let origin = 'http://localhost:44315' //windows.origin
//let originAws = "https://cloudapi.mdesign.online" // API for Aws-Login
let originAws = 'https://localhost:44340' // API for Aws-Login

/// server url
function getApiServerUrl() {
  return origin + '/data/api'
  // return origin + '/api'
}

/// local host url
// function getApiServerUrl() {
//   return origin + '/api'
// }
function getApiAuth() {
  return origin + '/auth/api'
}
function getApiMail() {
  return 'https://mailservice.mdesign.online/api'
}
function getApiLicense() {
  return origin + '/lic/api'
}
function getApiAwsToken() {
  return originAws + '/api/token'
}
function getApiAwsLogin() {
  return originAws + '/api/login'
}
function getApLicenceFile() {
  return origin + '/licence/api'
}
function getApiAwsTemp(apiName) {
  let url = `${process.env.VUE_APP_API_URL}/data/api`
  //var url = 'http://my.mdesign.de/data/api'
  // var url = "http://localhost:49898/api";
  // var url = "https://localhost:44358/api";
  return url + apiName
}
function getApiUrl(apiName) {
  switch (apiName) {
    case ApiNames.authorization:
    case ApiNames.register:
    case ApiNames.resetpassword:
    case ApiNames.confirmemail:
    case ApiNames.createtoken:
    case ApiNames.enabletfa:
    case ApiNames.disabletfa:
    case ApiNames.getcode:
    case ApiNames.verifycode:
    case ApiNames.resetlockout:
      return getApiAuth() + apiName
    case ApiNames.sendemail:
      return getApiMail() + apiName
    case ApiNames.generatelecense:
      return getApiLicense() + apiName
    case ApiNames.licence:
    case ApiNames.licence_with_configurationlicenceid:
      return getApLicenceFile() + apiName
    default:
      return getApiServerUrl() + apiName
  }
}

export async function httpGetByUrl(apiUrl, params, headers = {}) {
  try {
    let config = {
      headers: headers,
      params: params,
    }

    return await axios
      .get(apiUrl, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}
export async function httpGetFile(apiName, params, headers = {}) {
  try {
    const apiUrl = getApiUrl(apiName)
    let config = {
      headers: headers,
      params: params,
      responseType: 'blob',
    }

    return await axios
      .get(apiUrl, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}

export async function httpDelete(apiName, params, headers) {
  try {
    let apiUrl = getApiUrl(apiName)

    if (apiName === '/deletedatafile' || apiName === '/deletefilereferencebydatafileid') {
      apiUrl += '?id=' + encodeURIComponent(params)
      return await axios.delete(apiUrl, headers).then((response) => {
        return response
      })
    }
    let config = {
      headers: headers,
      params: params,
    }
    return await axios.delete(apiUrl, config).then((response) => {
      return response
    })
  } catch (error) {
    // console.log("error delete ", error)
    return error.response
  }
}

export async function httpGet(apiName, params, headers = {}) {
  try {
    let apiUrl
    if (apiName === ApiNames.getcode) apiUrl = getApiAuth() + apiName
    else if (apiName === ApiNames.verifycode) apiUrl = getApiAuth() + apiName
    else apiUrl = getApiUrl(apiName)
    // test long loading times
    // await new Promise((resolve) => setTimeout(resolve, 5000))

    let config = {
      headers: headers,
      params: params,
    }
    return await axios
      .get(apiUrl, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}

export async function httpPostAuth(apiName, data, config, token) {
  const headerConfig = { headers: { Authorization: `Bearer ${token}` } }
  return await httpPost(apiName, data, { ...config, ...headerConfig })
}
export async function httpGetAuthFileDownload(apiName, data, config, token) {
  const headerConfig = { Authorization: `Bearer ${token}` }
  return await httpGetFile(apiName, data, { ...config, ...headerConfig })
}
export async function httpGetAuth(apiName, data, config, token) {
  const headerConfig = { Authorization: `Bearer ${token}` }
  return await httpGet(apiName, data, { ...config, ...headerConfig })
}
export async function httpGetLicenceFile(apiName, data, config, token) {
  const headerConfig = { Authorization: `Bearer ${token}` }
  // apiName = getApLicenceFile() + apiName
  return await httpGet(apiName, data, { ...config, ...headerConfig })
}

export async function httpPost(apiName, data, config) {
  try {
    var apiUrl = getApiUrl(apiName)

    if (apiName === ApiNames.updatedownload) apiUrl = getApiUrl(apiName) + '?id=' + data.id
    if (apiName === ApiNames.copycompleteconfiguration)
      apiUrl =
        getApiUrl(apiName) +
        '?configurationID=' +
        encodeURIComponent(data.configurationID) +
        '&configurationInfo=' +
        encodeURIComponent(data.configurationInfo) +
        '&creator=' +
        encodeURIComponent(data.creator)
    return await axios
      .post(apiUrl, data, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}
export async function httpPostAvatar(apiName, contactID, data, config) {
  try {
    var apiUrl = getApiUrl(apiName) + '?contactId=' + contactID
    return await axios
      .post(apiUrl, data, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}
export async function httpPostEducationProof(apiName, contactID, productID, data, config) {
  try {
    var apiUrl = getApiUrl(apiName) + '?contactId=' + contactID + '&productId=' + productID
    return await axios
      .post(apiUrl, data, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}
export async function httpPostStudentEmail(apiName, contactID, productID, studentEmail, config) {
  try {
    const params = {
      contactID: contactID,
      productID: productID,
      studentEmail: studentEmail,
    }
    studentEmail = encodeURIComponent(studentEmail)
    var apiUrl =
      getApiUrl(apiName) + '?contactID=' + contactID + '&productId=' + productID + '&studentEmail=' + studentEmail

    return await axios
      .post(apiUrl, params, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}

export async function httpGetAws(apiName, params, headers) {
  try {
    const apiUrl = getApiAwsTemp(apiName)
    // test long loading times
    // await new Promise((resolve) => setTimeout(resolve, 5000))

    let config = {
      headers,
      params: params,
    }

    return await axios
      .get(apiUrl, config) //, headers
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}

// old aws-api
export async function httpGetTokenAws() {
  const apiUrl = getApiAwsToken()
  try {
    return await axios
      .get(apiUrl)
      .then((response) => {
        return response
      })
      .catch((error) => {})
  } catch (error) {}
}

// old aws-api
export async function httpPostAwsLogin(params, token) {
  const apiUrl = getApiAwsLogin()
  const data = JSON.stringify(params)
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  }
  try {
    return await axios
      .post(apiUrl, data, { headers: headers })
      .then((response) => {
        return response
      })
      .catch((error) => {})
  } catch (error) {}
}

// old aws-api
export async function httpPostAwsResetPw(params, token) {
  const apiUrl = originAws + '/api/resetPw'
  const data = JSON.stringify(params)
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  }
  try {
    return await axios
      .post(apiUrl, data, { headers: headers })
      .then((response) => {
        return response
      })
      .catch((error) => {})
  } catch (error) {}
}

export async function httpPostProductImage(apiName, productID, isGermanImage, data, config) {
  try {
    var apiUrl = getApiUrl(apiName) + '?productID=' + productID + '&isGermanImage=' + isGermanImage
    return await axios
      .post(apiUrl, data, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}

export async function httpPostProductLevelOneImage(apiName, productLevelOneID, data, config) {
  try {
    var apiUrl = getApiUrl(apiName) + '?productLevelOneID=' + productLevelOneID
    return await axios
      .post(apiUrl, data, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}

export async function httpPostUploadLicense(apiName, contactID, companyID, companyHasProductID, data, config) {
  try {
    var apiUrl =
      getApiUrl(apiName) +
      '?contactId=' +
      contactID +
      '&companyId=' +
      companyID +
      '&companyHasProductId=' +
      companyHasProductID
    return await axios
      .post(apiUrl, data, config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
  } catch (error) {
    return undefined
  }
}

<template>
  <div class="md-layout-item md-size-66 mx-auto md-medium-size-100" :key="langChange">
    <!--disabled tab panes: , 'policy'-->
    <tabs
      :tab-name="tabName[langChange]"
      :tab-icon="['key', 'file_download']"
      class="page-subcategories"
      nav-pills-icons
      plain
      color-button="primary"
    >
      <h3 class="title text-center" slot="header-title">{{ $t('studyVersion2022') }}</h3>
      <template slot="tab-pane-1">
        <loader :loader="loader" />
        <div class="tiles-sturcture">
          <div
            class="alert alert-info alert-with-icon warning-message"
            data-notify="container"
            v-if="this.verificationStatus === 1 || !this.verificationStatus"
          >
            <i data-notify="icon" class="material-icons">add_alert</i>
            <span data-notify="message">{{ $t('warningMassageOnStudentProduct') }}</span>
          </div>
          <div class="timeline-ima">
            <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
              <time-line plain type="simple">
                <time-line-item inverted badge-type="primary" badge-icon="contact_page">
                  <span slot="header" class="badge badge-primary">{{ $t('documents') }}</span>
                  <div slot="content">
                    <p :key="langChange">
                      {{ eductionProofText }}
                    </p>
                    <div v-if="[3, 4, 6].includes(this.verificationStatus)">
                      <div class="uploaded-ima-line"></div>
                      <p class="uploaded-ima" v-for="(item, index) in imaFilesData" :key="index">{{ item.Name }}</p>
                      <p v-if="studentEmailTimeline">{{ studentEmailTimeline }}</p>
                    </div>
                  </div>
                </time-line-item>
                <time-line-item inverted :badge-type="badgeVerificationInProcess" badge-icon="sync">
                  <span
                    slot="header"
                    :class="{
                      ['badge badge-primary']: verificationInProcess,
                      ['badge badge-default']: !verificationInProcess,
                    }"
                    >{{ $t('verification') }}</span
                  >
                  <div slot="content">
                    <p>
                      {{ reviewImaText }}
                    </p>
                    <div v-if="[3].includes(this.verificationStatus)">
                      <div class="uploaded-ima-line"></div>
                      <p>{{ $t('trainingEntity') }}: {{ uniName }}</p>
                    </div>
                  </div>
                </time-line-item>
                <time-line-item inverted :badge-type="badgeVerificationApproved" badge-icon="done">
                  <span
                    slot="header"
                    :class="{
                      ['badge badge-primary']: verificationApproved,
                      ['badge badge-default']: !verificationApproved,
                    }"
                    >{{ $t('licenceDownloadTitle') }}</span
                  >
                  <div slot="content">
                    <p>
                      {{ $t('downloadLicense') }}
                    </p>
                  </div>
                </time-line-item>
              </time-line>
            </div>
            <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
              <md-card class="ima-tile" v-if="!verificationApproved">
                <md-card-header>
                  <h4 class="title">{{ $t('proveEducationTitle') }}</h4>
                  <p class="category">{{ $t('for') }}</p>
                </md-card-header>
                <md-card-content class="content-style">
                  <div class="eduction-proof" v-if="!confirmStudentEmail && !studentEmailIsInReview">
                    {{ $t('provideEducationProof') }}
                  </div>
                  <div class="eduction-proof" v-if="confirmStudentEmail">
                    {{ $t('confirmStudentEmail') }}
                  </div>
                  <div v-if="studentEmailIsInReview">{{ $t('studentEmailIsInReview') }}</div>
                  <div class="student-email">
                    <ValidationObserver v-slot="{ invalid }">
                      <div v-if="!imaNotReadable">
                        <ValidationProvider
                          name="email"
                          :rules="{ required: true, email: true, customEmail: true }"
                          v-slot="{ passed, failed }"
                        >
                          <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]" required>
                            <label class="uni-email-label">{{ $t('universityEmail') }}</label>
                            <md-input v-model="studentEmail" type="email" class="uni-email-input"> </md-input>
                            <slide-y-down-transition>
                              <md-icon class="error" v-show="failed">close</md-icon>
                            </slide-y-down-transition>
                            <slide-y-down-transition>
                              <md-icon class="success" v-show="passed">done</md-icon>
                            </slide-y-down-transition>
                          </md-field>
                        </ValidationProvider>

                        <md-button
                          slot="footer"
                          class="md-primary md-round uni-email-button button-pointer"
                          :disabled="invalid"
                          id="uniEmail"
                          @click="verifyStudentEmail"
                        >
                          {{ $t('uniEmailButton') }}
                        </md-button>
                      </div>
                    </ValidationObserver>
                    <div v-if="imaNotReadable">{{ $t('imaNotReadable') }}</div>
                    <div>
                      <md-checkbox v-model="noUniEmail" class="ima-checkbox" v-if="!imaNotReadable">{{
                        $t('noUniEmail')
                      }}</md-checkbox>
                      <div v-if="noUniEmail">
                        <h4 class="upload-document-title">
                          {{ $t('uploadProofEduction') }}
                        </h4>
                        <form enctype="multipart/form-data">
                          <div class="md-layout-item upload-document">
                            <div class="file-input">
                              <div v-if="!imageRegular">
                                <div class="image-container">
                                  <md-button class="button-pointer md-fileinput ima-submit">
                                    <img :src="regularImg" title="" />
                                    <input type="file" @change="onFileChange" @click="onInputClick" />
                                  </md-button>
                                </div>
                              </div>
                              <div class="image-container" v-else>
                                <md-button class="button-pointer md-fileinput ima-submit">
                                  <img :src="imageRegular" />
                                  <input type="file" @change="onFileChange" @click="onInputClick" />
                                </md-button>
                              </div>
                              <div v-if="pdfName && imageRegular" class="pdf-name">
                                {{ pdfName }}
                              </div>
                              <div class="button-container">
                                <div class="remove-change button-pointer">
                                  <md-button
                                    class="md-primary md-round md-fileinput ima-submit"
                                    v-if="imageRegular"
                                    @click="uploadEducationProof"
                                    >{{ $t('upload') }}
                                  </md-button>
                                  <md-button
                                    :class="[
                                      { 'md-simple': !imageRegular },
                                      'md-fileinput',
                                      'md-round',
                                      'button-pointer',
                                      'ima-change',
                                      { 'md-simple': imageRegular },
                                    ]"
                                  >
                                    <template v-if="!imageRegular">{{ $t('selectImg') }}</template>

                                    <template v-else>{{ $t('change') }}</template>
                                    <input type="file" @change="onFileChange" @click="onInputClick" />
                                  </md-button>
                                  <md-button
                                    class="md-simple md-round button-pointer ima-delete"
                                    @click="removeImage"
                                    v-if="imageRegular"
                                    >{{ $t('remove') }}
                                  </md-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </md-card-content>
              </md-card>
              <md-card class="ima-tile" v-if="verificationApproved">
                <md-card-header>
                  <h4 class="title" v-if="verificationApproved && paymentSuccess">{{ $t('congratulations') }}</h4>
                  <h4 class="title" v-if="verificationApproved && !paymentSuccess">{{ $t('paymentProcess') }}</h4>
                  <p class="category">{{ $t('forMdesignStudent') }}</p>
                </md-card-header>
                <md-card-content class="content-style approved-status">
                  <div v-if="verificationApproved">{{ $t('requestLicenseAllowed2022') }}</div>
                </md-card-content>
              </md-card>
            </div>
          </div>
          <div v-if="imaFileNames.length" class="ima-docs">
            <md-card>
              <md-card-header>
                <h4 class="title-documents-download">
                  {{ $t('downloadima') }}
                </h4>
              </md-card-header>
              <div v-for="(item, index) in imaFilesData" :key="index" class="download-ima-section">
                <div class="download-ima-name" @click="downloadIma(imaFilesData[index].ImaFile, item.Name)">
                  {{ item.Name }}
                </div>
                <div class="ima-actions">
                  <div class="download-delete-ima" @click="downloadIma(imaFilesData[index].ImaFile, item.Name)">
                    <md-icon @click="downloadIma(imaFilesData[index].ImaFile, item.Name)" class="download-icon"
                      >file_download</md-icon
                    >
                  </div>
                  <div class="download-delete-ima" @click="deleteIma(item.DataFileID)" v-if="!verificationApproved">
                    <md-icon class="delete-icon">delete_outline</md-icon>
                  </div>
                </div>
              </div>
            </md-card>
          </div>
        </div>
      </template>
      <template slot="tab-pane-2">
        <md-card :key="componentKey">
          <md-card-header>
            <h4 class="title2" v-if="!verificationApproved">
              {{ $t('provideStudentInfo') }}
            </h4>
            <h4 class="title2" v-if="verificationApproved">
              {{ $t('downloadLicence') }}
            </h4>
            <div v-for="lic in licenceFileData" class="md-layout lic-row" :key="lic.ID">
              <span class="md-layout-item md-size-66">{{ lic.Name }}</span>
              <a id="download-link" v-on:click="downloadLicence(lic)" class="download-button md-layout-item md-size-33">
                <md-button v-if="verificationApproved" :class="['md-primary md-round rings']">
                  {{ $t('download') }}
                </md-button>
              </a>
            </div>
          </md-card-header>
        </md-card>
      </template>
    </tabs>
  </div>
</template>

<script>
import { Tabs, TimeLine, TimeLineItem } from '@/components'
import { useUserStore } from '@/stores/user.js'
import { useCompanyStore } from '@/stores/company'

import Student from './Student.vue'
import image_placeholder from '../../../../public/img/image_placeholder.jpg'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      loader: false,
      disableCTA: false,
      dataFileID: '',
      productID: '{14dc88db-5d79-447a-9bd6-193ae1fc0c90}',
      // downloadFile: this.userStore.userDownloadFile.RoloffMatek,
      link: '',
      componentKey: 0,
      langChange: '',
      tabName: [
        ['Lizenz', 'Datei'], // disabled tab panes: , 'Bedingungen für die Nutzung'
        ['Licence', 'File'], // disabled tab panes:  'Terms of use',
      ],
      verificationStatus: '',
      studentEmailTimeline: '',
      eductionProofText: '',
      badgeVerificationInProcess: 'disabled',
      verificationInProcess: false,
      reviewImaText: '',
      badgeVerificationApproved: 'disabled',
      verificationApproved: false,
      confirmStudentEmail: false,
      studentEmailIsInReview: false,
      imaNotReadable: false,
      noUniEmail: false,
      imaFileNames: [],
      studentEmail: '',
      studentProductID: '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}',
      paymentSuccess: false,
      imaFilesData: [],
      licenceFileData: [],
      imageRegular: '',
      regularImg: image_placeholder,
      pdfName: '',
      verificationID: '',
      licenceFileID: '',
    }
  },
  components: {
    Tabs,
    TimeLine,
    TimeLineItem,
  },
  methods: {
    async verifyStudentEmail() {
      await Student.methods.verifyStudentEmail.call(this)
    },
    async uploadEducationProof() {
      const response = await Student.methods.uploadEducationProof.call(this, this.productID)
      if (response.status === 200) {
        const verification = await this.userStore.getUserVerification(this.studentProductID)
        await this.userStore.changeStatusOfVerification(
          this.studentProductID,
          verification[0].ContactID,
          this.verificationStatus,
          verification[0].ID,
        )
      }
    },
    onFileChange(e) {
      Student.methods.onFileChange.call(this, e)
    },
    onInputClick(e) {
      Student.methods.onInputClick.call(this, e)
    },
    removeImage() {
      Student.methods.removeImage.call(this)
    },
    createImage() {
      Student.methods.createImage.call(this, this.file[0])
    },
    checkProfileData() {
      const returnValue = Student.methods.checkProfileData.call(this)
      return returnValue
    },
    async sendEmailApproveStudentImo() {
      await Student.methods.sendEmailApproveStudentImo.call(this)
    },
    async getVerificationStatus() {
      await Student.methods.getVerificationStatus.call(this, this.productID)
    },
    async getImaFileNames() {
      await Student.methods.getImaFileNames.call(this)
    },
    async getImaFile() {
      await Student.methods.getImaFile.call(this)
    },
    async deleteIma(dataFileID) {
      this.loader = true
      await Student.methods.deleteIma.call(this, dataFileID)
      this.loader = false
    },
    async downloadIma(imaFile, name) {
      await Student.methods.downloadIma.call(this, imaFile, name)
    },
    async downloadLicence(lic) {
      var res = await this.companyStore.getDownloadFile(lic.ID, true, this.userStore.token)
      if (res.status === 200) {
        const blobData = res.data
        // Create a blob URL
        const blobUrl = URL.createObjectURL(blobData)
        // Create an anchor element
        const link = document.createElement('a')
        // Set the href attribute to the blob URL
        link.href = blobUrl
        // Specify the download attribute and the desired file name
        link.download = lic.Name // Replace 'yourFileName.ext' with the desired file name
        // Append the link to the body (you can also append it to another element)
        document.body.appendChild(link)
        // Trigger a click on the link to start the download
        link.click()
        // Remove the link from the DOM
        document.body.removeChild(link)
        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(blobUrl)
        // this.userStore.updateDownloadCount()
      } else if (res.status === 404) {
        Swal.fire({
          title: this.$t('error'),
          text: this.$t('licNotFound'),
          icon: 'error',
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          text: this.$t('licError'),
          icon: 'error',
        })
      }
      return
      await Student.methods.downloadLicence.call(this, templateName)
    },
    async updateValidityStartDate() {
      await Student.methods.updateValidityStartDate.call(this)
    },
  },
  async mounted() {
    this.$changePageTitle(this.$t('pagetTitleStudentVersion22'))
    if (!this.userStore.userProductsID.includes(this.productID)) this.$router.push({ name: 'My Products' })
    this.loader = true
    await Student.methods.getVerificationStatus.call(this, this.productID)
    if (!this.verificationStatus || this.verificationStatus === 2 || this.verificationStatus === 1) {
      await Student.methods.checkEmailDomen.call(this, this.productID)
    }
    await Student.methods.getImaFileNames.call(this)
    await Student.methods.getImaFile.call(this)
    this.loader = false
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    return { userStore, companyStore }
  },
  watch: {
    downloadFile() {
      this.componentKey++
    },
  },
  async beforeMount() {
    if (this.$i18n.locale === 'de-DE') {
      this.langChange = 0
    } else {
      this.langChange = 1
    }
    let consonants = 'b, c, d, f, g, h, j, k, l, m, n, p, q, r, s, t, v, w, x, z'.replaceAll(', ', '')

    this.licenceFileData = []
    var res = await this.companyStore.getFileReferenceByTable(
      this.userStore.userData.ID,
      'contact_has_product',
      this.userStore.token,
    )
    if (res.status === 200) {
      const promises = res.data.map(async (ref) => {
        try {
          var resDataFile = await this.companyStore.getDataFileByID(ref.DataFileID, this.userStore.token)

          if (resDataFile.status !== 200) return

          this.licenceFileData.push(resDataFile.data[0])

          // var resDownload = await companyStore.getDownloadFile(resDataFile.data[0].ID, false, token)

          // if (resDownload.status !== 200) return
        } catch (error) {
          // console.log('error Product ', p.ID, error)
          return false
        }
      })

      await Promise.all(promises)
    }
  },
  updated() {
    this.eductionProofText = [3, 4, 6].includes(this.verificationStatus)
      ? this.$t('uploadedEducationProof')
      : this.$t('toUploadEducationProof')
    this.reviewImaText = [3].includes(this.verificationStatus)
      ? this.$t('verificationProcessFinished')
      : this.$t('verificationProcess')

    if (this.$i18n.locale === 'de-DE') {
      this.langChange = 0
    } else {
      this.langChange = 1
    }
  },
}
</script>

<style scoped>
.button-invisible {
  display: none;
}

/deep/.tab-content {
  display: flex;
  justify-content: center;
}

/deep/.content-style {
  display: flex !important;
  justify-content: center;
}

@keyframes ring {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  100% {
    width: 300px;
    height: 300px;
    opacity: 1;
  }
}

.rings::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #009ee3;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 2s infinite;
}

.rings:hover::after,
button:focus::after {
  animation: none;
  display: none;
}
/* 
.download-button {
  display: flex;
  justify-content: center;
} */

.md-card {
  padding: 15px 20px;
}

.warning-message {
  margin: 0 15px 0 75px;
}

.tiles-structure {
  display: flex;
  flex-direction: column;
}

.timeline-ima {
  display: flex;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.ima-tile {
  margin-top: 45px;

  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
}

.title-documents-download {
  display: flex;
  justify-content: center;
  margin: 0;
}

.download-ima-section {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

/deep/.timeline:before {
  height: 70%;
}

.md-card-plain {
  margin: 0;
}

.upload-document {
  display: flex;
  justify-content: center;
}

.image-container button {
  width: 250px;
  padding: 0;
  margin: 0;
  display: flex;
}

.ima-actions {
  display: flex;
  justify-content: space-between;
}

.download-delete-ima,
.download-ima-name {
  cursor: pointer;
}

.download-ima-section {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.download-icon,
.delete-icon {
  margin: 10px;
}

.title-documents-download {
  display: flex;
  justify-content: center;
  margin: 0;
}

.image-container /deep/.md-ripple {
  padding: 0 !important;
  background-color: white !important;
}

.remove-change {
  display: flex;
}

/deep/.content-style {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

div.ima-docs {
  margin-right: 15px;
  margin-left: 55px;
}
.rings::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #81c2de;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite !important;
}

.rings {
  height: 30px;
  display: flex;
  align-self: center;
  width: 95px;
}

.rings:hover::after,
button:focus::after {
  animation: none;
  display: none;
}
/* .download-button {
  margin-left: 15px;
} */
@keyframes ring {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  100% {
    width: 300px;
    height: 300px;
    opacity: 1;
  }
}
/* .download-button {
  display: flex;
  justify-content: center;
} */
.lic-row {
  padding: 10px 0;
}
.lic-row span {
  margin: auto;
}
#uniEmail {
  white-space: normal !important;
  word-wrap: break-word !important;
}
</style>

<template>
  <div class="md-layout md-size-33 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-default">
        <h2 class="title text-center">
          {{ $t('setPasswordTitle') }}
        </h2>
      </md-card-header>
      <md-card-content class="sucess-text">
        {{ $t('setPasswordRule') }}
        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider
            name="password"
            :rules="{
              required: true,
              regex: regExValid,
            }"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, 'new-password']" required>
              <label>{{ $t('password') }}</label>
              <md-input v-model="password" type="password"> </md-input>

              <slide-y-down-transition>
                <md-icon class="error password" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success password" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <div class="button-container">
            <md-button
              class="md-primary md-round mt-4"
              slot="footer"
              :disabled="invalid"
              @click.native="submitNewPassword()"
              >{{ $t('submit') }}</md-button
            >
          </div>
        </ValidationObserver>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, regex } from 'vee-validate/dist/rules'
import Swal from 'sweetalert2'
import { httpPost, ApiNames } from '../../libs/httpHelper'
import { useUserStore } from '../../stores/user'

extend('required', required)
extend('regex', regex)

export default {
  data() {
    return {
      password: '',
      email: '',
      regExValid: /.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[&"'(\-_)=~#{[|`\\^@\]}^$*¨£µ%,;:!?./§+]).*/,
    }
  },

  methods: {
    submitNewPassword() {
      this.setNewPassword()
    },
    async setNewPassword() {
      var obj = {
        Email: this.email,
        Password: this.password,
      }

      var base64 = btoa(JSON.stringify(obj))

      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      var response = await httpPost(ApiNames.resetpassword, base64, customConfig)
      if (response?.status === 200) {
        Swal.fire({
          position: 'middle-end',
          icon: 'success',
          title: this.$t('congratulations'),
          html: this.$t('setNewPassTextPopup'),
          showConfirmButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#009ee3',
        })
        this.$router.push({ path: '/login' })
      } else {
        Swal.fire({
          position: 'middle-end',
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('setNewPassErrorPopup'),
          showConfirmButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#009ee3',
        })
      }
    },
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('id')
    await useUserStore().validateToken(token)
    this.email = useUserStore().userData.Email
  },
  beforeMount() {
    this.$changePageTitle(this.$t('pageTitleNewPassword'))
  },
}
</script>

<style scoped>
.sucess-text {
  text-align: center;
  font-size: 24px;
}

.sucess-text a {
  color: #009ee3;
}

.new-password {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
}
</style>

<template>
  <md-card class="card-content edit-role-card" v-if="role.ID" :key="renderKey">
    <md-card-header class="md-card-header-icon md-card-header-primary">
      <div>
        <div class="card-icon">
          <md-icon>checklist_rtl</md-icon>
        </div>
        <h3 class="title">{{ $t('editPermissions') }}</h3>
        <h4>{{ role.Name }}</h4>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout-item">
        <div class="md-layout">
          <div class="md-layout-item md-size-33">
            <md-field>
              <label>{{ $t('search') }}</label>
              <md-input v-model="search" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-66 text-right">
            <md-button class="md-primary md-round" @click="handleCreate"
              >{{ $t('create') }}
              <md-tooltip>{{ $t('newPermission') }}</md-tooltip>
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <md-field>
            <div class="md-layout-item md-size-35">
              {{ $t('name') }}
            </div>
            <div class="md-layout-item md-size-55">
              {{ $t('description') }}
            </div>
            <div class="md-layout-item md-size-10 text-right">
              <span>{{ $t('permission') }}</span>
            </div>
          </md-field>
        </div>
        <div
          class="md-layout"
          v-for="(permission, index) in filteredPermissions"
          :key="permission.Name"
          :class="{ 'bg-table': index % 2 !== 0 }"
        >
          <div class="md-layout-item md-size-35">
            <span>{{ permission.Name }}</span
            ><br />
            <span class="permission-id">{{ permission.ID }}</span>
          </div>
          <div class="md-layout-item md-size-55">
            <span>{{ permission.Description }}</span>
          </div>
          <div class="md-layout-item md-size-10 pr-5">
            <md-checkbox @change="updateRolePermission(permission)" v-model="permission.Granted"></md-checkbox>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import { useManagementStore } from '@/stores/management'
import Swal from 'sweetalert2'

export default {
  emits: ['showModal'],
  props: {
    role: {
      type: Object,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      renderKey: 0,
      allPermissions: [
        {
          Granted: false,
        },
      ],
      rolePermissions: [],
      search: '',
    }
  },
  async beforeMount() {
    this.loader = true

    await this.getAllPermissions()

    await this.getRolePermissions()

    if (this.rolePerms.length > 0) {
      this.rolePerms.forEach((p) => {
        var temp = this.allPermissions.find((per) => per.ID === p.PermissionID)
        temp.Granted = true
      })
    }
    this.renderKey++
    this.loader = false
  },
  methods: {
    async updateRolePermission(permission) {
      var title = permission.Granted ? this.$t('titleGrantPermission') : this.$t('titleDeletePermission')
      var text = permission.Granted ? this.$t('textGrantPermission') : this.$t('textDeletePermission')
      text = text + '<br><br>' + this.role.Name + ': ' + permission.Description
      var question = await Swal.fire({
        title: title,
        html: text,
        icon: 'question',
        showCancelButton: true,
      }).then((result) => {
        return result
      })
      if (!question.isConfirmed) {
        permission.Granted = !permission.Granted
        return
      } else {
        if (permission.Granted) {
          var res = await this.createRolePermission(permission)
          if (!res) permission.Granted = !permission.Granted
        } else {
          var res = await this.deletePermission(permission)
          if (!res) permission.Granted = !permission.Granted
        }
      }
      this.renderKey++
    },
    async createRolePermission(permission) {
      var res = await this.managementStore.createUserRoleHasPermission(
        this.role.ID,
        permission.ID,
        this.userStore.token,
      )
      if (res.status === 200) return true
      else return false
    },
    async deletePermission(permission) {
      var res = await this.managementStore.deleteRolePermission(this.role.ID, permission.ID, this.userStore.token)
      if (res.status === 200) return true
      else return false
    },
    handleCreate() {
      this.$emit('showModal')
    },
    async getAllPermissions() {
      var resPerm = await this.managementStore.getAllPermissions(this.userStore.token)
      this.allPermissions = resPerm.data
    },
    async getRolePermissions() {
      var rolePerms = await this.managementStore.getPermissionsByRoleID(this.role.ID, this.userStore.token)
      this.rolePerms = rolePerms.data
    },
  },
  computed: {
    filteredPermissions() {
      return this.allPermissions.filter((item) => {
        const searchTerm = this.search.toLowerCase()
        return (
          (item.Name && item.Name.toLowerCase().includes(searchTerm)) ||
          (item.Description && item.Description.toLowerCase().includes(searchTerm)) ||
          (item.ID && item.ID.toLowerCase().includes(searchTerm))
        )
      })
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const managementStore = useManagementStore()
    return { companyStore, userStore, configurationProductLevelStore, managementStore }
  },
}
</script>

<style scoped lang="scss">
.mr-0 {
  margin-right: 0 !important;
}
.md-layout-item,
.md-checkbox {
  padding: 5px 2px;
  margin: auto 0 !important;
}
.pr-5 {
  padding-right: 5px;
}
.permission-id {
  font-size: 12px;
  color: lightgray;
}
.just-right {
  justify-content: right;
}
</style>

<template>
  <div class="md-layout scroll-behavior">
    <div class="md-layout-item" :key="renderKey">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">{{ $t('editAccess') }}</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover overflow-hidden"
          >
            <md-table-toolbar>
              <div class="toolbar-left">
                <md-field>
                  <div>
                    <label for="pages" id="perPage">{{ $t('perPage') }}</label>
                  </div>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
                <md-field class="ml-10">
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    :placeholder="searchRecords"
                    v-model="searchQuery"
                  >
                  </md-input>
                </md-field>
              </div>

              <div class="pad-5">
                <md-checkbox class="m-tb-auto" v-model="allSelected" @change="toggleAll"
                  >{{ $t('selectAll') }}
                </md-checkbox>
              </div>
            </md-table-toolbar>
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item, index }"
              @click.native="onTableRowClick(item, index)"
              :class="{
                'md-selected md-has-selection': checkedSelection[index],
              }"
            >
              <md-table-cell :md-label="'#'">
                {{ calculatePosition(index) }}
              </md-table-cell>

              <md-table-cell :md-label="$t('name')"
                ><strong>{{ item.FirstName + ' ' + item.LastName }}</strong></md-table-cell
              >

              <md-table-cell :md-label="$t('email')">{{ item.Email }}</md-table-cell>

              <md-table-cell @click.prevent="test()">
                <md-checkbox
                  class="m-auto"
                  md-auto-select
                  v-model="checkedSelection[index]"
                  @change="onSelect(item)"
                ></md-checkbox>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div v-if="queriedData.length === 0" class="md-center">
            <h3>{{ $t('noContactsFound') }}</h3>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="card-actions-container">
            <div class="button-actions">
              <div class="btn-container">
                <md-button class="md-simple md-round mr-10" @click="createNewContact()">{{
                  $t('createNewContact')
                }}</md-button>
              </div>
              <div class="btn-container">
                <md-button class="md-primary md-round" @click="confirmSelection()">{{ btnName }}</md-button>
              </div>
            </div>
            <div class="table-actions">
              <div class="">
                <p class="card-category">
                  {{ $t('show') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
                </p>
              </div>
              <pagination
                class="pagination-no-border pagination-success"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </pagination>
            </div>
          </div>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { useUserStore } from '../../stores/user'
import { useCompanyStore } from '../../stores/company'
import { useConfigurationStore } from '../../stores/configuration'

export default {
  components: {
    Pagination,
  },
  emits: ['toggleAws', 'updateAwsList'],
  props: {
    companyID: { type: String, default: '' },
    awsIds: { type: Array },
    contacts: { type: Array },
    configurationAws: { type: Object },
  },
  computed: {
    // return userStore
    // }
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      // only show the data that was actually found
      // if (this.searchedData.length > 0) {
      result = this.searchedData
      // }
      return result.slice(this.from, this.to)
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length
      // only show data that was actually found
      // if nothing is found $t("noContactsFound") will show up
      //> 0
      // ? this.searchedData.length
      //   : this.tableData.length;
    },
    btnName() {
      if (this.selected.length === 0) {
        return this.$t('back')
      } else {
        return this.$t('save')
      }
    },
  },
  data() {
    return {
      awsIDArray: [],
      selected: [],
      currentSort: 'LastName',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
        active: false,
      },
      searchQuery: '',
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      propsToSearch: ['FirstName', 'LastName', 'Email'],
      searchRecords: this.$t('searchRecords'),
      perPage: this.$t('perPage'),
      renderKey: 1,
      userFilter: 1,
      companyTypes: [],
      screenW: 0,
      checkedSelection: [],
      allSelected: false,
      contactIds: [],
    }
  },
  methods: {
    toggleAll() {
      // Toggle the variable to indicate whether all items are selected or not

      // Update the selection for each item based on the toggle state
      this.checkedSelection = this.queriedData.map(() => this.allSelected)

      // Update the selected items array
      this.selected = this.allSelected ? [...this.queriedData] : []
    },
    onTableRowClick(item, index) {
      // Find the index of the clicked item in the selected array
      const existingIndex = this.selected.findIndex((sel) => sel.ID === item.ID)

      if (existingIndex !== -1) {
        // Item is already in the selection, remove it
        this.selected.splice(existingIndex, 1)
      } else {
        // Item is not in the selection, add it
        // this.selected.push(item);
        this.onSelect(item)
      }

      // Update the corresponding checkbox state
      this.$set(
        this.checkedSelection,
        this.pagination.perPage * (this.pagination.currentPage - 1) + index,
        this.selected.includes(item),
      )
    },
    onSelect(item) {
      // Update this.selected to display in the table
      // Update this.awsIDArray to store only the IDs
      if (item) {
        const existingIndex = this.selected.findIndex((sel) => sel.ID === item.ID)

        if (existingIndex !== -1) {
          // Item is already in the selection, remove it
          this.selected.splice(existingIndex, 1)
        } else {
          // Item is not in the selection, add it
          this.selected.push(item)
        }

        this.awsIDArray = this.selected.map((sel) => sel.ID)
      }
    },
    async confirmSelection() {
      if (this.selected.length < 1) {
        this.$emit('toggleAws')
        return
      }
      this.loader = true
      const token = this.userStore.token

      this.contactIds = []
      let awsUsers = []
      let awsUsers_Old = []
      let accountType = this.configurationStore.configurationAccountTypes.find(
        (el) => el.ID === this.configurationAws.ConfigurationAccountTypeID,
      )
      let oldAccountTypeValue = accountType.LicenseProfilesOldID
      let stack = this.configurationStore.awsStacks.find((el) => el.Stacks === this.configurationAws.FleetNormal)
      this.selected.forEach((obj) => {
        var mdAwsUser = {
          ContactID: obj.ID,
          AwsUserTypeID: '1',
          AwsStacksID: stack.ID,
          AwsGroupID: '1',
          StartDate: this.configurationAws.StartDate,
          EndDate: this.configurationAws.EndDate,
          Enabled: false,
          ConfirmedDate: '0001-01-01',
          Confirmed: false,
        }
        var awsUser_Old = {
          UserID: 0,
          FirstName: obj.FirstName,
          LastName: obj.LastName,
          Email: obj.Email,
          DateStart: this.configurationAws.StartDate,
          DateEnd: this.configurationAws.EndDate,
          Agentur: this.userStore.userData.LastName,
        }
        awsUsers.push(mdAwsUser)
        awsUsers_Old.push(awsUser_Old)
      })

      const creatorId = this.userStore.userData.ID
      const configurationId = this.configurationAws.ID

      const promisesNew = awsUsers.map(async (item) => {
        await this.createAwsUser_new(item, configurationId)
      })
      await Promise.all(promisesNew)

      const dataObj = {
        configurationAwsId: configurationId,
        contactIds: this.contactIds,
        creatorId: creatorId,
      }

      await this.configurationStore.createConfigurationAwsHasContactManyContacts(dataObj, token)

      this.$emit('updateAwsList', this.configurationAws)
    },
    async createAwsUser_new(item, configID) {
      var resAwsUser = await this.configurationStore.getConfigurationAwsHasContactPrimary(
        configID,
        item.ContactID,
        this.userStore.token,
      )
      if (resAwsUser.status === 404) {
        var res = await this.configurationStore.CreateAwsUser(item, this.userStore.token)
        this.contactIds.push(item.ContactID)
      }
    },
    /*
    async createAwsUser_Old(item) {
      // var obj = {
      //   UserID: 0,
      //   FirstName: '',
      //   LastName: '',
      //   Email: '',
      //   DateStart: '0001-01-01',
      //   DateEnd: '0001-01-01',
      //   Agentur: this.userStore.userData.ID,
      // }

      var res = await this.configurationStore.createAwsUserOld(item, this.userStore.token)
    },
    */
    createNewContact() {
      const params = {
        companyID: this.companyID,
        companyTypes: this.companyStore.companyTypes,
        configurationAws: this.configurationAws,
      }
      this.$router.push({
        name: 'Create Company Contact',
        params: params,
      })
    },
    back() {
      this.$emit('toggleAws')
    },
    changeTranslation() {
      this.renderKey += 1
      this.perPage = this.$t('perPage')
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    calculatePosition(index) {
      return index + 1 + (this.pagination.currentPage - 1) * this.pagination.perPage
    },
  },
  mounted() {
    if (this.companyID === '') this.$router.push({ name: 'Aws Account' })
    var selectableContacts = []
    if (this.contacts.length > 0) {
      selectableContacts = this.contacts.filter((contact) => !this.awsIds.includes(contact.ID))
    }

    this.tableData.splice(0, this.tableData.length, ...selectableContacts)
    this.searchedData.splice(0, this.searchedData, ...this.tableData)
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['FirstName', 'LastName', 'Email'],
      findAllMatches: true,
      useExtendedSearch: true,
      shouldSort: true,
      ignoreLocation: true,
      threshold: 0.1,
    })
    this.renderKey++
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        //if you only want to show perfect matches use .search('=' + this.searchQuery)
        result = this.fuseSearch.search(this.searchQuery)
        //get access to result items
        result = result.map((record) => record.item)
      }
      this.searchedData.splice(0, this.searchedData.length, ...result)
    },
    perPage() {
      this.renderKey++
    },
    allSelected() {
      this.renderKey++
    },
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    const configurationStore = useConfigurationStore()
    return { userStore, companyStore, configurationStore }
  },
  updated() {
    if (this.perPage != this.$t('perPage')) {
      this.perPage = this.$t('perPage')
      this.searchRecords = this.$t('searchRecords')
    }
  },
}
</script>

<style lang="css" scoped>
@media (max-width: 500px) {
  .toolbar-left {
    flex-direction: column;
  }
}

@media (min-width: 501px) {
  .toolbar-left {
    display: flex;
    flex-direction: row;
  }

  .ml-10 {
    margin-left: 10px;
  }
}

.md-card {
  width: 98%;
  margin: 0 auto;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.text-align-center {
  text-align: center !important;
}

.md-layout-item {
  padding: 0;
}

.md-center {
  text-align: center;
}
.mr-10 {
  margin-right: 10px;
}
.card-actions-container {
  width: 100%;
}
.table-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.button-actions {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.btn-container {
  padding-left: 4px;
}
.m-auto {
  margin: auto !important;
}
.m-tb-auto {
  margin: auto 0 !important;
}
.pad-5 {
  padding: 5px;
}
</style>

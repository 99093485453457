<template>
  <div class="text-center">
    <a class="md-primary md-round classic-modal" title="Cookies" @click="openModal">Cookies</a>

    <div class="mmodal-content">
      <modal v-if="classicModal">
        <template slot="header">
          <h4 class="modal-title">{{ $t('cookieSettingsTitle') }}</h4>
          <md-button class="md-just-icon modal-default-button icon-style" @click="classicModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body" class="modal-body">
          <div>
            {{ $t('cookiesSettingsText') }}
            <a :href="$t('privacyLink')" target="_blank" class="privacy-policy">{{ $t('privacyPolicy') }}</a
            >.
            <hr />
            <div class="essential-cookies">
              <md-switch v-model="essis" disabled></md-switch>
              <div class="essential-cookies-descrp">
                <div>
                  {{ $t('essentialCookiesTitle') }}
                  <span class="es-required">{{ $t('alwaysRequired') }}</span>
                </div>
                <div>{{ $t('essentialCookiesText') }}</div>
                <div class="purposes">{{ $t('purposeEssential') }}</div>
              </div>
            </div>
            <div class="marketing-cookies">
              <md-switch v-model="stats" :class="[{ 'switch-off': !stats }]"></md-switch>
              <div class="marketing-cookies-descrp">
                <div>{{ $t('marketingCookies') }}</div>
                <div>{{ $t('marketingCookiesText') }}</div>
                <div class="purposes">{{ $t('purposeMarketing') }}</div>
              </div>
            </div>
          </div>
        </template>

        <template slot="footer">
          <md-button class="md-primary md-round" @click="classicModalSave">{{ $t('save') }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Modal } from '..'
import VueCookies from 'vue-cookies'
import { unsetStatsGA } from '../../utils'
export default {
  components: {
    Modal,
  },
  data() {
    return {
      type: ['', 'info', 'success', 'warning', 'danger'],
      classicModal: false,
      essis: true,
      stats: this.stats,
    }
  },
  methods: {
    openModal: function () {
      this.$sidebar.showProductMenu = false
      this.classicModal = true
      this.stats = VueCookies.get('my.mdesign-klaro').stats === true
    },
    classicModalHide: function () {
      this.classicModal = false
    },
    classicModalSave: function () {
      this.classicModal = false
      VueCookies.set('my.mdesign-klaro', { essis: this.essis, stats: this.stats })
      if (!this.stats) {
        unsetStatsGA()
      } else {
        this.setStatsGA()
      }
    },
    setStatsGA() {
      // Find the scipt tag with the Google Analytics code
      const scripts = document.querySelectorAll('script[src^="https://www.googletagmanager.com/gtag/js?id="]')
      scripts.forEach((script) => {
        // Modify the script tag attributes
        script.remove()
      })
      var environment = window.location.hostname

      var analyticsID = 'G-NJL0T2PYH0' // Default for test environment
      if (environment === 'my.mdesign.de') {
        analyticsID = 'G-B0R9Z0F5TL'
      }
      // Create the script tag with the correct src
      var script = document.createElement('script')
      script.async = true
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + analyticsID
      document.head.appendChild(script)
      // Initialize the data layer and gtag function
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', analyticsID)
    },
  },
}
</script>
<style>
a:hover {
  cursor: pointer;
}

.modal-container {
  color: #3c4858;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.privacy-policy {
  all: unset !important;
  text-decoration: underline !important;
}

.main-panel > .footer-position a.privacy-policy {
  color: #3c4858 !important;
}

.privacy-policy:hover {
  cursor: pointer !important;
}

.essential-cookies,
.marketing-cookies {
  display: flex;
}

.essential-cookies-descrp,
.marketing-cookies-descrp {
  text-align: left;
  margin-left: 16px;
}

.es-required,
.purposes {
  font-size: 0.8em;
  color: #878989;
  opacity: 0.5;
}

.icon-style {
  background-color: transparent !important;
  color: #00325b !important;
  border: none !important;
  box-shadow: none !important;

  &:hover {
    background-color: transparent !important;
    color: #00325b !important;
  }
}

.md-button i:hover {
  color: #00325b !important;
}
</style>

<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66">
      <ValidationObserver v-slot="{ invalid }">
        <form class="create-event">
          <loader :loader="loader" />
          <md-card class="card-content md-layout-item">
            <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
              <div>
                <div class="card-icon">
                  <md-icon>event</md-icon>
                </div>
                <h4 class="title">
                  {{ $t('createEvent') }}
                </h4>
              </div>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <ValidationProvider name="eventName" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('eventName') }}</label>
                      <md-input v-model="event.Name" type="text"> </md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-33">
                  <ValidationProvider name="eventPersonCount" rules="required|integer" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('eventPersonCount') }}</label>
                      <md-input v-model="event.Count" type="number"> </md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-33">
                  <ValidationProvider name="eventPersonCount" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('version') }}</label>
                      <md-select v-model="event.Version" name="version">
                        <md-option v-for="item in accountTypes" :key="item.ID" :value="item.ID">{{
                          item.Name
                        }}</md-option>
                      </md-select>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field>
                    <label>{{ $t('date') }}</label>
                    <md-input v-model="event.Date" type="date" />
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field>
                    <label>{{ $t('start') }}</label>
                    <md-input v-model="event.Start" type="time"> </md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-33">
                  <md-field>
                    <label>{{ $t('end') }}</label>
                    <md-input v-model="event.End" type="time"> </md-input>
                  </md-field>
                </div>

                <div class="update-profile-button md-layout-item md-size-100 text-right">
                  <div class="">
                    <md-button class="md-raised md-primary mt-4 md-round" @click="handleCreate" :disabled="invalid"
                      >{{ $t('save') }}
                    </md-button>
                  </div>
                  <div class="text-center"></div>

                  <form enctype="multipart/form-data"></form>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import { useUserStore } from '../../stores/user'
import { useConfigurationStore } from '@/stores/configuration'
import Swal from 'sweetalert2'
import { Slider } from '@/components'
import { useCompanyStore } from '../../stores/company'
import { permissionMixin } from '../RoleManagement/permissionMixin'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

export default {
  mixins: [permissionMixin],
  components: {},
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  name: 'create-event-form',
  data() {
    return {
      loader: false,
      event: {},
      accountTypes: [],
    }
  },

  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationStore = useConfigurationStore()
    return { companyStore, userStore, configurationStore }
  },
  async beforeMount() {
    this.$changePageTitle(this.$('pageTitleCreateEvent'))

    var res = await this.configurationStore.getConfigurationAccountTypes(this.userStore.token)

    this.accountTypes = [...this.configurationStore.configurationAccountTypes]
  },
  mounted() {
    this.event.Date = this.event.Date === undefined ? this.companyStore.getToday() : this.event.Date
    this.event.Start = this.event.Start === undefined ? '08:00' : this.event.Start
    this.event.End = this.event.End === undefined ? '12:00' : this.event.Start
  },
  methods: {
    handleCreate() {
      // console.log('event ', this.event)
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
  },
}
</script>

<style lang="scss" scoped></style>

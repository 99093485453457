<template>
  <div class="md-layout text-center" id="login-tile">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <loader :loader="loader" />
      <login-card header-color="primary">
        <img slot="title" :src="MyMdesignLogo" alt="MyMdesignLogo" class="img-title" />
        <div slot="inputs">
          <ValidationObserver v-slot="{ invalid }">
            <form>
              <ValidationProvider name="user" rules="required|email" v-slot="{ passed, failed }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t('email') }}</label>
                  <md-input v-model="contact.Email" type="text" id="user" disabled> </md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                name="password"
                :rules="{
                  required: true,
                  regex: regExValid,
                }"
                v-slot="{ passed, failed }"
              >
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t('password') }}</label>
                  <md-input v-model="password" type="password" id="password"></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <div v-show="failed" class="password-rule">
                  {{ $t('setPasswordRule') }}
                </div>
              </ValidationProvider>
            </form>
            <div class="remaining-attempts">
              <span v-if="wrongPw">{{ $t('remainingAttempts') + userStore.lockout.Attempts }}</span>
            </div>
            <div class="cta-style">
              <md-button
                slot="footer"
                class="md-primary md-round"
                id="Submit"
                :disabled="invalid"
                @click.prevent="handleSubmit"
                ><md-icon>how_to_reg</md-icon>
                {{ $t('registration') }}
              </md-button>
            </div>
          </ValidationObserver>
        </div>
      </login-card>
    </div>
  </div>
</template>

<script>
import { LoginCard } from '@/components'
import { SlideYDownTransition } from 'vue2-transitions'
import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import { useUserStore } from '../../stores/user'
import { useCompanyStore } from '../../stores/company'
import { useManagementStore } from '@/stores/management'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import { httpPost, ApiNames } from '@/libs/httpHelper'
import Swal from 'sweetalert2'

extend('email', email)
extend('required', required)
extend('regex', regex)

export default {
  components: {
    LoginCard,
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    const managementStore = useManagementStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    return { userStore, companyStore, managementStore, configurationProductLevelStore }
  },
  async beforeMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const contactStr = decodeURIComponent(urlParams.get('id'))
    const contactID = atob(contactStr)
    const urlToken = urlParams.get('token')
    const tokenDecode = JSON.parse(decodeURIComponent(escape(atob(urlToken.split('.')[1]))))
    const emailFromToken = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']

    var resToken = await this.userStore.createTokenByEmail(emailFromToken)
    if (!resToken.Message) {
      this.token = resToken
      var resContact = await this.companyStore.getContactByID(contactID, this.token)
      if (resContact.status === 200) {
        this.contact = resContact.data[0]
      }

      var resRoles = await this.companyStore.getUserRoles(this.token)

      if (resRoles.status === 200) {
        this.userRoles = resRoles.data
      }
    }
  },
  methods: {
    async handleSubmit() {
      var res = await this.registerUser()
      if (res) await this.updateUser()
      return
      if (res.success) await this.updateUser()
      else {
        Swal.fire({
          title: this.$t('error'),
          text: this.$t('userExistConfirmConcactText'),
          icon: 'error',
        }).then(() => {
          this.$router.push({ name: 'Login' })
        })
      }
    },
    async registerUser() {
      const customerRole = this.userRoles.find((el) => el.Name === 'Customer')
      const resUser1 = await this.userStore.getUserDataByEmail(this.contact.Email, this.token)
      var obj = {
        Email: this.contact.Email,
        Password: this.password,
        Firstname: this.contact.FirstName,
        Lastname: this.contact.LastName,
        RoleID: customerRole.ID,
      }

      const jsonString = JSON.stringify(obj)
      const base64String = btoa(unescape(encodeURIComponent(jsonString)))
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      var response = await httpPost(ApiNames.register, base64String, customConfig)
      if (response?.status === 200 && response?.data) {
        var dataObj = response.data
        this.token = dataObj
        if (this.token) {
          return true
        }
      } else if (
        response?.status === 400 &&
        response?.data.Message === 'Name ' + this.contact.Email + ' is already taken.'
      ) {
        const userExist = { error: 'userExist', success: false }
        await this.updateUser()
      } else {
        return false
      }
    },
    async updateUser() {
      const resUser = await this.userStore.getUserDataByEmail(this.contact.Email, this.token)
      const now = this.companyStore.getNow()

      if (resUser.status === 200) {
        let user = resUser.data[0]
        user.ContactID = this.contact.ID
        user.Verified = true
        user.VerifiedDate = now

        var resUpdate = await this.userStore.updateUserWithContactID(user, this.token)

        if (resUpdate === 200) {
          Swal.fire({
            title: this.$t('success'),
            text: this.$t('confirmContactText'),
            icon: 'success',
          }).then(() => {
            this.login()
          })
        }
      }
    },
    async login() {
      this.loader = true
      this.wrongPw = false
      this.userStore.createDefaultUserData()
      this.companyStore.resetCompanyStore()
      this.configurationProductLevelStore.resetConfigurationProductLevelStore()
      // this.managementStore.resetManagementStore()
      const loginUser = await this.userStore.loginUser(this.contact.Email, this.password)

      this.loader = false

      if (this.userStore.valid) {
        this.loader = true
        var res = await this.managementStore.getPermissionsByRoleID(this.userStore.roleID, this.userStore.token)
        this.loader = false
        if (res.status === 200) {
          res.data.forEach((el) => {
            this.userStore.permissions.push(el.PermissionID)
          })
        }
      }

      if (this.userStore.valid && this.userStore.tfa.Enabled) {
        this.$router.push({ name: 'Enter Tfa Code' })
        return
      }

      if (this.userStore.valid && this.userStore.role.includes('Student')) {
        this.$router.push({ name: 'My Products' })
      } else if (this.userStore.valid && this.userStore.role.includes('Customer')) {
        this.$router.push({ name: 'Company Products' })
      } else if (this.userStore.valid && this.userStore.role.includes('Admin')) {
        this.$router.push({ name: 'Companies' })
      } else if (this.userStore.valid && this.userStore.role.includes('Licenser')) {
        this.$router.push({ name: 'User' })
      }

      if (loginUser === 'unknown username') {
        Swal.fire({
          icon: 'error',
          html: this.$t('unknownUsername'),
          animation: false,
          showConfirmButton: true,
        })
      }
      if (loginUser === 'wrong password') {
        Swal.fire({
          icon: 'error',
          html: this.$t('wrongPassword'),
          animation: false,
          showConfirmButton: true,
        })
        this.wrongPw = true
      }
      if (loginUser === 'email is not confirmed') {
        Swal.fire({
          icon: 'error',
          html: this.$t('confirmEmail'),
          animation: false,
          showConfirmButton: true,
        })
        this.showResendMail = true
      }
      if (loginUser === 'user disabled') {
        Swal.fire({
          icon: 'error',
          html: this.$t('userDisabled'),
          animation: false,
          showConfirmButton: true,
        })
      }
      if (loginUser === 'error tfa') {
        Swal.fire({
          icon: 'error',
          html: this.$t('errorTfa'),
          animation: false,
          showConfirmButton: true,
        })
      }
      if (loginUser === 'user locked out') {
        Swal.fire({
          icon: 'warning',
          html: this.$t('userLockedOut'),
          animation: false,
          showConfirmButton: true,
        })
      }
      if (loginUser === 'attemps left') {
        Swal.fire({
          icon: 'warning',
          html: this.$t('userLockedOut'),
          animation: false,
          showConfirmButton: true,
        })
      }
    },
  },
  data() {
    return {
      renderKey: 0,
      loader: false,
      user: '',
      password: '',
      confirmation: '',
      contact: {},
      regExValid: /.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[&"'(\-_)=~#{[|`\\^@\]}^$*¨£µ%,;:!?./§+]).*/,
      MyMdesignLogo: './../img/my_mdesign_white.svg',
      wrongPw: false,
      token: '',
      userRoles: [],
    }
  },
}
</script>

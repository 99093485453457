<template>
  <div class="wrapper">
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      |
      <template slot="links">
        <mobile-menu></mobile-menu>
        <!-- <span class="tooltip">
          <sidebar-item
            :link="{
              name: $t('orders'),
              icon: 'shopping_cart',
              path: '/orders',
            }"
            v-if="!roleStudent"
          ></sidebar-item>
          <span class="tooltiptext">{{ $t('orders') }}</span>
        </span> -->
        <li v-if="!$sidebar.showSidebar && !roleStudent" class="tooltip">
          <span class="toolt">
            <a
              @click="showProductMenuFunction"
              :class="{
                'nav-link product-menu': true,
                'hover-effect': $sidebar.showProductMenu,
                'product-menu-open': productURL,
              }"
            >
              <md-icon :id="listIconStyle">list</md-icon>
              <span class="tooltiptext">{{ $t('productMenu') }}</span></a
            >
          </span>
        </li>
        <sidebar-item
          v-if="$sidebar.showSidebar && !roleStudent"
          :link="{
            name: $t('productsData'),
            icon: 'list',
            disableCollapse: true,
          }"
        >
          <sidebar-item v-if="roleStudent" :link="{ name: $t('myproducts'), path: '/products/myproducts' }" />
          <sidebar-item
            :link="{
              name: $t('companyProducts'),
              path: '/products/companyproducts',
            }"
            v-if="allow('{1a0be966-f650-45a4-a8b6-2f4aa41f55ca}')"
          />
          <sidebar-item
            v-if="allow('{9867a942-1704-4b2c-ad63-b369d0405856}')"
            :link="{ name: $t('allProducts'), path: '/products/allproducts' }"
          />
        </sidebar-item>
        <span class="tooltip">
          <sidebar-item
            :link="{
              name: $t('studentList'),
              icon: 'school',
              path: '/contacts',
            }"
            v-if="allow('{367b3416-5022-4a41-bb19-e4c4dd51ddb3}')"
          ></sidebar-item>
          <span class="tooltiptext">{{ $t('studentList') }}</span>
        </span>
        <span class="tooltip">
          <sidebar-item
            :link="{
              name: $t('companyList'),
              icon: 'factory',
              path: '/companies',
            }"
            v-if="allow('{4e2393ce-bbd5-4347-a68d-e8869353c7b2}')"
          ></sidebar-item>
          <span class="tooltiptext">{{ $t('companyList') }}</span>
        </span>
        <span class="tooltip">
          <sidebar-item
            :link="{
              name: $t('productsData'),
              icon: 'list',
              path: '/products/myproducts',
            }"
            v-if="roleStudent"
          ></sidebar-item>
          <span class="tooltiptext">{{ $t('myproducts') }}</span>
        </span>
        <span class="tooltip">
          <sidebar-item
            :link="{
              name: $t('userPassword'),
              icon: 'lock_outline',
              path: '/password',
            }"
          ></sidebar-item>
          <span class="tooltiptext">{{ $t('userPassword') }}</span>
        </span>
        <span class="tooltip">
          <sidebar-item
            :link="{
              name: $t('roles'),
              icon: 'vpn_key',
              path: '/management/rolestable',
            }"
            v-if="roleAdmin"
          ></sidebar-item>
          <span class="tooltiptext">{{ $t('roles') }}</span>
        </span>
        <div @click="Logout()" class="logout-style tooltip">
          <sidebar-item :link="{ name: $t('logout'), icon: 'logout', path: '/login' }"></sidebar-item>
          <span class="tooltiptext">{{ $t('logout') }}</span>
        </div>
      </template>
    </side-bar>

    <template>
      <transition name="fade">
        <side-bar-second
          v-if="$sidebar.showProductMenu"
          :active-color="sidebarBackground"
          :background-image="sidebarBackgroundImage"
          :data-background-color="sidebarBackgroundColor"
        >
          <template slot="links">
            <sidebar-item v-if="roleStudent" :link="{ name: $t('myproducts'), path: '/products/myproducts' }" />
            <sidebar-item
              :link="{
                name: $t('companyProducts'),
                path: '/products/companyproducts',
              }"
              v-if="allow('{1a0be966-f650-45a4-a8b6-2f4aa41f55ca}')"
            />
            <sidebar-item
              v-if="allow('{9867a942-1704-4b2c-ad63-b369d0405856}')"
              :link="{ name: $t('allProducts'), path: '/products/allproducts' }"
            />
          </template>
        </side-bar-second>
      </transition>
    </template>

    <div class="main-panel" :main-panel-background="sidebarBackgroundColor">
      <top-navbar
        :data-background-color="sidebarBackgroundColor"
        :class="{ 'transition-content': $sidebar.showProductMenu, 'top-nav-bar-style': true, 'navbar-dashboard': true }"
      ></top-navbar>
      <div
        :class="{
          content: !$route.meta.hideContent,
          'transition-content': $sidebar.showProductMenu,
        }"
        @click="toggleSidebar"
        :data-background-color="sidebarBackgroundColor"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer
        v-if="!$route.meta.hideFooter"
        :theme-color="sidebarBackgroundColor"
        :class="{ 'transition-content': $sidebar.showProductMenu, 'footer-position': true }"
      ></content-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`, {
      wheelSpeed: 0.1, // Adjust this value to slow down the mouse wheel speed
    })
    document.getElementsByClassName(className)[0].scrollTop = 0
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export function reinitScrollbar() {
  let docClasses = document.body.classList
  let isWindows = navigator.platform.startsWith('Win')
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar('main-panel .content')
    initScrollbar('sidebar')
    initScrollbar('main-panel')
    docClasses.add('perfect-scrollbar-on')
  } else {
    docClasses.add('perfect-scrollbar-off')
  }
}

import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import { ZoomCenterTransition } from 'vue2-transitions'
import MobileMenu from '../../components/MobileMenu/MobileMenu.vue'
import { useUserStore } from '../../stores/user'
import { useCompanyStore } from '../../stores/company'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import { useManagementStore } from '@/stores/management'
import { permissionMixin } from '../RoleManagement/permissionMixin'

export default {
  mixins: [permissionMixin],
  components: {
    TopNavbar,
    ContentFooter,
    ZoomCenterTransition,
    MobileMenu,
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const managementStore = useManagementStore()
    return { userStore, companyStore, configurationProductLevelStore, managementStore }
  },
  data() {
    return {
      sidebarBackgroundColor: this.userStore.theme,
      sidebarBackground: 'purple',
      sidebarBackgroundImage: '',
      sidebarMini: true,
      roleStudent: this.userStore.role.includes('Student'),
      roleAdmin: this.userStore.role.includes('Admin'),
      //&& !this.userStore.role.includes('Admin') || this.userStore.role.includes('User'),
      productURL: false,
      listIconStyle: '',
      companyContact: this.userStore.userData.CompanyID !== 'NULL',
    }
  },
  methods: {
    showProductMenuFunction() {
      this.$sidebar.showProductMenu = !this.$sidebar.showProductMenu
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    Logout() {
      this.userStore.valid = false
      this.userStore.createDefaultUserData()
      this.companyStore.resetCompanyStore()
      this.configurationProductLevelStore.resetConfigurationProductLevelStore()
      this.managementStore.resetManagementStore()
    },
  },
  updated() {
    reinitScrollbar()
  },
  mounted() {
    reinitScrollbar()
    const myBody = document.body
    myBody.setAttribute('data-background-color', this.sidebarBackgroundColor)
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar()
    },
    'userStore.theme': function (newTheme, oldTheme) {
      this.sidebarBackgroundColor = newTheme
      const myBody = document.body
      myBody.setAttribute('data-background-color', this.sidebarBackgroundColor)
    },
    $route(newURL, oldURL) {
      if (newURL.path.includes('/products')) {
        this.productURL = true
        this.listIconStyle = 'custom-icon-color'
      } else {
        this.productURL = false
        this.listIconStyle = ''
      }
      const element = document.querySelector('.content')
      const elementX = element.getBoundingClientRect().left + window.scrollX
      if (this.$sidebar.showProductMenu && elementX < 250) {
        const contentToMove = document.querySelector('.main-panel')
        contentToMove.scrollLeft -= 250
      }
    },
  },
}
</script>

<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

a.nav-link {
  width: 40px;
  height: 40px;
  padding: 5px;
}

.hover-effect {
  background: rgba(200, 200, 200, 0.2);
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 1s; // Adjust the duration as needed
}

.fade-enter,
.fade-leave-to {
  transform: translateX(-100%);
}

.transition-content {
  transform: translateX(240px) !important;
  transition: transform 1s !important;
  width: calc(100% - 250px);
}

.product-menu-open,
.product-menu-open:hover {
  background-color: #009ee3;

  &.hover-effect {
    background-color: #009ee3 !important;
  }

  #custom-icon-color {
    color: white !important;
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    display: none;
    position: fixed;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 100;
    left: 70px;
    font-size: 13px;
  }

  &:hover {
    .tooltiptext {
      display: block;
    }
  }
}

.tooltip a {
  margin: 10px 10px 0px !important;
}

a.product-menu:hover {
  background: rgba(200, 200, 200, 0.2);
}

.main-panel > .footer-position {
  position: relative !important;
  top: 26px;
  width: 100%;

  a {
    color: white !important;

    &:hover,
    &:focus {
      text-decoration: none;
      color: #009ee3 !important;
    }
  }
}
.top-nav-bar-style {
  width: 100%;
}
.navbar-dashboard.md-toolbar {
  z-index: unset !important;
}
</style>

<template>
  <div class="">
    <loader :loader="loader" />
    <div class="md-layout-item md-size-100 no-pad">
      <student-daylie-chart
        :chart-data="dailySalesChart.data"
        :chart-options="dailySalesChart.options"
        chart-type="Line"
        chart-inside-header
        background-color="primary"
        :key="renderKey"
      >
        <md-button @click="refreshData" class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('refresh') }}</md-tooltip>
        </md-button>
        <!-- <md-button class="md-simple md-just-icon" slot="second-button">
        <md-icon>edit</md-icon>
        <md-tooltip md-direction="bottom">Change Date</md-tooltip>
      </md-button> -->

        <template slot="content">
          <h4 class="title">{{ $t('lastDaysRegistration') }}</h4>
          <p class="category">
            {{ $t('today') }}
            <span class="text-success"> <animated-number :value="todaysOrdering"></animated-number> </span>
            {{ $t('registrations') }}
          </p>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            {{ $t('timeTextUpdated') + ' ' }}<animated-number :value="elapsedTime" :key="elapsedTime"></animated-number
            >{{ ' ' + (timeTextHours ? $t('timeTextHours') : $t('timeTextMinutes')) }}
          </div>
        </template>
      </student-daylie-chart>
    </div>
    <div class="md-layout-item md-size-100 no-pad">
      <chart-card
        header-animation="true"
        :chart-data="emailsSubscriptionChart.data"
        :chart-options="emailsSubscriptionChart.options"
        :chart-responsive-options="emailsSubscriptionChart.responsiveOptions"
        chart-type="Bar"
        chart-inside-header
        background-color="primary"
        :key="renderKeyMonth"
      >
        <md-button @click="decreaseYear" class="md-simple md-just-icon" slot="first-button">
          <md-icon>remove</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('previousYear') }}</md-tooltip>
        </md-button>

        <md-button @click="refreshMonthly" class="md-simple md-info md-just-icon" slot="second-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('refresh') }}</md-tooltip>
        </md-button>

        <md-button @click="increaseYear" class="md-simple md-just-icon" slot="third-button">
          <md-icon>add</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('nextYear') }}</md-tooltip>
        </md-button>

        <template slot="content">
          <h4 class="title">{{ $t('registrationsOfYear') + ' ' + selectedYear }}</h4>

          <p class="category">
            {{ $t('inTotal') + ' ' }}<animated-number :value="registrationsPerYear"></animated-number>
          </p>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            {{ $t('timeTextUpdated') + ' '
            }}<animated-number :value="elapsedTimeMonthly" :key="elapsedTimeMonthly"></animated-number
            >{{ ' ' + (timeTextHoursMonthly ? $t('timeTextHours') : $t('timeTextMinutes')) }}
          </div>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-size-100 no-pad">
      <stats-card header-color="primary" :key="renderKeyMonth">
        <template slot="header">
          <div class="card-icon">
            <md-icon>store</md-icon>
          </div>
          <p class="category">{{ $t('revenue') }}</p>
          <h3 class="title">€ <animated-number :value="yearRev"></animated-number></h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            {{ $t('inYear') + ' ' }}<animated-number :value="parseInt(selectedYear)"></animated-number>
          </div>
        </template>
      </stats-card>
    </div>
  </div>
</template>

<script>
import { useManagementStore } from '@/stores/management'
import { useUserStore } from '@/stores/user'
import StudentDaylieChart from '../../components/Cards/StudentDaylieChart.vue'
import AnimatedNumber from '@/components/AnimatedNumber.vue'
import ChartCard from '@/components/Cards/ChartCard.vue'
import StatsCard from '@/components/Cards/StatsCard.vue'
import Swal from 'sweetalert2'

export default {
  components: { StudentDaylieChart, AnimatedNumber, ChartCard, StatsCard },
  data() {
    return {
      renderKey: 0,
      renderKeyMonth: 0,
      loader: false,
      dailySalesChart: {},
      emailsSubscriptionChart: {
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 100,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            },
          ],
        ],
      },
      salesToday: 0,
      timeObject: null,
      timeObjectMonthly: null,
      timeTextHours: false,
      timeTextHoursMonthly: false,
      elapsedTime: 0,
      elapsedTimeMonthly: 0,
      todaysOrdering: 0,
      selectedYear: '2024',
      registrationsPerYear: 0,
      yearRev: 0,
    }
  },
  async mounted() {
    this.refreshData()
    await this.refreshMonthly()
    this.registrationsPerYear = this.managementStore.registrationsPerYear
    const date = new Date()
    const fullYear = date.getFullYear()
    this.selectedYear = fullYear.toString()
    this.yearRev = this.managementStore.yearRev
    this.renderKey++
    this.renderKeyMonth++
  },
  watch: {
    async selectedYear(newValue) {
      await this.refreshMonthly()
      // this.managementStore.getMonthlyOrderings(newValue)
      this.emailsSubscriptionChart.data.series = this.managementStore.emailsSubscriptionChart.data.series
      this.emailsSubscriptionChart.options.high = this.managementStore.emailsSubscriptionChart.options.high
      this.registrationsPerYear = this.managementStore.registrationsPerYear
      this.yearRev = this.managementStore.yearRev
      this.renderKeyMonth++
    },
    timeObject() {
      if (!this.timeObject) return 0
      if (this.timeObject.hours > 0) this.elapsedTime = this.timeObject.hours
      else this.elapsedTime = this.timeObject.minutes
    },
    timeObjectMonthly() {
      if (!this.timeObjectMonthly) return 0
      if (this.timeObjectMonthly.hours > 0) this.elapsedTimeMonthly = this.timeObjectMonthly.hours
      else this.elapsedTimeMonthly = this.timeObjectMonthly.minutes
    },
  },
  async beforeMount() {
    this.loader = true
    this.dailySalesChart = this.managementStore.dailySalesChart
    this.dailySalesChart.options = {
      lineSmooth: this.$Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      low: 0,
      // high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    }
    this.emailsSubscriptionChart.data = this.managementStore.emailsSubscriptionChart.data

    this.saveCurrentTime()
    this.calculateElapsedTime()
    this.todaysOrdering = this.managementStore.salesToday

    this.loader = false
  },
  methods: {
    async refreshData() {
      var res = await this.managementStore.refreshDailyRegistrations(this.userStore.token)
      if (res === '200') {
        this.saveCurrentTime()
        this.calculateElapsedTime()
        this.renderKey++
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res,
        })
      }
    },
    saveCurrentTime() {
      this.savedTime = new Date(this.managementStore.updatedDailySales).getTime()
      this.savedTimeMonthly = new Date(this.managementStore.updatedMonthlyRegister).getTime()
      // Set a timeout to calculate elapsed time after 5 seconds
      this.intervalId = setInterval(this.calculateElapsedTime, 60000)
    },
    calculateElapsedTime() {
      if (this.managementStore.updatedDailySales) {
        const currentTime = new Date().getTime()
        const elapsedMilliseconds = currentTime - this.savedTime
        this.timeObject = this.convertMilliseconds(elapsedMilliseconds)
        if (this.timeObject.hours > 0) this.timeTextHours = true
        else this.timeTextHours = false
      }
      if (this.managementStore.updatedMonthlyRegister) {
        const currentTime = new Date().getTime()
        const elappsedMsMonthly = currentTime - this.savedTimeMonthly
        this.timeObjectMonthly = this.convertMilliseconds(elappsedMsMonthly)
        if (this.timeObjectMonthly.hours > 0) this.timeTextHoursMonthly = true
        else this.timeTextHoursMonthly = false
      }
    },
    convertMilliseconds(ms) {
      const seconds = Math.floor((ms / 1000) % 60)
      const minutes = Math.floor((ms / (1000 * 60)) % 60)
      const hours = Math.floor((ms / (1000 * 60 * 60)) % 24)
      return { hours, minutes, seconds }
    },
    decreaseYear() {
      this.selectedYear--
    },
    increaseYear() {
      this.selectedYear++
    },
    async refreshMonthly() {
      var res = await this.managementStore.refreshMonthlyRegistrations(this.userStore.token, this.selectedYear)
      if (res !== '200') {
        Swal.fire({
          icon: 'error',
          title: this.$('error'),
          html: res,
        })
        return
      }
      this.saveCurrentTime()
      this.calculateElapsedTime()
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId) // Clear the interval when the component is destroyed
  },
  setup() {
    const managementStore = useManagementStore()
    const userStore = useUserStore()
    return { managementStore, userStore }
  },
}
</script>
<style scoped lang="scss">
.pad-lr {
  padding-left: 5px !important;
}
.no-pad {
  padding: 0;
}
</style>

<template>
  <div class="protocol-content">
    <loader :loader="loader" />
    <md-card class="md-card-configuration-protocol">
      <md-card-header class="md-card-header-icon md-card-header-primary">
        <div class="card-icon">
          <md-icon>content_paste</md-icon>
        </div>
        <h4 class="">{{ $t('protocol') }}</h4>
      </md-card-header>
      <md-card-content>
        <div class="controls">
          <md-switch v-model="showAll">{{ $t('showAll') }}</md-switch>
        </div>
        <div class="md-layout protocol-table">
          <div class="md-layout md-layout-item md-size-100">
            <div class="md-layout-item md-size-35">{{ $t('Name') }}</div>
            <div class="md-layout-item md-size-25">ID</div>
            <div class="md-layout-item md-size-10">{{ $t('count') }}</div>
            <div class="md-layout-item md-size-10">{{ $t('endDate') }}</div>
            <div class="md-layout-item md-size-20"></div>
          </div>

          <div
            class="md-layout md-layout-item md-size-100"
            v-for="p in licSelected"
            :key="p.ConfigurationHasProductLevelOne.ID"
          >
            <div class="md-layout md-layout-item md-size-100 protocol-header">
              <div
                class="md-layout-item md-size-35"
                :class="{ dependency: p.isDependency, hide: p.isDependency && !showAll }"
              >
                {{ p.ProductLevelOne.Name }}
              </div>
              <div
                class="md-layout-item md-size-25"
                :class="{ dependency: p.isDependency, hide: p.isDependency && !showAll }"
              >
                {{
                  (p.ProductLevelOne.LicName !== '' ? p.ProductLevelOne.LicName : p.ProductLevelOne.LicNameParent) +
                  version
                }}
              </div>
              <div
                class="md-layout-item md-size-10"
                :class="{ dependency: p.isDependency, hide: p.isDependency && !showAll }"
              >
                {{
                  p.ConfigurationHasProductLevelOne.Count === 9999
                    ? 'uncounted'
                    : p.ConfigurationHasProductLevelOne.Count
                }}
              </div>
              <div
                class="md-layout-item md-size-20"
                :class="{ dependency: p.isDependency, hide: p.isDependency && !showAll }"
              >
                {{
                  p.ConfigurationHasProductLevelOne.Limited.includes('9999-12-31')
                    ? $t('unlimited')
                    : formateDate(p.ConfigurationHasProductLevelOne.Limited.split('T')[0])
                }}
              </div>
              <div
                class="md-layout-item md-size-10"
                :class="{ dependency: p.isDependency, hide: p.isDependency && !showAll }"
              >
                {{
                  `${p.ConfigurationHasProductLevelOne.Connect === 1 ? 'Connect' : ''} ${
                    p.ConfigurationHasProductLevelOne.EditModules === 1 ? 'Module bearbeiten' : ''
                  }`
                }}
              </div>
            </div>

            <div class="md-layout md-layout-item md-size-100" v-if="p.ListProtocolLevelTwo.length > 0">
              <div
                class="md-layout md-layout-item md-size-100"
                :class="{
                  'block-end':
                    l2.ListProtocolLevelThree.length === 0 &&
                    l2.ProductLevelTwo.LicName.includes('GROUP') &&
                    !l2.isDependency,
                  hide: (l2.isDependency || l2.deSelected) && !showAll,
                  'is-feature': !l2.ProductLevelTwo.LicName.includes('GROUP') && !l2.isDependency,
                }"
                v-for="l2 in p.ListProtocolLevelTwo"
                :key="l2.ConfigurationHasProductLevelTwo.ID"
              >
                <div
                  v-if="p.ListProtocolLevelTwo.length > 1"
                  class="md-layout-item md-size-35 fs-italic"
                  :class="{ dependency: l2.isDependency, deSelected: l2.deSelected }"
                >
                  {{
                    `${l2.ConfigurationHasProductLevelTwo.Sorting.toString().padStart(2, '0')}. ${
                      l2.ProductLevelTwo.Name
                    }`
                  }}
                </div>

                <div
                  v-else
                  class="md-layout-item md-size-35 fs-italic"
                  :class="{ dependency: l2.isDependency, deSelected: l2.deSelected }"
                >
                  {{ `${l2.ProductLevelTwo.Name}` }}
                </div>

                <div class="md-layout-item md-size-25" :class="{ dependency: l2.isDependency }">
                  {{ l2.ProductLevelTwo.LicName }}
                </div>
                <div class="md-layout-item md-size-10" :class="{ dependency: l2.isDependency }">
                  {{
                    l2.ConfigurationHasProductLevelTwo.Count === 9999
                      ? 'uncounted'
                      : l2.ConfigurationHasProductLevelTwo.Count
                  }}
                </div>
                <div class="md-layout-item md-size-20" :class="{ dependency: l2.isDependency }">
                  {{
                    l2.ConfigurationHasProductLevelTwo.Limited.includes('9999-12-31')
                      ? $t('unlimited')
                      : formateDate(l2.ConfigurationHasProductLevelTwo.Limited.split('T')[0])
                  }}
                </div>
                <div class="md-layout-item md-size-10"></div>
                <div
                  class="md-layout md-layout-item md-size-100 module-block"
                  v-if="l2.ListProtocolLevelThree.length > 0 && l2.ConfigurationHasProductLevelTwo.Count > 0"
                >
                  <div
                    class="md-layout md-layout-item md-size-100"
                    :class="{ hide: (l3.isDependency || l3.deSelected) && !showAll }"
                    v-for="l3 in l2.ListProtocolLevelThree"
                    :key="l3.ConfigurationHasProductLevelThree.ID"
                  >
                    <div
                      class="md-layout-item md-size-35 pl-5"
                      :class="{
                        dependency: l3.isDependency,
                        deSelected: l3.deSelected,
                      }"
                    >
                      {{ '- ' + l3.ProductLevelThree.Name }}
                    </div>
                    <div class="md-layout-item md-size-25" :class="{ dependency: l3.isDependency }">
                      {{ l3.ProductLevelThree.LicName }}
                    </div>
                    <div class="md-layout-item md-size-10" :class="{ dependency: l3.isDependency }">
                      {{
                        l3.ConfigurationHasProductLevelThree.Count === 9999
                          ? 'uncounted'
                          : l3.ConfigurationHasProductLevelThree.Count
                      }}
                    </div>
                    <div class="md-layout-item md-size-20" :class="{ dependency: l3.isDependency }">
                      {{
                        l3.ConfigurationHasProductLevelThree.Limited.includes('9999-12-31')
                          ? $t('unlimited')
                          : formateDate(l3.ConfigurationHasProductLevelThree.Limited.split('T')[0])
                      }}
                    </div>
                    <div class="md-layout-item md-size-10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'

export default {
  components: {},
  data() {
    return {
      loader: false,
      version: '',
      licSelected: [],
      showAll: false,
    }
  },
  async beforeMount() {
    const token = this.userStore.token
    const configurationID = this.configurationProductLevelStore.protocolData.ConfigurationID
    const productID = this.configurationProductLevelStore.product.ID
    await this.getProtocolComplete(configurationID, productID, token)
    this.version = this.configurationProductLevelStore.product.LicName
  },
  methods: {
    async getProtocolComplete(configurationID, productID, token) {
      const resProtocolComplete = await this.configurationProductLevelStore.getProtocolComplete(
        configurationID,
        productID,
        token,
      )

      this.licSelected = []
      resProtocolComplete.data.forEach((item) => {
        if (item.ShowIt) {
          item.isDependency = false
          this.licSelected.push(item)
        } else {
          item.isDependency = true
          this.licSelected.push(item)
        }
      })

      this.licSelected.forEach((level_1) => {
        level_1.ListProtocolLevelTwo.forEach((level_2) => {
          if (level_1.isDependency) level_2.isDependency = true
          else if (level_2.ConfigurationHasProductLevelTwo.Count < 1) level_2.deSelected = true
          if (level_2.ListProtocolLevelThree.length > 0) {
            level_2.ListProtocolLevelThree.forEach((level_3) => {
              if (level_2.isDependency) level_3.isDependency = true
              else if (level_3.ConfigurationHasProductLevelThree.Count < 1) level_3.deSelected = true
            })
          }
        })
      })
      this.licSelected.sort(
        (a, b) => a.ConfigurationHasProductLevelOne.Sorting - b.ConfigurationHasProductLevelOne.Sorting,
      )
      this.licSelected.forEach((level_1) => {
        level_1.ListProtocolLevelTwo.sort(
          (a, b) => a.ConfigurationHasProductLevelTwo.Sorting - b.ConfigurationHasProductLevelTwo.Sorting,
        )
        level_1.ListProtocolLevelTwo.forEach((level_2) => {
          level_2.ListProtocolLevelThree.sort(
            (a, b) => a.ConfigurationHasProductLevelThree.Sorting - b.ConfigurationHasProductLevelThree.Sorting,
          )
        })
      })
    },
    formateDate(input) {
      const [year, month, day] = input.split('-')
      const formattedDate = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
      return formattedDate
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    return { companyStore, userStore, configurationProductLevelStore }
  },
}
</script>
<style scoped lang="scss">
.md-layout-item {
  padding: 0;
}
.fs-italic {
  font-style: italic;
}
.protocol-header {
  font-weight: bold;
}
[data-background-color='black'] {
  .protocol-header {
    background: lightslategray;
  }
  .module-block,
  .block-end {
    border-bottom: 1px solid white;
  }
}
[data-background-color='white'] {
  .protocol-header {
    background: rgb(211, 211, 211);
  }
  .module-block,
  .block-end {
    border-bottom: 1px solid black;
  }
}
.dependency {
  color: red !important;
}
.hide {
  display: none !important;
  border: 0 !important;
}
.pl-5,
.is-feature {
  padding-left: 5px;
}
.protocol-content {
  display: flex;
  justify-content: center;
}
.md-card-configuration-protocol {
  max-width: 1000px;
}
.deSelected {
  text-decoration: line-through !important;
}
</style>

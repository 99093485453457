import axios from 'axios'
import { ApiNames, httpGetAuth } from '@/libs/httpHelper'

export async function httpGetShopUrl(companyID, productID, contactID, contactEmail, token) {
  const config = { Authorization: `Bearer ${token}` }
  let ProductCodeExtern
  await (async function getProductInfo() {
    var response = await httpGetAuth(
      ApiNames.product,
      {
        id: productID,
      },
      {},
      token,
    )
    if (response.data.length) {
      ProductCodeExtern = response.data[0].ProductCodeExtern
    }
  })()
  if (ProductCodeExtern)
    try {
      const apiUrl = 'https://my.mdesign.de/shop/api/shopping-url'
      let data = {
        Merchant: 'TEDATAGM',
        Prod: ProductCodeExtern,
        Email: contactEmail,
        CustomFieldsName: 'MDESIGN',
        CustomFieldsValue: `com~${companyID}|pro~${productID}|con~${contactID}`,
        Testorder: 0,
        ReturnURL: window.location.href,
      }
      return await axios
        .post(apiUrl, data, config)
        .then((response) => {
          return response
        })
        .catch((error) => {
          return error.response
        })
    } catch (error) {
      return undefined
    }
}

import { useUserStore } from '@/stores/user'
import { useManagementStore } from '@/stores/management'

export const permissionMixin = {
  async created() {
    if (this.userStore.permissions.length === 0) {
      var res = await this.managementStore.getPermissionsByRoleID(this.userStore.roleID, this.userStore.token)
      if (res.status === 200) {
        res.data.forEach((el) => {
          this.userStore.permissions.push(el.PermissionID)
        })
      }
    }
  },
  methods: {
    allow(key) {
      if (this.userStore.permissions.includes(key)) return true
      else return false
    },
  },
  setup() {
    const userStore = useUserStore()
    const managementStore = useManagementStore()
    return { userStore, managementStore }
  },
}

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="modalWidthClass" v-click-outside="closeModal">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body text-center">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    modalWidth: {
      type: String,
      default: null,
    },
  },
  computed: {
    modalWidthClass() {
      return this.modalWidth ? `modal-width-${this.modalWidth}` : ''
    },
  },
  methods: {
    closeModal: function () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

[data-background-color='black'] {
  div.modal-footer {
    .md-button {
      &.md-simple {
        color: white !important;
      }
    }
  }
}
.list-item {
  cursor: pointer;
}
.modal-width-400 {
  width: 400px !important;
  max-width: none !important;
}

.modal-width-600 {
  width: 600px !important;
  max-width: none !important;
}

.modal-width-800 {
  width: 800px !important;
  max-width: none !important;
}
.modal-width-1200 {
  width: 1200px !important;
  max-width: none !important;
}
</style>

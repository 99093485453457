<template>
  <div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Comparison of versions</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableDataStriped" class="table-striped">
            <md-table-row slot="md-table-row" slot-scope="{ item }" md-auto-select>
              <md-table-cell md-label="#">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Product Name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Student NEW">
                <span class="material-icons">
                  {{ item.studentNew }}
                </span></md-table-cell
              >
              <md-table-cell md-label="Student Old"
                ><span class="material-icons">
                  {{ item.studentOld }}
                </span></md-table-cell
              >
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      trueIcon: 'done',
      falseIcon: 'remove',
      renderKey: 0,
      tableDataStriped: [],
    }
  },
  methods: {},
  created() {
    this.tableDataStriped = [
      {
        id: 1,
        name: 'Moleskine Agenda',
        studentNew: this.falseIcon,
        studentOld: this.trueIcon,
      },
      {
        id: 2,
        name: 'Stabilo Pen',
        studentNew: this.trueIcon,
        studentOld: this.trueIcon,
      },
      {
        id: 3,
        name: 'A4 Paper Pack',
        studentNew: this.trueIcon,
        studentOld: this.trueIcon,
      },
      {
        id: 4,
        name: 'Apple Ipad',
        studentNew: this.trueIcon,
        studentOld: this.trueIcon,
      },
      {
        id: 5,
        name: 'Apple Iphone',
        studentNew: this.trueIcon,
        studentOld: this.trueIcon,
      },
    ]
  },
}
</script>
<style lang="scss" scoped>
.material-icons {
  display: flex;
  justify-content: center;
  color: #a9afbb !important;
}
</style>

<template>
  <div class="md-layout">
    <loader :loader="loader" />
    <two-fa-card header-color="primary">
      <div slot="title" header-color="primary" class="md-card-header-icon md-card-header-default">
        <img :src="logo" alt="MyMdesignLogo" class="img-title" />
      </div>
      <div slot="content" class="sucess-text md-layout">
        <h4 class="title text-center md-layout-item md-size-100">
          {{ titleText }}
        </h4>
        <p v-if="success" class="md-layout-item md-size-100">
          {{ $t('successfulRegistration') }} <a href="/login">{{ $t('link') }}</a>
        </p>
      </div>
    </two-fa-card>
  </div>
</template>

<script>
import Loader from '@/components/Loader/Loader.vue'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'
import MyMdesignLogo from './../../../public/img/my_mdesign_white.svg'
import TwoFaCard from '@/components/Cards/TwoFACard.vue'
import { httpPost, ApiNames } from '@/libs/httpHelper'
import { sendEmailTfaAuthentication } from '@/libs/emailSender'

export default {
  components: { TwoFaCard },
  data() {
    return {
      loader: false,
      logo: MyMdesignLogo,
      titleText: null,
      success: false,
    }
  },
  methods: {},

  async mounted() {
    this.loader = true
    const params = new URLSearchParams(location.search).toString()
    const tokenStr = params.split('token=')[1]
    const token = encodeURIComponent(tokenStr)

    var res = await this.userStore.resetLockout(token)

    if (res.status === 200) {
      this.titleText = this.$t('unlockAccountTitle')
      this.success = true
      setTimeout(function () {
        window.location.href = '/login'
      }, 2500)
    } else {
      this.titleText = this.$t('errorUnlockAccount')
    }

    this.loader = false
    return
  },
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
}
</script>

<style scoped lanf="scss">
.sucess-text {
  text-align: center;
  font-size: 24px;
}

.sucess-text a {
  color: #009ee3;
}
.just-center {
  display: flex;
  justify-content: center !important;
}
h4 {
  margin: 0 auto;
}
.resend-info {
  text-align: center;
  margin-bottom: 15px;
}
.remaining-time {
  margin-top: 15px;
}
[data-background-color='white'] h4 {
  color: black;
}

p {
  font-size: 16px;
}
</style>

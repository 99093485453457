<template>
  <div class="md-layout text-center" id="login-tile">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <loader :loader="loader" />
      <login-card header-color="primary">
        <img slot="title" :src="MyMdesignLogo" alt="MyMdesignLogo" class="img-title" />
        <div slot="inputs">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <form @submit.prevent="handleSubmit(submit)">
              <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t('email') }}</label>
                  <!-- v-on:click="changeType()" -->
                  <!-- V V V -->
                  <md-input v-model="email" type="text" id="email"> </md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                name="pass"
                :rules="{
                  required: true,
                  regex: regExValid,
                }"
                v-slot="{ passed, failed }"
              >
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t('password') }}</label>
                  <md-input v-model="password" type="password" v-on:keyup.enter="loginUser()" id="password"></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </form>
            <div class="forgot-password">
              <a @click="$router.push({ name: 'Aws Forgot Password' })">{{ $t('forgotPassword') }}</a>
            </div>
            <div class="cta-style">
              <!-- <md-button slot="footer" class="md-simple md-primary md-lg" id="registration-button"
                @click.native="$router.push({ name: 'Registration' })"><md-icon>how_to_reg</md-icon>
                {{ $t("registration") }}
              </md-button> -->
              <md-button slot="footer" class="md-primary md-lg" v-on:click="loginUser()" :disabled="invalid" id="Submit"
                ><md-icon>login</md-icon>
                {{ $t('letsgo') }}
              </md-button>
            </div>
          </ValidationObserver>
        </div>
      </login-card>
    </div>
  </div>
</template>

<script>
import { LoginCard } from '@/components'
import { SlideYDownTransition } from 'vue2-transitions'
import { httpGetTokenAws, httpPostAwsLogin } from '../../libs/httpHelper'
import Swal from 'sweetalert2'

export default {
  components: { LoginCard },
  data() {
    return {
      loader: false,
      email: 'simon.kissling@tedata.de',
      password: 'ASD123asd!§',
      regExValid: /.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[&"'(\-_)=~#{[|`\\^@\]}^$*¨£µ%,;:!?./§+]).*/,
      MyMdesignLogo: './../img/my_mdesign_white.svg',
      errors: [
        {
          errCode: 1,
          text: this.$t('err_user_AWS'),
        },
        {
          errCode: 2,
          text: this.$t('err_user_not_found'),
        },
        {
          errCode: 3,
          text: 'redirect to set pw',
        },
        {
          errCode: 4,
          text: this.$t('err_pass_wrong'),
        },
        {
          errCode: 5,
          text: this.$t('err_userNotBind2Stack'),
        },
        {
          errCode: 6,
          text: this.$t('err_build_url'),
        },
        {
          errCode: 7,
          text: this.$t('err_nofreecapacity'),
        },
      ],
    }
  },
  methods: {
    async loginUser() {
      const response = await httpGetTokenAws()
      if (response.status !== 200) return
      const token = response.data
      let user = {
        email: this.email,
        password: this.password,
      }
      const responseLogin = await httpPostAwsLogin(user, token)
      if (responseLogin.data.errCode > 0) {
        var msg = this.errors.find((err) => err.errCode === responseLogin.data.errCode)
        Swal.fire({
          icon: 'error',
          text: msg.text,
          animation: false,
          showConfirmButton: true,
        })
      }
    },
  },
}
</script>

<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submit)">
        <loader :loader="loader" />
        <md-card class="card-content">
          <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
            <div class="card-icon">
              <md-icon>perm_identity</md-icon>
            </div>
            <h4 class="title">
              {{ $t('contactData') }}
            </h4>
            <h3>{{ $t('editContactText') }}</h3>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('contactId') }}</label>
                  <md-input v-model="contact.ID" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('customerID') }}</label>
                  <md-input v-model="contact.CompanyID" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label for="salutation">{{ $t('salutation') }}</label>
                  <md-select v-model="contact.Salutation" name="salutation" id="salutation">
                    <md-option value="Herr">{{ $t('mr') }}</md-option>
                    <md-option value="Frau">{{ $t('ms') }}</md-option>
                    <md-option value="NoSalutation">{{ $t('noSalutaition') }}</md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('title') }}</label>
                  <md-input v-model="contact.Title" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="firstname" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('firstName') }}</label>
                      <md-input v-model="contact.FirstName" type="text"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="lastname" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('lastName') }}</label>
                      <md-input v-model="contact.LastName" type="text"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field v-if="adminRole && userFound" :key="rendeKey">
                  <label for="role">{{ $t('role') }}</label>
                  <md-select v-model="mdUser.MDUserRoleID" name="role" id="role">
                    <md-option v-for="role in userRoles" :value="role.ID" :key="role.ID">{{ role.Name }}</md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('phoneNumber') }}</label>
                  <md-input v-model="contact.Phone" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('email') }}</label>
                    <md-input v-model="contact.Email" type="email"> </md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('position') }}</label>
                  <md-input v-model="contact.Position" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <md-checkbox v-model="contact.MainContact" class="imo-checkbox">{{ $t('mainContact') }} </md-checkbox>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <select-country :value="contact.Language.toUpperCase()" @changeCountry="handleCountry"></select-country>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('createdBy') }}</label>
                  <md-input v-model="contact.CreatedBy" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('createdOn') }}</label>
                  <md-input v-model="contact.Created" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('modifiedBy') }}</label>
                  <md-input v-model="contact.ModifiedBy" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('modifiedOn') }}</label>
                  <md-input v-model="contact.Modified" type="text" disabled></md-input>
                </md-field>
              </div>
              <!-- <div class="md-layout-item md-size-50 text-right">
                <md-button class="md-raised md-primary mt-4" @click="manageProducts()">{{
                  $t("manageProducts") }}
                </md-button>
              </div> -->
              <div class="md-layout-item md-size-100 text-right">
                <md-button id="submit" class="md-raised md-primary mt-4" @click="updateContact()" :disabled="invalid"
                  >{{ $t('save') }}
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationProvider, extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import SelectCountry from '../../components/SelectCountry/SelectCountry.vue'
import { useCompanyStore } from '../../stores/company'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

export default {
  components: { SelectCountry, ValidationProvider },
  name: 'edit-contact-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    return { companyStore, userStore }
  },
  data() {
    return {
      rendeKey: 0,
      contact: {
        ID: '',
        CompanyID: '',
        Salutation: '',
        Title: '',
        FirstName: '',
        LastName: '',
        Gender: '',
        Phone: '',
        Email: '',
        Position: '',
        MainContact: false,
        Language: '',
        Image: 0,
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      adminRole: false,
      userFound: false,
      mdUser: {},
      userRoles: [],
      loader: false,
    }
  },
  async beforeMount() {
    // contact in params übergeben

    if (!this.companyStore.companyContact.ID) this.$router.push({ name: 'Company' })

    this.contact = this.companyStore.companyContact

    if (this.userStore.role === 'Administrator') {
      this.adminRole = true
      await this.getUserRoles()
      await this.getMDUserRole()
    }

    this.rendeKey++
  },
  methods: {
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    handleCountry(val) {
      this.contact.Language = val.value.toLowerCase()
    },
    // manageProducts() {
    //   this.$router.push({ name: 'Contact Products', params: { contactID: this.contact.ID } })
    // },
    async updateContact() {
      this.contact.ModifiedBy = this.userStore.userData.LastName
      this.contact.Modified = this.companyStore.formatDate(new Date())

      this.loader = true
      if (this.adminRole && this.userFound) {
        await this.saveMDUser()
      }
      const response = await this.companyStore.updateCompanyContactData(this.contact, this.userStore.token)
      this.loader = false
      if (response.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('profileUpdated'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        }).then((result) => {
          this.$router.push({
            name: 'Company',
          })
        })
      } else if (response.status === 400 && response.data.Message === 'Limit of main contacts reached') {
        Swal.fire({
          title: this.$t('mainContactLimit'),
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: response.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      }
    },
    async getUserRoles() {
      var res = await this.companyStore.getUserRoles(this.userStore.token)
      if (res.status === 200) {
        this.userRoles = res.data
      }
    },
    async getMDUserRole() {
      var res = await this.userStore.getUserDataByEmail(this.contact.Email, this.userStore.token)
      if (res.status === 200) {
        this.mdUser = res.data[0]
        this.userFound = true
      }
    },
    async saveMDUser() {
      var res = await this.userStore.updateUserWithContactID(this.mdUser, this.userStore.token)
      if (res !== 200) {
        this.loader = false
        await Swal.fire({
          title: this.$t('error'),
          html: this.$t('errorSaveRole'),
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
        this.loader = true
      }
    },
  },
}
</script>
<style scoped>
.md-card {
  width: 96%;
  margin: 0 auto;
}
.md-checkbox {
  margin-right: 0 !important;
  label {
    padding-left: 0 !important;
  }
}
</style>

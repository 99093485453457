import { defineStore } from 'pinia'
import {
  ApiNames,
  httpGetAuth,
  httpPost,
  httpGetByUrl,
  httpGetAuthFileDownload,
  httpDelete,
  httpGetAws,
  httpPostProductImage,
} from '@/libs/httpHelper'
import { useUserStore } from './user.js'
import { httpGet } from '../libs/httpHelper.js'

export const useCompanyStore = defineStore({
  id: 'companyState',
  state: () => {
    // const userStore = ref('useUserStore')
    return {
      // all these properties will have their type inferred automatically
      company: {
        ID: '',
        CompanyTypeID: '',
        CustomerNr: '',
        VATnr: '',
        Name: '',
        Street: '',
        Housenumber: '',
        ZipCode: '',
        City: '',
        Country: '',
        Phone: '',
        Fax: '',
        Email: '',
        Website: '',
        Language: '',
        Image: 0,
        ServiceContract: false,
        ServiceContractStart: '',
        ServiceContractEnd: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      companyTypes: [
        {
          ID: '',
          Name: '',
          Description: '',
        },
      ],
      productData: {
        editProduct: {},
        companyHasProduct: [
          {
            ID: '',
            ConfigurationID: '',
            CompanyID: '',
            ProductID: '',
            FileCount: 0,
          },
        ],
      },
      companyContact: {},
    }
  },
  persist: true,
  getters: {
    // ...
  },
  actions: {
    resetCompanyStore() {
      // all these properties will have their type inferred automatically
      this.company = {
        ID: '',
        CompanyTypeID: '',
        CustomerNr: '',
        VATnr: '',
        Name: '',
        Street: '',
        Housenumber: '',
        ZipCode: '',
        City: '',
        Country: '',
        Phone: '',
        Fax: '',
        Email: '',
        Website: '',
        Language: '',
        Image: 0,
        ServiceContract: false,
        ServiceContractStart: '',
        ServiceContractEnd: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      }
      this.companyTypes = [
        {
          ID: '',
          Name: '',
          Description: '',
        },
      ]
      this.productData = {
        editProduct: {},
        companyHasProduct: [
          {
            ID: '',
            ConfigurationID: '',
            CompanyID: '',
            ProductID: '',
            FileCount: 0,
          },
        ],
      }
      this.companyContact = {}
    },

    async getCompanies() {
      const userStore = useUserStore()

      var userID = userStore.userData.ID
      var token = userStore.token

      var response = await this.getCompanyTypes(token)
      if (response.status === 200) {
        this.companyTypes = response.data
      } else {
        return response
      }

      var responseCompanies = await httpGetAuth(ApiNames.companies, { id: userID }, {}, token)

      if (responseCompanies.status !== 200) {
        return responseCompanies
      } else {
        this.companies = responseCompanies.data
      }

      return responseCompanies
    },
    async getCompanyTypes(token) {
      var responseTypes = await httpGetAuth(ApiNames.companytypes, {}, {}, token)

      return responseTypes
    },
    async updateCompanyData(updatedCompanyOj) {
      const userStore = useUserStore()

      const headerConfig = {
        headers: { Authorization: `Bearer ${userStore.token}` },
      }

      var responseUpdateData = await httpPost(ApiNames.updatecompany, updatedCompanyOj, headerConfig)

      if (responseUpdateData?.status === 200) {
        return 200
      } else {
        return responseUpdateData
      }
    },
    async createCompany(dataObj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createcompany, dataObj, headerConfig)

      return response
    },
    async updateCompanyContactData(updatedContactOj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }

      var responseUpdateData = await httpPost(ApiNames.updatecompanycontact, updatedContactOj, headerConfig)

      return responseUpdateData
    },
    async createCompanyContact(dataObj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createcompanycontact, dataObj, headerConfig)

      return response
    },

    async getContactByID(contactid, token) {
      const params = { id: contactid }

      var response = await httpGetAuth(ApiNames.contact, params, {}, token)

      return response
    },

    async getContactsByCompanyID(companyId) {
      const userStore = useUserStore()
      const params = { companyID: companyId }
      const headerConfig = {
        headers: { Authorization: `Bearer ${userStore.token}` },
      }

      var response = await httpGetAuth(ApiNames.contactbycompanyid, params, {}, userStore.token)

      return response
    },
    async getProducts(token) {
      var response = await httpGetAuth(ApiNames.products, {}, {}, token)
      return response
    },
    async getProductsByCompanyID(companyID, token) {
      const params = { id: companyID }
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }

      var response = await httpGetAuth(ApiNames.companyhasproducts, params, {}, token)
      this.productData.companyHasProduct = response.data
      return response
    },

    async getCompanyHasProductByID(companyHasProductID, token) {
      const params = { id: companyHasProductID }

      var response = await httpGetAuth(ApiNames.companyhasproducts_id, params, {}, token)

      return response
    },

    async createProductToCompany(companyID, productID, token, configID = '') {
      var dataObj = {
        CompanyID: companyID,
        ConfigurationID: configID,
        ProductID: productID,
      }
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createcompanyhasproduct, dataObj, headerConfig)

      return response
    },

    async deleteProductFromCompany(companyID, productID, configurationID, token) {
      const params = {
        companyID: companyID,
        productID: productID,
        configurationID: configurationID,
      }

      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deletecompanyhasproduct, params, header)

      return response
    },

    async deleteCompanyHasProduct_ID(companyHasProdID, token) {
      const params = {
        id: companyHasProdID,
      }

      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deletecompanyhasproduct_id, params, header)

      return response
    },

    async deleteCompany(companyID, token) {
      const params = {
        id: companyID,
      }

      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(ApiNames.deletecompany, params, header)

      return response
    },

    async getProductsByContactID(contactID, token) {
      const params = { id: contactID }

      var response = await httpGetAuth(ApiNames.contacthasproducts, params, {}, token)

      return response
    },

    async getUserRoles(token) {
      const params = {}

      var response = await httpGetAuth(ApiNames.userroles, params, {}, token)

      return response
    },

    async createProductToContact(contactID, productID, token) {
      var dataObj = {
        ID: '',
        ContactID: contactID,
        ProductID: productID,
        FileCount: 0,
      }
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createcontacthasproduct, dataObj, headerConfig)
      return response
    },
    async deleteProductFromContact(contactID, productID, token) {
      const params = {
        contactID: contactID,
        productID: productID,
      }

      // const headerConfig = {    deleteProductFromContact
      //   headers: { Authorization: `Bearer ${token}` },
      // };
      const header = { Authorization: `Bearer ${token}` }
      var response = await httpDelete(
        // ApiNames.deletecompanyhasproduct, /api/deletecontacthasproduct
        ApiNames.deletecontacthasproduct,
        params,
        header,
      )

      return response
    },
    async getAllContacts(token) {
      const params = {}
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }

      var response = await httpGetAuth(ApiNames.contacts, params, {}, token)

      return response
    },

    async getFileReferenceByTable(idFromTable, tableName, token) {
      const params = {
        idFromTable: idFromTable,
        tableName: tableName,
      }
      var response = await httpGetAuth(ApiNames.filereferencebytable, params, {}, token)

      return response
    },
    async getDataFileByID(dataFileID, token) {
      const params = {
        id: dataFileID,
      }
      var response = await httpGetAuth(ApiNames.datafile, params, {}, token)

      return response
    },

    async getDownloadFile(dataFileID, isDownloadCounted, token) {
      const params = {
        id: dataFileID,
        isDownloadCounted: isDownloadCounted,
      }
      var response = await httpGetAuthFileDownload(ApiNames.downloadfile, params, {}, token)

      return response
    },

    async uploadProductImage(file, token, productID) {
      const headerConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      var formData = new FormData()

      formData.append('image', file)

      var response = await httpPostProductImage(ApiNames.uploadproductimage, productID, 'true', formData, headerConfig)

      return response
    },

    async createProduct(obj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }

      var response = await httpPost(ApiNames.createproduct, obj, headerConfig)

      return response
    },
    async updateProduct(product, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.updateproduct, product, headerConfig)

      return response
    },
    // async getAwsUser(email, token) {
    //   const params = { email: email };
    //   const headerConfig = {
    //     headers: { Authorization: `Bearer ${token}` },
    //   };
    //   var response = await httpGetAws(ApiNames.awsuser, params, headerConfig);
    //   return response;
    // },
    // async getAwsUsers(mails, token) {
    //   const params = { data: mails };
    //   const headers = { Authorization: `Bearer ${token}` };

    //   var response = await httpGetAws(ApiNames.awsalluser, params, headers);

    //   return response;
    // },
    getInOneYear() {
      const today = new Date()
      let date = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
      date = this.formatDate(date).split('T')[0]
      return date
    },
    getToday() {
      const today = new Date()
      const date = this.formatDate(today).split('T')[0]
      return date
    },
    formatDate(input) {
      let date

      if (input instanceof Date) {
        date = input
      } else if (input.includes('.')) {
        const dayParts = input.split('.')
        if (dayParts.length !== 3) {
          throw new Error('Invalid date string')
        }
        const dayPart = parseInt(dayParts[0], 10)
        const monthPart = parseInt(dayParts[1], 10) - 1
        const yearPart = parseInt(dayParts[2], 10)
        date = new Date(Date.UTC(yearPart, monthPart, dayPart))
      } else if (typeof input === 'string') {
        date = new Date(input)
      } else {
        throw new Error('Invalid input type')
      }

      if (isNaN(date)) {
        throw new Error('Invalid date string')
      }

      const year = date.getUTCFullYear()
      const month = String(date.getUTCMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getUTCHours()).padStart(2, '0')
      const minutes = String(date.getUTCMinutes()).padStart(2, '0')
      const seconds = String(date.getUTCSeconds()).padStart(2, '0')

      const offset = -date.getTimezoneOffset()
      const offsetHours = String(Math.floor(offset / 60)).padStart(2, '0')

      const offhours = String(parseInt(hours) + parseInt(offsetHours)).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}T${offhours}:${minutes}:${seconds}`

      return formattedDate
    },
    getNow() {
      const date = new Date()

      const germanyOffset = 2 * 60 * 60 * 1000
      const germanyTime = new Date(date.getTime() + germanyOffset)

      const isoString = germanyTime.toISOString().split('.')[0]
      return isoString
    },
    getReadableDate(d) {
      var date = new Date(d)

      // Get day, month, and year components
      var day = date.getDate()
      var month = date.getMonth() + 1 // Months are zero-based
      var year = date.getFullYear()

      // Add leading zeros if needed
      var formattedDay = day < 10 ? '0' + day : day
      var formattedMonth = month < 10 ? '0' + month : month

      // Construct the formatted date string in European format
      return formattedDay + '.' + formattedMonth + '.' + year
    },
    async getCompanyDataByID(CompanyID, token) {
      var response = await httpGetAuth(ApiNames.company, { id: CompanyID }, {}, token)
      return response
    },
  },
})

// =========================================================
// * Vue Material Dashboard PRO - v1.5.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import Select2 from 'v-select2-component'
import VueRouter from 'vue-router'
import DashboardPlugin from './material-dashboard'

// Plugins
import App from './App.vue'
import Chartist from 'chartist'

// router setup
import routes from './routes/routes'
import { SlideYDownTransition } from 'vue2-transitions'
import VueCookies from 'vue-cookies'
import { createPinia, PiniaVuePlugin } from 'pinia'

import VueI18n from 'vue-i18n'
import { httpGetByUrl } from '@/libs/httpHelper'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Modal from '@burhanahmeed/vue-modal-2'
import '../src/pages/Contacts/select2.scss'

import { useUserStore } from './stores/user'
import { useManagementStore } from './stores/management'
import { useConfigurationProductLevelStore } from './stores/configurationProductLevel'
import { unsetStatsGA } from './utils'

// plugin setup
Vue.use(VueRouter)
Vue.use(DashboardPlugin)
Vue.use(VueI18n)
Vue.use(Modal, {
  componentName: 'ModalVue',
})
// Globally register your component
Vue.component('slide-y-down-transition', SlideYDownTransition)
Vue.component('SelectVue', Select2)
//pinia
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  linkExactActiveClass: 'nav-item active',
})
if (window.location.protocol !== 'https:' && !window.location.href.includes('localhost')) {
  window.location.href = 'https://' + window.location.hostname + window.location.pathname + window.location.search
}

const userStore = useUserStore(pinia)
const managementStore = useManagementStore(pinia)
const configurationLevelStore = useConfigurationProductLevelStore(pinia)
router.beforeEach((to, from, next) => {
  var token = userStore.token

  // here is getting checked if the user has 'two factor auth' activated
  // and only nafivates further when the auth-key is passed
  if (to.name !== 'Login' && to.name !== 'Enter Tfa Code') {
    if (userStore.tfa.Enabled && !userStore.tfa.Verified) {
      const expirationTime = parseInt(userStore.tfa.Exp) * 1000
      const now = Date.now()
      if (now > expirationTime) {
        next({ name: 'Login' })
      } else {
        next({ name: 'Enter Tfa Code' })
      }
      return
    }
  }
  // cancel all navigation further in the app if no token is applied
  if (
    to.name !== 'Login' &&
    to.name !== 'Registration' &&
    to.name !== 'Forgot Password' &&
    to.name !== 'Confirmation Registration' &&
    to.name !== 'Reset Lockout' &&
    to.name !== 'Confirm Company Contact' &&
    to.name !== 'Set New Password' &&
    userStore.token === ''
  )
    next({ name: 'Login' })
  else {
    // here the permissions in meta of each route is checked
    // if the user dont have the permission the navigation sends him to login
    if (to.meta.permissions && to.meta.permissions.length > 0) {
      let isAllowed = userStore.permissions.some((p) => to.meta.permissions.includes(p))
      if (!isAllowed) {
        managementStore.fireUnauthorized()
        return next(from)
      }
    }

    // clear configurationStore if you are done with the confiuration
    if (from.path.includes('mdesignconfiguration') && !to.path.includes('mdesignconfiguration')) {
      configurationLevelStore.resetConfigurationProductLevelStore()
    }
    next()
  }
})

export const i18n = new VueI18n({
  legacy: false,
  locale: 'de-DE',
  fallbackLocale: 'de-DE',
  missing: (locale, key) => key,
})
Vue.component('i18n', i18n)

// loadDictionary()

// global library setup
Vue.prototype.$Chartist = Chartist
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: '365d' })
/* eslint-disable no-new */
Vue.prototype.$eventBus = new Vue()
checkUserCookiesAndConfigureGA()
;(async function initializeApp() {
  await loadDictionary() // Load the dictionary first

  new Vue({
    el: '#app',
    render: (h) => h(App),
    router,
    pinia,
    i18n,
  })
})()
async function loadDictionary() {
  // TODO: better error handling when file can't be loaded or has parsing issues
  const response = await httpGetByUrl(window.location.origin + '/dictionary.csv')
  if (response.status == 200 && response.data) {
    try {
      const csvResult = parseResult(response.data)
      for (let i = 1; i < csvResult.length; i++) {
        const key = csvResult[i][0]
        if (!key) continue
        for (let j = 1; j < csvResult[i].length; j++) {
          i18n.mergeLocaleMessage(csvResult[0][j], {
            [key]: csvResult[i][j],
          })
        }
      }
    } catch (err) {}
  } else {
    //initSuccess = false
  }
  // dictionaryLoaded = true
}
function parseResult(result) {
  var resultArray = []
  result.split('\r\n').forEach(function (row) {
    var rowArray = []
    row.split(';').forEach(function (cell) {
      rowArray.push(cell)
    })
    resultArray.push(rowArray)
  })
  return resultArray
}
function checkUserCookiesAndConfigureGA() {
  const cookiesObj = Vue.$cookies.get('my.mdesign-klaro')
  if (cookiesObj && !cookiesObj?.stats) {
    unsetStatsGA()
  }
  if (cookiesObj && cookiesObj?.stats) {
    let environment = window.location.hostname
    let analyticsID = 'G-NJL0T2PYH0' // Default for test environment
    if (environment === 'my.mdesign.de') {
      analyticsID = 'G-B0R9Z0F5TL'
    }
    var script = document.createElement('script')
    script.async = true
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + analyticsID
    document.head.appendChild(script)
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', analyticsID)
  }
}

<template>
  <md-card v-if="awsConfigurationID !== ''" class="card-content" :key="renderKey">
    <loader :loader="loader" />
    <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
      <div class="card-icon">
        <md-icon>share</md-icon>
      </div>
      <h4 class="title">
        {{ $t('awsLinkedProducts') }}
      </h4>
    </md-card-header>
    <md-card-content>
      <md-table
        :value="queriedData"
        :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder"
        :md-sort-fn="customSort"
        class="paginated-table table-striped table-hover"
      >
        <md-table-toolbar>
          <div class="toolbar-left">
            <md-field>
              <div>
                <label for="pages" id="perPage">{{ $t('perPage') }}</label>
              </div>
              <md-select v-model="pagination.perPage" name="pages">
                <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                  {{ item }}
                </md-option>
              </md-select>
            </md-field>
            <md-field class="ml-10">
              <md-input
                type="search"
                class="mb-3"
                clearable
                style="width: 200px"
                :placeholder="searchRecords"
                v-model="searchQuery"
              ></md-input>
            </md-field>
          </div>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell :md-label="'#'">
            {{ calculatePosition(index) }}
          </md-table-cell>

          <md-table-cell :md-label="$t('name')" md-sort-by="Name"
            ><strong>{{ item.Name }}</strong></md-table-cell
          >

          <md-table-cell :md-label="$t('awsInfo')" md-sort-by="Info">{{ item.Info }}</md-table-cell>

          <md-table-cell :md-label="$t('actions')">
            <md-switch v-model="item.IsActive" @change="handleActivationProduct(item)">
              {{ $t('accessActive') }}
            </md-switch>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions>
      <div class="card-actions-container">
        <div class="button-actions">
          <md-button id="submit" class="md-raised md-primary mt-4 md-round" @click="askLinkProduct()"
            >{{ $t('selectProductToLink') }}
          </md-button>
        </div>
        <div class="table-actions">
          <div class="">
            <p class="card-category">
              {{ $t('show') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
            </p>
          </div>

          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </div>
      </div>
    </md-card-actions>
  </md-card>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useConfigurationStore } from '@/stores/configuration'
import { useUserStore } from '@/stores/user'
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'

export default {
  components: { Pagination },
  props: {
    awsConfigurationID: { type: String },
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      tableData: [],
      searchedData: [],
      renderKey: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
        active: false,
      },
      currentSort: '',
      currentSortOrder: 'asc',
      searchQuery: '',
      searchRecords: this.$t('searchRecords'),
      productsToLink: [],
      productCloudID: '{faeb2319-1fb3-4fb8-a5ea-d3250f5ccccd}',
      linkedProduct: {},
      linkedProducts: [],
      allProducts: [],
      fuseSearch: null,
      loader: false,
    }
  },
  async mounted() {
    this.loader = true

    await this.getAllProducts()
    await this.updateData()

    this.tableData = this.linkedProducts
    this.searchedData = this.tableData

    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['Name', 'Info'],
      findAllMatches: true,
      useExtendedSearch: true,
      shouldSort: true,
      ignoreLocation: true,
      threshold: 0.1,
    })
    this.loader = false
  },
  methods: {
    async updateData() {
      await this.getLinkedProducts()
      this.setProductsToLink()
    },
    async askLinkProduct() {
      let inputOptions = []
      this.productsToLink.forEach((item) => {
        inputOptions[item.ID] = item.Name + (item.Info !== '' ? ' - ' + item.Info : '')
      })

      const result = await Swal.fire({
        icon: 'info',
        title: this.$t('askLinkAwsProduct'),
        input: 'select',
        inputOptions: inputOptions,
        inputPlaceholder: this.$t('chooseProduct'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        return result
      })

      if (!result.isConfirmed || result.value === '') return

      this.postProductLink(result.value)
    },
    async getLinkedProducts() {
      const awsID = this.awsConfigurationID
      const res = await this.configurationStore.getCompanyHasProductHasConfigurationAwsByConfigurationawsID(
        this.awsConfigurationID,
        this.userStore.token,
      )
      this.linkedProducts = []
      if (res.status === 200) this.linkedProducts = res.data

      if (this.linkedProducts.length > 0) {
        this.linkedProducts.forEach((item) => {
          const companyHasProduct = this.companyStore.productData.companyHasProduct.find(
            (cHp) => cHp.ID === item.CompanyHasProductID,
          )
          const product = this.allProducts.find((p) => p.ID === companyHasProduct.ProductID)
          item.Name = product.Name
          item.Info = companyHasProduct.Info
        })
        this.tableData = this.linkedProducts
        this.searchedData = this.tableData
      }
    },
    async getAllProducts() {
      const res = await this.companyStore.getProducts(this.userStore.token)
      if (res.status === 200) {
        this.allProducts = res.data
      } else this.allProducts = []
    },
    setProductsToLink() {
      this.productsToLink = []

      this.companyStore.productData.companyHasProduct.forEach((product) => {
        var p = this.allProducts.find((i) => i.ID === product.ProductID)
        if (p.ID !== this.productCloudID) {
          var obj = {
            Name: p.Name,
            ProductID: product.ProductID,
            Info: product.Info,
            ConfigurationID: product.ConfigurationID,
            ID: product.ID,
          }
          if (!this.linkedProducts.some((p) => p.CompanyHasProductID === obj.ID)) this.productsToLink.push(obj)
        }
      })
    },
    async postProductLink(companyHasProductID) {
      const obj = {
        CompanyHasProductID: companyHasProductID,
        ConfigurationAwsID: this.awsConfigurationID,
        IsActive: false,
        CreatedBy: this.userStore.userData.ID,
        ModifiedBy: this.userStore.userData.ID,
      }

      const res = await this.configurationStore.createCompanyHasProductHasConfigurationAws(obj, this.userStore.token)

      if (res.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.data.Message,
        })
      }
      this.updateData()
      this.renderKey++
    },
    async handleActivationProduct(item) {
      this.loader = true
      // update companyhasproducthasconfigurationaws
      // get the products
      const now = this.companyStore.formatDate(new Date())
      const obj = {
        CompanyHasProductID: item.CompanyHasProductID,
        ConfigurationAwsID: item.ConfigurationAwsID,
        IsActive: item.IsActive,
        ModifiedBy: this.userStore.userData.ID,
        Modified: now,
      }

      await this.configurationStore.updateCompanyHasProductHasConfigurationAws(obj, this.userStore.token)

      this.updateData()
      this.loader = false
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    changeTranslation() {
      this.perPage = this.$t('perPage')
      this.searchRecords = this.$t('searchRecords')
    },
    calculatePosition(index) {
      return index + 1 + (this.pagination.currentPage - 1) * this.pagination.perPage
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationStore = useConfigurationStore()

    return { companyStore, userStore, configurationStore }
  },
  updated() {
    if (this.perPage != this.$t('perPage')) {
      this.perPage = this.$t('perPage')
      this.searchRecords = this.$t('searchRecords')
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
        result = result.map((record) => record.item)
      }
      this.searchedData = result
    },
  },
  computed: {
    // return userStore
    // }
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      result = this.searchedData
      return result.slice(this.from, this.to)
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length
    },
  },
}
</script>

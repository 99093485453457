<template>
  <div :key="renderKey" class="edit-product-level-content">
    <ValidationObserver v-slot="{}"
      ><!--invalid-->
      <form>
        <loader :loader="loader" />
        <!-- <md-card class="card-content">
          <md-card-content class=""> -->
        <div class="md-layout">
          <div v-if="productLevel !== 3 && productLevelSelection.length === 0" class="md-layout-item md-size-100">
            <md-field>
              <label>{{ $t('productLevelOneID') }}</label>
              <md-input v-model="configurationHasProductLevel.ProductLevelOneID" type="text" disabled></md-input>
            </md-field>
          </div>
          <div v-if="productLevel !== 1 && productLevelSelection.length === 0" class="md-layout-item md-size-100">
            <md-field>
              <label>{{ $t('productLevelTwoID') }}</label>
              <md-input v-model="configurationHasProductLevel.ProductLevelTwoID" type="text" disabled></md-input>
            </md-field>
          </div>
          <div v-if="productLevel === 3 && productLevelSelection.length === 0" class="md-layout-item md-size-100">
            <md-field>
              <label>{{ $t('productLevelThreeID') }}</label>
              <md-input v-model="configurationHasProductLevel.ProductLevelThreeID" type="text" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <label>{{ $t('configurationID') }}</label>
              <md-input v-model="configurationHasProductLevel.ConfigurationID" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <label>{{ $t('count') }}</label>
              <md-input v-model="configurationHasProductLevel.Count" type="number"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field class="md-has-value date-field">
              <label>{{ $t('startDate') }}</label>
              <!-- <md-input v-model="configurationHasProductLevel.StartDate" type="text"></md-input> -->
              <md-input type="date" v-model="configurationHasProductLevel.StartDate" />
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field class="md-has-value date-field">
              <label>{{ $t('limited') }}</label>
              <!-- <md-input v-model="configurationHasProductLevel.Limited" type="text"></md-input> -->
              <md-input type="date" v-model="configurationHasProductLevel.Limited" />
            </md-field>
          </div>
          <div class="md-layout-item md-size-100" v-if="productLevel === 1">
            <md-field>
              <md-checkbox name="test" id="test" value="0" v-model="valTest50">{{ $t('test50') }}</md-checkbox>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100" v-if="productLevel === 1">
            <md-field>
              <md-checkbox name="student" id="student" value="0" v-model="valStudent">{{ $t('student') }}</md-checkbox>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100" v-if="productLevel === 1">
            <md-field>
              <md-checkbox name="uncounted" id="uncounted" value="0" v-model="valUncounted">{{
                $t('uncounted')
              }}</md-checkbox>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100" v-if="productLevel === 1">
            <md-field>
              <md-checkbox name="editModules" id="editModules" value="0" v-model="valEditModules">{{
                $t('editModules')
              }}</md-checkbox>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100" v-if="productLevel === 1">
            <md-field>
              <md-checkbox name="connect" id="connect" value="0" v-model="valConnect">{{ $t('connect') }}</md-checkbox>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100" v-if="productLevelSelection.length === 0">
            <md-field>
              <label>{{ $t('sorting') }}</label>
              <md-input v-model="configurationHasProductLevel.Sorting" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-button v-if="!editProduct" @click="askCreate" class="md-round md-primary">{{
                $t('create')
              }}</md-button>
              <md-button v-else @click="askUpdate" class="md-round md-primary">{{ $t('save') }}</md-button>
            </div>
            <div class="md-layout-item md-size-50">
              <md-button @click="closeModal" class="md-round md-simple">{{ $t('cancel') }}</md-button>
            </div>
          </div>
        </div>
        <!-- </md-card-content>
        </md-card> -->
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import Swal from 'sweetalert2'
import image_placeholder from '../../../../public/img/image_placeholder.jpg'
import pdfImg from '../../../../public/img/PDF_file_icon.svg.png'
import { extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'

export default {
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
    productLevel: {
      type: Number,
      required: true,
    },
    configurationID: {
      type: String,
      default: null,
    },
    productLevelOneID: {
      type: String,
      default: null,
    },
    productLevelTwoID: {
      type: String,
      default: null,
    },
    productLevelThreeID: {
      type: String,
      default: null,
    },
    productLevelSelection: {
      type: Array,
    },
    editProduct: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {
      renderKey: 0,
      pdf: pdfImg,
      loader: false,
      image: null,
      newAvatar: null,
      sliders: {
        simple: 5,
      },
      files: [],
      regularImg: image_placeholder,
      imageRegular: '',
      valTest50: null,
      valStudent: null,
      valUncounted: null,
      valEditModules: null,
      valConnect: null,
      valCount: 0,
      configurationHasProductLevel: {
        ID: '',
        ConfigurationID: '',
        Count: 0,
        StartDate: '',
        Limited: '',
        Sorting: -1,
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      lastSorting: 0,
    }
  },
  async beforeMount() {
    this.loader = true
    if (!this.editProduct) {
      if (this.productLevelSelection.length === 0) {
        if (this.configurationID !== null && this.productLevel === 1) {
          this.setConfigurationHasProductLevelOne()
        } else if (this.configurationID !== null && this.productLevel === 2) {
          this.setConfigurationHasProductLevelTwo()
        } else if (this.configurationID !== null && this.productLevel === 3) {
          this.setConfigurationHasProductLevelThree()
        }
      } else {
        if (this.productLevel === 1) {
          this.setConfigurationHasProductLevelOneMulti()
        } else if (this.productLevel === 2) {
          this.setConfigurationHasProductLevelTwoMulti()
        } else if (this.productLevel === 3) {
          this.setConfigurationHasProductLevelThreeMulti()
        }
      }
    } else {
      if (this.productLevel === 1) {
        this.getConfigurationHasProductLevelOne()
      } else if (this.productLevel === 2) {
        this.getConfigurationHasProductLevelTwo()
      } else if (this.productLevel === 3) {
        this.getConfigurationHasProductLevelThree()
      }
    }

    this.loader = false
  },
  beforeDestroy() {},
  methods: {
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    askCreate() {
      Swal.fire({
        position: 'middle-end',
        icon: 'question',
        title: this.$t('askCreateConfigHasProductlevel'),
        // html: '',
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.productLevelSelection.length === 0) this.createConfigurationHasProductLevel()
          else this.createConfigurationHasProductLevelMultiple()
        }
      })
    },
    askUpdate() {
      Swal.fire({
        position: 'middle-end',
        icon: 'question',
        title: this.$t('updateMaster'),
        html: this.$t('askUpdateConfigHasProductlevel'),
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) this.updateConfigHasProdcutLevel()
      })
    },
    async createConfigurationHasProductLevel() {
      const date = new Date()
      const now = date.toISOString()

      this.configurationHasProductLevel.Created = now
      this.configurationHasProductLevel.Modified = now
      this.configurationHasProductLevel.CreatedBy = this.userStore.userData.ID
      this.configurationHasProductLevel.ModifiedBy = this.userStore.userData.ID
      this.configurationHasProductLevel.Count = parseInt(this.configurationHasProductLevel.Count)

      if (this.productLevel === 1) {
        if (this.productLevelSelection.length === 0) {
          this.configurationHasProductLevel.Test = this.valTest50 === null ? -1 : 0
          this.configurationHasProductLevel.Student = this.valStudent === null ? -1 : 0
          this.configurationHasProductLevel.Uncounted = this.valUncounted === null ? -1 : 0
          this.configurationHasProductLevel.EditModules = this.valEditModules === null ? -1 : 0
          this.configurationHasProductLevel.Connect = this.valConnect === null ? -1 : 0
          this.configurationHasProductLevel.TestCount = 0
          var res = await this.configurationProductLevelStore.CreateConfigurationHasProductLevel_1(
            this.configurationHasProductLevel,
            this.userStore.token,
          )
          this.$emit('update')
        }
      } else if (this.productLevel === 2) {
        var res = await this.configurationProductLevelStore.CreateConfigurationHasProductLevel_2(
          this.configurationHasProductLevel,
          this.userStore.token,
        )
        this.$emit('update')
      } else if (this.productLevel === 3) {
        var res = await this.configurationProductLevelStore.CreateConfigurationHasProductLevel_3(
          this.configurationHasProductLevel,
          this.userStore.token,
        )
        this.$emit('update')
      }
    },
    async createConfigurationHasProductLevelMultiple() {
      const date = new Date()
      const now = date.toISOString()
      var sort = this.getLastSorting() + 1
      var toPush = []
      if (this.productLevel === 1) {
        this.productLevelSelection.forEach((selected) => {
          var obj = {
            ID: '',
            ProductLevelOneID: selected.ID,
            ConfigurationID: this.configurationID,
            Created: now,
            Modified: now,
            CreatedBy: this.userStore.userData.ID,
            ModifiedBy: this.userStore.userData.ID,
            Count: parseInt(this.configurationHasProductLevel.Count),
            Test: this.valTest50 === null ? -1 : 0,
            Student: this.valStudent === null ? -1 : 0,
            Uncounted: this.valUncounted === null ? -1 : 0,
            EditModules: this.valEditModules === null ? -1 : 0,
            Connect: this.valConnect === null ? -1 : 0,
            TestCount: 0,
            Sorting: sort,
            StartDate: this.configurationHasProductLevel.StartDate,
            Limited: this.configurationHasProductLevel.Limited,
          }
          sort++
          toPush.push(obj)
        })
        var res_many = await this.configurationProductLevelStore.CreateConfigurationHasProductLevel_1_many(
          toPush,
          this.userStore.token,
        )

        this.$emit('update')
      } else if (this.productLevel === 2) {
        this.productLevelSelection.forEach((selected) => {
          var obj = {
            ID: '',
            ProductLevelOneID: this.productLevelOneID,
            ProductLevelTwoID: selected.ID,
            ConfigurationID: this.configurationID,
            Created: now,
            Modified: now,
            CreatedBy: this.userStore.userData.ID,
            ModifiedBy: this.userStore.userData.ID,
            Sorting: sort,
            StartDate: this.configurationHasProductLevel.StartDate,
            Limited: this.configurationHasProductLevel.Limited,
            Count: parseInt(this.configurationHasProductLevel.Count),
          }
          sort++
          toPush.push(obj)
        })
        var res_many = await this.configurationProductLevelStore.CreateConfigurationHasProductLevel_2_many(
          toPush,
          this.userStore.token,
        )
        this.$emit('update')
      } else if (this.productLevel === 3) {
        this.productLevelSelection.forEach((selected) => {
          var obj = {
            ID: '',
            ProductLevelTwoID: this.productLevelTwoID,
            ProductLevelThreeID: selected.ID,
            ConfigurationID: this.configurationID,
            Created: now,
            Modified: now,
            CreatedBy: this.userStore.userData.ID,
            ModifiedBy: this.userStore.userData.ID,
            Sorting: sort,
            StartDate: this.configurationHasProductLevel.StartDate,
            Limited: this.configurationHasProductLevel.Limited,
            Count: parseInt(this.configurationHasProductLevel.Count),
          }
          sort++
          toPush.push(obj)
        })
        var res_many = await this.configurationProductLevelStore.CreateConfigurationHasProductLevel_3_many(
          toPush,
          this.userStore.token,
        )
        this.$emit('update')
      }

      if (res_many.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('successCreateProductLevel'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: res_many.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      }
    },
    async updateConfigHasProdcutLevel() {
      const date = new Date()
      const now = date.toISOString()
      if (this.productLevel === 1) {
        this.configurationHasProductLevel.Test = this.valTest50 === null ? -1 : 0
        this.configurationHasProductLevel.Student = this.valStudent === null ? -1 : 0
        this.configurationHasProductLevel.Uncounted = this.valUncounted === null ? -1 : 0
        this.configurationHasProductLevel.EditModules = this.valEditModules === null ? -1 : 0
        this.configurationHasProductLevel.Connect = this.valConnect === null ? -1 : 0
        this.configurationHasProductLevel.Modified = now
        this.configurationHasProductLevel.ModifiedBy = this.userStore.userData.ID
        var res1 = await this.configurationProductLevelStore.updateConfigurationHasProductLevelOne(
          this.configurationHasProductLevel,
          this.userStore.token,
        )
        if (res1.status === 200) {
          Swal.fire({
            title: this.$t('success'),
            html: this.$t('successUpdateProductLevel'),
            icon: 'success',
            confirmButtonText: this.$t('ok'),
          })
        }
        this.$emit('update')
      } else if (this.productLevel === 2) {
        this.configurationHasProductLevel.Modified = now
        this.configurationHasProductLevel.ModifiedBy = this.userStore.userData.ID
        var res1 = await this.configurationProductLevelStore.updateConfigurationHasProductLevelTwo(
          this.configurationHasProductLevel,
          this.userStore.token,
        )
        if (res1.status === 200) {
          Swal.fire({
            title: this.$t('success'),
            html: this.$t('successUpdateProductLevel'),
            icon: 'success',
            confirmButtonText: this.$t('ok'),
          })
        }
        this.$emit('update')
      } else if (this.productLevel === 3) {
        this.configurationHasProductLevel.Modified = now
        this.configurationHasProductLevel.ModifiedBy = this.userStore.userData.ID
        var res1 = await this.configurationProductLevelStore.updateConfigurationHasProductLevelThree(
          this.configurationHasProductLevel,
          this.userStore.token,
        )
        if (res1.status === 200) {
          Swal.fire({
            title: this.$t('success'),
            html: this.$t('successUpdateProductLevel'),
            icon: 'success',
            confirmButtonText: this.$t('ok'),
          })
        }
        this.$emit('update')
      }
    },
    getLastSorting() {
      var res = -1
      if (this.productLevel === 1) {
        if (this.configurationProductLevelStore.masterModel.ProductsLevel_1.length > 0) {
          this.configurationProductLevelStore.masterModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.Sorting > res) res = level_1.Sorting
          })
        } else {
          res = 0
        }
      } else if (this.productLevel === 2) {
        var levelOne = this.configurationProductLevelStore.masterModel.ProductsLevel_1.find(
          (obj) => obj.ProductLevelOneID === this.productLevelOneID,
        )
        if (levelOne.ProductsLevel_2.length > 0) {
          levelOne.ProductsLevel_2.forEach((level_2) => {
            if (level_2.Sorting > res) res = level_2.Sorting
          })
        } else {
          res = 0
        }
      } else if (this.productLevel === 3) {
        // var levelOne = this.configurationProductLevelStore.masterModel.ProductsLevel_1.find((obj) => obj.ProductLevelOneID === this.productLevelOneID)

        var levelTwo
        this.configurationProductLevelStore.masterModel.ProductsLevel_1.forEach((level_1) => {
          if (level_1.ProductsLevel_2.length > 0) {
            if (level_1.ProductsLevel_2.some((obj) => obj.ProductLevelTwoID === this.productLevelTwoID))
              levelTwo = level_1.ProductsLevel_2.find((obj) => obj.ProductLevelTwoID === this.productLevelTwoID)
          }
        })
        if (levelTwo.ProductsLevel_3.length > 0) {
          levelTwo.ProductsLevel_3.forEach((level_3) => {
            if (level_3.Sorting > res) res = level_3.Sorting
          })
        } else {
          res = 0
        }
      }
      return res
    },
    closeModal() {
      this.$emit('close')
    },
    async getConfigurationHasProductLevelOne() {
      var res = await this.configurationProductLevelStore.getConfigurationHasProductLevel_1_Combi(
        this.productLevelOneID,
        this.configurationID,
        this.userStore.token,
      )

      if (res.status === 200) {
        this.configurationHasProductLevel = res.data[0]
        this.configurationHasProductLevel.StartDate = this.configurationHasProductLevel.StartDate.includes('T')
          ? this.configurationHasProductLevel.StartDate.split('T')[0]
          : this.configurationHasProductLevel.StartDate
        this.configurationHasProductLevel.Limited = this.configurationHasProductLevel.Limited.includes('T')
          ? this.configurationHasProductLevel.Limited.split('T')[0]
          : this.configurationHasProductLevel.Limited

        this.valTest50 = this.configurationHasProductLevel.Test === -1 ? null : '0'
        this.valStudent = this.configurationHasProductLevel.Student === -1 ? null : '0'
        this.valUncounted = this.configurationHasProductLevel.Uncounted === -1 ? null : '0'
        this.valEditModules = this.configurationHasProductLevel.EditModules === -1 ? null : '0'
        this.valConnect = this.configurationHasProductLevel.Connect === -1 ? null : '0'
      }
    },
    async getConfigurationHasProductLevelTwo() {
      var res = await this.configurationProductLevelStore.getConfigurationHasProductLevel_2_Combi(
        this.productLevelOneID,
        this.productLevelTwoID,
        this.configurationID,
        this.userStore.token,
      )
      if (res.status === 200) {
        this.configurationHasProductLevel = res.data[0]
        this.configurationHasProductLevel.StartDate = this.configurationHasProductLevel.StartDate.includes('T')
          ? this.configurationHasProductLevel.StartDate.split('T')[0]
          : this.configurationHasProductLevel.StartDate
        this.configurationHasProductLevel.Limited = this.configurationHasProductLevel.Limited.includes('T')
          ? this.configurationHasProductLevel.Limited.split('T')[0]
          : this.configurationHasProductLevel.Limited
      }
    },
    async getConfigurationHasProductLevelThree() {
      var res = await this.configurationProductLevelStore.getConfigurationHasProductLevel_3_Combi(
        this.productLevelTwoID,
        this.productLevelThreeID,
        this.configurationID,
        this.userStore.token,
      )

      if (res.status === 200) {
        this.configurationHasProductLevel = res.data[0]
        this.configurationHasProductLevel.StartDate = this.configurationHasProductLevel.StartDate.includes('T')
          ? this.configurationHasProductLevel.StartDate.split('T')[0]
          : this.configurationHasProductLevel.StartDate
        this.configurationHasProductLevel.Limited = this.configurationHasProductLevel.Limited.includes('T')
          ? this.configurationHasProductLevel.Limited.split('T')[0]
          : this.configurationHasProductLevel.Limited
      }
    },
    setConfigurationHasProductLevelOne() {
      this.configurationHasProductLevel.ConfigurationID = this.configurationID
      this.configurationHasProductLevel.ProductLevelOneID = this.productLevelOneID
      this.lastSorting = this.getLastSorting()
      this.configurationHasProductLevel.Sorting = this.lastSorting + 1
    },
    setConfigurationHasProductLevelOneMulti() {
      this.configurationHasProductLevel.ConfigurationID = this.configurationID
    },
    setConfigurationHasProductLevelTwoMulti() {
      this.configurationHasProductLevel.ConfigurationID = this.configurationID
      this.configurationHasProductLevel.ProductLevelOneID = this.productLevelOneID
    },
    setConfigurationHasProductLevelThreeMulti() {
      this.configurationHasProductLevel.ConfigurationID = this.configurationID
      this.configurationHasProductLevel.ProductLevelTwoID = this.productLevelTwoID
    },
    setConfigurationHasProductLevelTwo() {
      this.configurationHasProductLevel.ConfigurationID = this.configurationID
      this.configurationHasProductLevel.ProductLevelOneID = this.productLevelOneID
      this.configurationHasProductLevel.ProductLevelTwoID = this.productLevelTwoID
      var level_1 = this.configurationProductLevelStore.masterModel.ProductsLevel_1.find(
        (obj) => obj.ProductLevelOneID === this.productLevelOneID,
      )
      this.configurationHasProductLevel.StartDate = level_1.StartDate.includes('T')
        ? level_1.StartDate.split('T')[0]
        : level_1.StartDate
      this.configurationHasProductLevel.Limited = level_1.Limited
      this.lastSorting = this.getLastSorting()
    },
    setConfigurationHasProductLevelThree() {
      this.configurationHasProductLevel.ConfigurationID = this.configurationID
      this.configurationHasProductLevel.ProductLevelTwoID = this.productLevelTwoID
      this.configurationHasProductLevel.ProductLevelThreeID = this.productLevelThreeID

      var level_1 = this.configurationProductLevelStore.masterModel.ProductsLevel_1.find(
        (obj) => obj.ProductLevelOneID === this.productLevelOneID,
      )

      var level_2 = level_1.ProductsLevel_2.find((obj) => obj.ProductLevelTwoID === this.productLevelTwoID)

      this.configurationHasProductLevel.StartDate = level_2.StartDate.includes('T')
        ? level_2.StartDate.split('T')[0]
        : level_2.StartDate
      this.configurationHasProductLevel.Limited = level_2.Limited.includes('T')
        ? level_2.Limited.split('T')[0]
        : level_2.Limited
      this.lastSorting = this.getLastSorting()
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    return { companyStore, userStore, configurationProductLevelStore }
  },
}
</script>

<style scoped lang="scss">
.edit-product-level-content {
  max-width: 400px;
}
[data-background-color='black'] {
  .title {
    color: white !important;
  }
}
.image-container {
  max-width: 100px;
  margin-bottom: 10px;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);

  .img-circle & {
    border-radius: 50%;
    max-width: 100px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  &,
  img {
    border-radius: 4px;
  }
}
.card-content {
  display: flex;
  flex-direction: column;
}
.title {
  width: 300px;
}
.md-layout-item {
  display: flex !important;
  justify-content: center !important;
}
.md-checkbox {
  margin: 0 16px 16px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.date-field {
  padding-right: 14px;
}
</style>

import Sidebar from './SideBar.vue'
import SidebarItem from './SidebarItem.vue'
import SideBarSecond from './SideBarSecond.vue'

const SidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  linksStore: [],
  showProductMenu: false,
  displaySidebar(value) {
    this.showSidebar = value
  },
  toggleMinimize() {
    this.showProductMenu = !this.showProductMenu
  },
  addSidebarLink(link) {
    this.linksStore.push(link)
  },
  removeSidebarLink(link) {
    const index = this.sidebarLinks.indexOf(this)
    this.linksStore.splice(index, 1)
  },
  collapseAllMenus() {
    this.linksStore.forEach((link) => {
      link.collapsed = true
    })
  },
}

const SidebarPlugin = {
  install(Vue, options) {
    if (options && options.sidebarLinks) {
      SidebarStore.sidebarLinks = options.sidebarLinks
    }
    Vue.mixin({
      data() {
        return {
          sidebarStore: SidebarStore,
        }
      },
    })

    Object.defineProperty(Vue.prototype, '$sidebar', {
      get() {
        return this.$root.sidebarStore
      },
    })
    Vue.component('side-bar', Sidebar)
    Vue.component('sidebar-item', SidebarItem)
    Vue.component('side-bar-second', SideBarSecond)
  },
}

export default SidebarPlugin
export { SidebarStore }

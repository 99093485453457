<template>
  <div class="md-layout md-size-33 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
    <md-card>
      <loader :loader="loader" />
      <md-card-header class="md-card-header-icon md-card-header-default">
        <h2 class="title text-center">
          {{ $t(title) }}
        </h2>
      </md-card-header>
      <md-card-content class="sucess-text">
        {{ $t(text) }} <a href="/login">{{ $t(link) }}</a>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user.js'
import { sendEmailApproveStudentEmailToHotline } from '@/libs/emailSender.js'

export default {
  data() {
    return {
      studentProductID: '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}',
      roloffMatekProductID: '{691a838c-0e9f-11ee-b3bc-0050561ebe44}',
      deckerProductID: '{d0b9464d-7b53-4b15-b97b-09bcc5898a72}',
      contactID: null,
      token: null,
      emailCoded: null,
      title: null,
      text: null,
      link: 'backToLogin',
      loader: false,
      productID: null,
    }
  },
  methods: {
    getDataFromToken() {
      const urlParams = new URLSearchParams(window.location.search)
      this.token = urlParams.get('id')
      this.emailCoded = urlParams.get('email')
      const tokenDecode = JSON.parse(atob(this.token.split('.')[1]))
      this.contactEmail = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
      this.contactID = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
    },

    async verifyStudentEmail() {
      //at first we need to understand which product is available for the user

      const response = await this.userStore.getUserVerification(this.studentProductID, this.contactID)
      if (!response.length) {
        const response = await this.userStore.getUserVerification(this.roloffMatekProductID, this.contactID)
        if (!response.length) {
          this.productID = this.deckerProductID
        } else {
          this.productID = this.roloffMatekProductID
        }
      } else {
        this.productID = this.studentProductID
      }
      const responseVerifyEmail = await this.userStore.verifyStudentEmail(this.productID, this.contactID, this.token)
      if (responseVerifyEmail === 'unauthorized') {
        this.title = 'error'
        this.text = 'linkExpired'
        this.link = 'backToLogin'
      }
      if (responseVerifyEmail === 'verification was updated. Status: 4') {
        this.title = 'waitPlease'
        this.text = 'studentEmailVerification'
        this.link = 'backToLogin'
        await sendEmailApproveStudentEmailToHotline(this.contactEmail)
        await this.changeStatusOfSecondVerificationIfExists(4)
      }
      if (responseVerifyEmail === 'verification was updated. Status: 3') {
        this.title = 'congratulations'
        this.text = 'studentEmailVerificationSuccess'
        this.link = 'backToLogin'
        await this.changeStatusOfSecondVerificationIfExists(3)
      }
      if (responseVerifyEmail === 'verification was not created.' || responseVerifyEmail === null) {
        this.title = 'error'
        this.text = 'setNewPassErrorPopup'
        this.link = 'backToLogin'
      }
    },
    async changeStatusOfSecondVerificationIfExists(status) {
      if (this.productID != this.studentProductID) {
        const studentVerification = await this.userStore.createVerification(this.studentProductID, this.contactID)
        await this.userStore.changeStatusOfVerification(
          this.studentProductID,
          this.contactID,
          status,
          studentVerification.data,
          true,
        )
      }
      if (this.productID === this.studentProductID) {
        //check if the contact has other products IDs
        const response = await this.userStore.getUserProducts(this.contactID)
        let secondProductID
        for (let product of response) {
          if (product.ProductID === this.roloffMatekProductID) {
            secondProductID = this.roloffMatekProductID
          }
          if (product.ProductID === this.deckerProductID) {
            secondProductID = this.deckerProductID
          }
        }
        //check if verification exists and change status for it
        if (secondProductID) {
          const secondVerification = await this.userStore.getUserVerification(secondProductID, this.contactID)
          if (!secondVerification.length) {
            const secondVerification = await this.userStore.createVerification(secondProductID, this.contactID)
            await this.userStore.changeStatusOfVerification(
              secondProductID,
              this.contactID,
              status,
              secondVerification.data,
              true,
            )
          } else {
            await this.userStore.changeStatusOfVerification(
              secondProductID,
              this.contactID,
              status,
              secondVerification[0].ID,
              true,
            )
          }
        }
      }
    },
  },
  async mounted() {
    this.loader = true
    this.getDataFromToken()
    await this.verifyStudentEmail()
    this.loader = false
  },

  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
}
</script>

<style scoped>
.sucess-text {
  text-align: center;
  font-size: 24px;
}

.sucess-text a {
  color: #009ee3;
}
</style>

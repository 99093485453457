<template>
  <div class="edit-technical-dependency-content">
    <ValidationObserver v-slot="{}"
      ><!--invalid-->
      <form>
        <loader :loader="loader" />
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-checkbox v-model="autoSelected">{{ $t('automaticallySelected') }}</md-checkbox>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <md-checkbox v-model="visible">{{ $t('visible') }}</md-checkbox>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50" v-if="!edit">
            <md-button @click="handleCreate" class="md-round md-primary">{{ $t('create') }}</md-button>
          </div>
          <div class="md-layout-item md-size-50" v-else>
            <md-button @click="handleSave" class="md-round md-primary">{{ $t('save') }}</md-button>
          </div>
          <div class="md-layout-item md-size-50">
            <md-button @click="closeModal" class="md-round md-simple">{{ $t('cancel') }}</md-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import Swal from 'sweetalert2'

export default {
  props: {
    productID: {
      type: String,
      required: true,
    },
    productLevelOneID: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      loader: false,
      autoSelected: false,
      visible: false,
      edit: false,
    }
  },
  async beforeMount() {
    this.loader = true

    var res = await this.configurationProductLevelStore.getProductHasProductLevelOnePrimary(
      this.productID,
      this.productLevelOneID,
      this.userStore.token,
    )

    if (res.status === 200) {
      this.edit = true
      this.visible = res.data[0].Visible
      this.autoSelected = res.data[0].AutomaticallySelected
    }

    this.loader = false
  },
  beforeDestroy() {},
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleCreate() {
      Swal.fire({
        icon: 'info',
        title: this.$t('askCreateTechDep'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.createTechDep()
        }
      })
    },
    async createTechDep() {
      var obj = {
        ProductID: this.productID,
        ProductLevelOneID: this.productLevelOneID,
        AutomaticallySelected: this.autoSelected,
        Visible: this.visible,
      }
      var res = await this.configurationProductLevelStore.createProductHasProductLevelOne(obj, this.userStore.token)

      if (res.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('successCreateTechDep'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
        this.$emit('update')
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: res.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
        this.closeModal()
      }
    },
    handleSave() {
      Swal.fire({
        icon: 'info',
        title: this.$t('askSaveTechDep'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveTechDep()
        }
      })
    },
    async saveTechDep() {
      var obj = {
        ProductID: this.productID,
        ProductLevelOneID: this.productLevelOneID,
        AutomaticallySelected: this.autoSelected,
        Visible: this.visible,
      }
      var res = await this.configurationProductLevelStore.updateProductHasProductLevelOne(obj, this.userStore.token)
      if (res.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('successSaveTechDep'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
        this.$emit('update')
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: res.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
        this.closeModal()
      }
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    return { companyStore, userStore, configurationProductLevelStore }
  },
}
</script>
<style lang="scss">
.md-checkbox {
  margin: 0 16px 16px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  label {
    padding-left: 0 !important;
  }
}
</style>

<template>
  <ValidationObserver v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(submit)" class="md-layout-item md-medium-size-50 md-size-50 md-small-size-100">
      <loader :loader="loader" />
      <md-card class="card-content scroll-auto">
        <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
          <div class="card-icon">
            <md-icon>school</md-icon>
          </div>
          <h4 class="title">
            {{ $t('domainData') }}
          </h4>
          <h3>{{ $t('createDomainText') }}</h3>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <ValidationProvider name="domainName" rules="required" v-slot="{ passed, failed }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t('domain') }}</label>
                  <md-input v-model="domain.DomainName" type="text"></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100">
              <select-country :value="domain.Country.toUpperCase()" @changeCountry="handleCountry"></select-country>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100">
              <div>
                <ValidationProvider name="descriptionGer" rules="required" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('domainNameDE') }}</label>
                    <md-input v-model="domain.DescriptionDE" type="text"></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100">
              <div>
                <ValidationProvider name="descriptionEn" rules="required" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('domainNameEN') }}</label>
                    <md-input v-model="domain.DescriptionEN" type="text"></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout-item md-size-100 text-right">
              <md-button id="submit" class="md-raised md-primary mt-4" @click="askCreate()" :disabled="invalid"
                >{{ $t('save') }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import SelectCountry from '../../components/SelectCountry/SelectCountry.vue'
import { useCompanyStore } from '../../stores/company'
import { useUserStore } from '../../stores/user'
import { useManagementStore } from '../../stores/management'
import Swal from 'sweetalert2'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

export default {
  components: { SelectCountry, ValidationObserver, ValidationProvider },
  name: 'create-company-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const managementStore = useManagementStore()

    return { companyStore, userStore, managementStore }
  },
  data() {
    return {
      domain: {
        Country: '',
        Created: '',
        CreatedBy: '',
        DescriptionDE: '',
        DescriptionEN: '',
        DomainName: '',
        Modified: '',
        ModifiedBy: '',
      },
      loader: false,
    }
  },
  methods: {
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    handleCountry(val) {
      this.domain.Country = val.value.toLowerCase()
    },
    async askCreate() {
      Swal.fire({
        icon: 'question',
        html: this.$t('createStudentDomain'),
        showCancelButton: true,
      }).then(async (result) => {
        if (!result.isConfirmed) return

        const now = this.companyStore.getNow()
        var obj = {
          DescriptionDE: this.domain.DescriptionDE,
          DescriptionEN: this.domain.DescriptionEN,
          Country: this.domain.Country,
          DomainName: this.domain.DomainName,
          Created: now,
          Modified: now,
          CreatedBy: this.userStore.userData.ID,
          ModifiedBy: this.userStore.userData.ID,
        }

        var res = await this.managementStore.createStudentDomain(obj, this.userStore.token)
        if (res.status === 200) {
          await this.userStore.getAllStudentDomains()
          Swal.fire({
            icon: 'success',
            html: this.$t('createDomainSuccess'),
          }).then(() => {
            this.$router.push({ name: 'Edit Contact' })
          })
        } else {
          var error = res.data.Message ? res.data.Message : undefined

          Swal.fire({
            icon: 'error',
            html: this.$t('createDomainError') + error,
          })
        }
      })
    },
  },
}
</script>
<style scoped>
form {
  margin: 0 auto;
}
</style>

// loggingService.js

class LoggingService {
  constructor() {
    this.logs = []
  }

  info(message) {
    var date = new Date()
    this.logs.push(`[INFO] [${date}] ${message}`)
  }

  error(message) {
    var date = new Date()
    this.logs.push(`[ERROR] [${date}] ${message}`)
  }

  debug(message) {
    var date = new Date()
    this.logs.push(`[DEBUG] [${date}] ${message}`)
  }

  saveLogsToFile() {
    const logText = this.logs.join('\n')
    const blob = new Blob([logText], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, 'log.txt') // saveAs is provided by FileSaver library
  }
}

export default LoggingService

import { directive as vClickOutside } from 'vue-clickaway'
import { changePageTitle, getUsedLocalStorage } from '@/utils'
/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', vClickOutside)
    Vue.prototype.$changePageTitle = changePageTitle
    Vue.prototype.$getUsedLocalStorage = getUsedLocalStorage
  },
}

export default GlobalDirectives

<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100">
      <md-card class="md-card-roles-table">
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>vpn_key</md-icon>
          </div>
          <h4>{{ $t('overViewRoles') }}</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-size-100 md-space-between config-content roles-header">
            <md-field>
              <div class="md-layout-item md-size-15">
                <span>{{ $t('role') }}</span>
              </div>
              <div class="md-layout-item md-size-70">
                <span>{{ $t('description') }}</span>
              </div>
              <div class="md-layout-item md-size-15 text-right">
                <span>{{ $t('controls') }}</span>
              </div>
            </md-field>
          </div>

          <md-field v-for="role in mdUserRoles" :key="role.ID">
            <div class="md-layout content-row">
              <div class="md-layout-item md-size-15 name-div">
                <span>{{ role.Name }}</span>
              </div>
              <div class="md-layout-item md-size-70">
                <span>{{ role.Description }}</span>
              </div>
              <div class="md-layout-item md-size-15 controls">
                <div class="icon-container">
                  <span class="animated-icon" @click="handleEdit(role)">edit</span>
                  <md-tooltip>{{ $t('edit') }}</md-tooltip>
                </div>
                <div class="icon-container">
                  <span class="animated-icon" @click="handleDelete(role)">delete</span>
                  <md-tooltip>{{ $t('delete') }}</md-tooltip>
                </div>
              </div>
            </div>
          </md-field>

          <div class="md-layout-item md-size-100 text-right pt-15">
            <md-button class="md-primary md-round btn-custom" @click="handleCreate"
              >{{ $t('create') }}
              <md-tooltip>{{ $t('newRole') }}</md-tooltip>
            </md-button>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import { useManagementStore } from '@/stores/management'
import Swal from 'sweetalert2'

export default {
  components: {},
  data() {
    return {
      loader: false,
      mdUserRoles: [],
    }
  },
  async beforeMount() {
    this.$changePageTitle(this.$t('pageTitleRolesTable'))
    this.loader = true
    await this.getMDUserRoles()

    this.managementStore.resetManagementStore()
    this.loader = false
  },
  methods: {
    async getMDUserRoles() {
      var res = await this.companyStore.getUserRoles(this.userStore.token)
      if (res.status === 200) {
        this.mdUserRoles = res.data
        this.mdUserRoles.sort((a, b) => a.Name.localeCompare(b.Name))
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: res.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      }
    },
    handleDelete(role) {
      if (role.Name === 'Administrator') {
        Swal.fire({
          title: this.$t('cannotDeleteRole'),
          icon: 'warning',
          confirmButtonText: this.$t('ok'),
        })
        return
      }
      Swal.fire({
        title: this.$t('askDeleteRoleText'),
        html: role.Name,
        icon: 'warning',
        confirmButtonText: this.$t('ok'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: this.$t('reallyDeleteRole'),
            html: this.$t('deleteText'),
            icon: 'warning',
            confirmButtonText: this.$t('ok'),
            showCancelButton: true,
            cancelButtonText: this.$t('cancel'),
          }).then((result) => {
            if (result.isConfirmed) {
              this.deleteRole(role)
            }
          })
        }
      })
    },
    async deleteRole(role) {
      var resPerms = await this.managementStore.getPermissionsByRoleID(role.ID, this.userStore.token)
      if (resPerms.status === 200) {
        var resDelRolePerms = await this.managementStore.deleteAllRolePermission(role.ID, this.userStore.token)
        if (resDelRolePerms.status !== 200) return
      }
      if (resPerms.status !== 200 && resPerms.status !== 404) {
        return
      }
      var res = await this.managementStore.deleteUserRole(role.Name, this.userStore.token)
      if (res.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('succesDeleteRole'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        })
      } else {
        Swal.fire({
          title: this.$t('errorDeleteRole'),
          html: res.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      }
      this.getMDUserRoles()
    },
    handleEdit(role) {
      Swal.fire({
        title: this.$t('editRoleTitle'),
        html: role.Name,
        icon: 'question',
        confirmButtonText: this.$t('ok'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.managementStore.manageRole = {
            edit: true,
            role: role,
          }

          this.$router.push({ name: 'Edit Role' })
        }
      })
    },
    handleCreate() {
      Swal.fire({
        title: this.$t('createRoleTitle'),
        icon: 'question',
        confirmButtonText: this.$t('ok'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.managementStore.manageRole = {
            edit: false,
            role: {},
          }

          this.$router.push({ name: 'Edit Role' })
        }
      })
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const managementStore = useManagementStore()
    return { companyStore, userStore, configurationProductLevelStore, managementStore }
  },
}
</script>

<style lang="scss" scoped>
.md-field .animated-icon {
  visibility: hidden;
  padding-bottom: 0;
}
.md-field {
  margin: 0;
}
.md-field:hover .animated-icon {
  visibility: visible;
}
.controls {
  display: flex;
  flex-direction: row;
  padding-bottom: 4px;
  padding-right: 4px;
  justify-content: flex-end;
  align-items: center;
}
.md-layout-item {
  padding-left: 0;
  padding-right: 0;
}
.icon-container {
  position: relative;
}
.config-content {
  display: flex;
}

.roles-header {
  font-weight: bold;
}
.md-field {
  padding-bottom: 0 !important;
  // margin-bottom: 15px;
}
.md-field:after {
  bottom: 0;
}
.animated-icon {
  vertical-align: middle;
  cursor: pointer;
}
.pt-15 {
  padding-top: 15px;
}
</style>

<template>
  <md-card class="" :class="{ 'md-card-nav-mdesign': !view, 'md-card-nav-mdesign-view': view }">
    <md-card-header class="md-card-header-icon md-card-header-primary">
      <div class="card-icon">
        <md-icon>assignment</md-icon>
      </div>
      <slot name="header" class="title"></slot>
    </md-card-header>
    <md-card-content>
      <slot name="contentNav"></slot>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  emits: ['createConfig'],
  props: {
    view: {
      type: Boolean,
      default: false,
    },
  },
  name: 'MdesignConfigNavCard',
  methods: {
    handleCreate() {
      this.$emit('createConfig')
    },
  },
}
</script>

<style scoped>
.md-card-nav-mdesign {
  height: fit-content !important;
}
</style>

<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <li>
            <a :href="$t('contactLink')" target="_blank" id="contact">{{ $t('contact') }}</a>
          </li>
          <li>
            <a :href="$t('termsLink')" target="_blank" id="terms">
              {{ $t('terms&conditions') }}
            </a>
          </li>
          <li>
            <a :href="$t('licenseLink')" target="_blank" id="license">
              {{ $t('license') }}
            </a>
          </li>
          <li>
            <a target="_blank" :href="$t('privacyLink')" id="privacy">{{ $t('privacy') }}</a>
          </li>
          <li>
            <cookie-modal></cookie-modal>
          </li>
          <li>
            <a target="_blank" :href="$t('cancellationLink')" id="cancellation">{{ $t('cancellation') }}</a>
          </li>
          <li>
            <a target="_blank" :href="$t('imprintLink')" id="imprint">{{ $t('imprint') }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>
<script>
import CookieModal from '../../components/Cookie/CookieModal.vue'

export default {
  components: {
    CookieModal,
  },
  props: {
    backgroundColor: {
      type: String,
      default: '',
      validator: (value) => {
        let acceptedValues = ['', 'black', 'white', 'red']
        return acceptedValues.indexOf(value) !== -1
      },
    },
  },
  data() {
    return {}
  },
}
</script>
<style scoped>
.footer {
  color: #ffffff;
  background-color: #00325b;
}
[data-background-color='black'] .footer {
  background-color: transparent;
}
</style>

<template>
  <ValidationObserver v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(submit)" class="md-layout-item md-medium-size-66 md-size-66 md-small-size-100">
      <loader :loader="loader" />
      <md-card class="card-content scroll-auto">
        <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
          <h4 class="title">
            {{ $t('contactData') }}
          </h4>
          <h3>{{ $t('createContactText') }}</h3>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label for="salutation">{{ $t('salutation') }}</label>
                <md-select v-model="contact.Salutation" name="salutation" id="salutation">
                  <md-option value="Herr">{{ $t('mr') }}</md-option>
                  <md-option value="Frau">{{ $t('ms') }}</md-option>
                  <md-option value="NoSalutation">{{ $t('noSalutaition') }}</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>{{ $t('title') }}</label>
                <md-input v-model="contact.Title" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <div>
                <ValidationProvider name="firstname" rules="required" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('firstName') }}</label>
                    <md-input v-model="contact.FirstName" type="text"></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <div>
                <ValidationProvider name="lastname" rules="required" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('lastName') }}</label>
                    <md-input v-model="contact.LastName" type="text"></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label for="gender">{{ $t('gender') }}</label>
                <md-select v-model="contact.Gender" name="gender" id="gender">
                  <md-option value="männlich">{{ $t('male') }}</md-option>
                  <md-option value="weiblich">{{ $t('female') }}</md-option>
                  <md-option value="divers">{{ $t('diverse') }}</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>{{ $t('phoneNumber') }}</label>
                <md-input v-model="contact.Phone" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t('email') }}</label>
                  <md-input v-model="contact.Email" type="email"> </md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>

            <!-- <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t("position") }}</label>
                  <md-input v-model="contact.Position" type="text"></md-input>
                </md-field>
              </div> -->

            <!-- <div class="md-layout-item md-small-size-100 md-size-50">
                <md-checkbox v-model="contact.MainContact" class="imo-checkbox">{{ $t("mainContact") }}
                </md-checkbox>
              </div> -->

            <div class="md-layout-item md-small-size-100 md-size-50">
              <select-country :value="contact.Language.toUpperCase()" @changeCountry="handleCountry"></select-country>
            </div>

            <div class="md-layout-item md-size-100 text-right">
              <md-button id="submit" class="md-raised md-primary mt-4" @click="askCreate()" :disabled="invalid"
                >{{ $t('createNewContact') }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import SelectCountry from '../../components/SelectCountry/SelectCountry.vue'
import { useCompanyStore } from '../../stores/company'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

export default {
  components: { SelectCountry, ValidationObserver, ValidationProvider },
  name: 'create-company-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()

    return { companyStore, userStore }
  },
  data() {
    return {
      contact: {
        ID: '',
        CompanyID: '',
        Salutation: '',
        Title: '',
        FirstName: '',
        LastName: '',
        Gender: '',
        Phone: '',
        Email: '',
        Position: '',
        MainContact: false,
        Language: '',
        Image: 0,
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      loader: false,
    }
  },
  mounted() {
    this.$changePageTitle(this.$t('pageTitleCreateNewContact'))
  },
  methods: {
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    handleCountry(val) {
      this.contact.Language = val.value.toLowerCase()
    },
    askCreate() {
      Swal.fire({
        title: this.$t('askCreateNewContact'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('confirm'),
      }).then((result) => {
        if (result.dismiss) {
          return
        } else {
          this.createNewContact()
        }
      })
    },
    async createNewContact() {
      this.contact.CreatedBy = this.userStore.userData.LastName
      this.contact.Created = this.companyStore.formatDate(new Date())
      this.contact.ModifiedBy = this.userStore.userData.LastName
      this.contact.Modified = this.companyStore.formatDate(new Date())
      // this.contact.CompanyID = "NULL"

      this.loader = true
      var res = await this.userStore.createUser(this.contact, this.userStore.token)
      this.loader = false

      if (res) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('contactWasCreated'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        }).then((result) => {
          this.$router.push({ name: 'Contacts List' })
          //this.$router.push({ name: "Edit Company Profile", params: { companyTypes: this.companyTypes } });
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          // html: res.data.Message,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      }
    },
  },
}
</script>
<style scoped>
form {
  margin: 0 auto;
}
</style>

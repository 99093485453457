<template>
  <md-card class="md-card-edit-mdesign">
    <md-card-header class="md-card-header-icon md-card-header-primary">
      <div class="card-icon">
        <img class="small-icon-header" v-if="this.imgData !== ''" :src="this.imgData" title="" />
        <img class="small-icon-header" v-else :src="this.regularImg" title="" />
        <!-- <md-icon>assignment</md-icon> -->
      </div>
      <slot name="header" class="title"></slot>
    </md-card-header>
    <md-card-content>
      <slot name="contentEdit"></slot>
    </md-card-content>
  </md-card>
</template>

<script>
import defaultIcon from '@/../public/img/default_icon_128-128.png'

export default {
  name: 'MdesignConfigEditCard',
  props: {
    imgData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      regularImg: defaultIcon,
    }
  },
}
</script>

<style scoped>
.small-icon-header {
  /* max-width: 42px;
  max-height: 42px; */
  max-width: 63px;
  max-height: 63px;
}
.card-icon {
  max-width: 63px;
  max-height: 63px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c("loader", { attrs: { loader: _vm.loader } }),
      _c("div", { staticClass: "img-title-registration" }, [
        _c("img", {
          staticClass: "img-logo",
          attrs: { slot: "title", src: _vm.logo, alt: "MyMdesignLogo" },
          slot: "title",
        }),
      ]),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  {
                    attrs: {
                      slot: "inputs",
                      action: "",
                      method: "post",
                      id: "registrationForm",
                    },
                    slot: "inputs",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-default",
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "title text-center registration-title",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("registration")) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "md-collapse private-business-role",
                        }),
                        _c("md-card-content", { staticClass: "input-fields" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-xsmall-size-100 md-size-50",
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "email",
                                  rules: "required|email",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var passed = ref.passed
                                        var failed = ref.failed
                                        return [
                                          _c(
                                            "md-field",
                                            {
                                              class: [
                                                { "md-error": failed },
                                                { "md-valid": passed },
                                              ],
                                              attrs: { required: "" },
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("email")) + " *"
                                                ),
                                              ]),
                                              _c("md-input", {
                                                attrs: {
                                                  disabled:
                                                    _vm.registerCompanyContact,
                                                  type: "email",
                                                  autocomplete: "new-password",
                                                },
                                                model: {
                                                  value: _vm.email,
                                                  callback: function ($$v) {
                                                    _vm.email = $$v
                                                  },
                                                  expression: "email",
                                                },
                                              }),
                                              _c(
                                                "slide-y-down-transition",
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: failed,
                                                          expression: "failed",
                                                        },
                                                      ],
                                                      staticClass: "error",
                                                    },
                                                    [_vm._v("close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "slide-y-down-transition",
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: passed,
                                                          expression: "passed",
                                                        },
                                                      ],
                                                      staticClass: "success",
                                                    },
                                                    [_vm._v("done")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "password",
                                  rules: {
                                    required: true,
                                    regex: _vm.regExValid,
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var passed = ref.passed
                                        var failed = ref.failed
                                        return [
                                          _c(
                                            "md-field",
                                            {
                                              class: [
                                                { "md-error": failed },
                                                { "md-valid": passed },
                                              ],
                                              attrs: { required: "" },
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("password")) +
                                                    " *"
                                                ),
                                              ]),
                                              _c("md-input", {
                                                attrs: {
                                                  type: "password",
                                                  autocomplete: "new-password",
                                                },
                                                model: {
                                                  value: _vm.password,
                                                  callback: function ($$v) {
                                                    _vm.password = $$v
                                                  },
                                                  expression: "password",
                                                },
                                              }),
                                              _c(
                                                "slide-y-down-transition",
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: failed,
                                                          expression: "failed",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "error password",
                                                    },
                                                    [_vm._v("close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "slide-y-down-transition",
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: passed,
                                                          expression: "passed",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "success password",
                                                    },
                                                    [_vm._v("done")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: failed,
                                                  expression: "failed",
                                                },
                                              ],
                                              staticClass: "password-rule",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("setPasswordRule")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm.isPrivateRole
                                ? _c(
                                    "ValidationProvider",
                                    {
                                      attrs: {
                                        name: "checkboxStudent",
                                        rules: {
                                          required: { allowFalse: false },
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "student-checkbox" },
                                        [
                                          _c("label", { staticClass: "line" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.checkboxStudent,
                                                  expression: "checkboxStudent",
                                                },
                                              ],
                                              staticClass:
                                                "my-checkbox student-checkbox",
                                              attrs: {
                                                type: "checkbox",
                                                required: "",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.checkboxStudent
                                                )
                                                  ? _vm._i(
                                                      _vm.checkboxStudent,
                                                      null
                                                    ) > -1
                                                  : _vm.checkboxStudent,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.checkboxStudent,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.checkboxStudent =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.checkboxStudent =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.checkboxStudent = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "student-checkbox",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("studentCheckbox")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-xsmall-size-100 md-size-50 right-side-menu",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-xsmall-size-100 md-size-100",
                                },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "salutation" } },
                                        [_vm._v(_vm._s(_vm.$t("salutation")))]
                                      ),
                                      _c(
                                        "md-select",
                                        {
                                          attrs: {
                                            name: "salutation",
                                            id: "salutation",
                                          },
                                          model: {
                                            value: _vm.salutation,
                                            callback: function ($$v) {
                                              _vm.salutation = $$v
                                            },
                                            expression: "salutation",
                                          },
                                        },
                                        [
                                          _c(
                                            "md-option",
                                            { attrs: { value: "Herr" } },
                                            [_vm._v(_vm._s(_vm.$t("mr")))]
                                          ),
                                          _c(
                                            "md-option",
                                            { attrs: { value: "Frau" } },
                                            [_vm._v(_vm._s(_vm.$t("ms")))]
                                          ),
                                          _c(
                                            "md-option",
                                            {
                                              attrs: { value: "NoSalutation" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("noSalutaition"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "ValidationProvider",
                                    { attrs: { rules: "required" } },
                                    [
                                      _c(
                                        "md-field",
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(_vm.$t("firstName")) + " *"
                                            ),
                                          ]),
                                          _c("md-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.firstname,
                                              callback: function ($$v) {
                                                _vm.firstname = $$v
                                              },
                                              expression: "firstname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "ValidationProvider",
                                    { attrs: { rules: "required" } },
                                    [
                                      _c(
                                        "md-field",
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(_vm.$t("lastName")) + " *"
                                            ),
                                          ]),
                                          _c("md-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.lastname,
                                              callback: function ($$v) {
                                                _vm.lastname = $$v
                                              },
                                              expression: "lastname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("select-country", {
                                    attrs: { value: "DE" },
                                  }),
                                  !_vm.isPrivateRole
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "ValidationProvider",
                                            {
                                              attrs: {
                                                rules: {
                                                  required: !_vm.isPrivateRole,
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "md-field",
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("companyName")
                                                      ) + " *"
                                                    ),
                                                  ]),
                                                  _c("md-input", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.registerCompanyContact,
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value: _vm.companyName,
                                                      callback: function ($$v) {
                                                        _vm.companyName = $$v
                                                      },
                                                      expression: "companyName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              !_vm.isPrivateRole
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "street-hausnumber md-layout-item md-xsmall-size-100 md-size-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-size-50",
                                        },
                                        [
                                          _c(
                                            "ValidationProvider",
                                            {
                                              attrs: {
                                                rules: {
                                                  required: !_vm.isPrivateRole,
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "md-field",
                                                { staticClass: "street" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("street")) +
                                                        " *"
                                                    ),
                                                  ]),
                                                  _c("md-input", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.registerCompanyContact,
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value: _vm.street,
                                                      callback: function ($$v) {
                                                        _vm.street = $$v
                                                      },
                                                      expression: "street",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-size-50",
                                        },
                                        [
                                          _c(
                                            "ValidationProvider",
                                            {
                                              attrs: {
                                                rules: {
                                                  required: !_vm.isPrivateRole,
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "md-field",
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("houseNumber")
                                                      ) + " *"
                                                    ),
                                                  ]),
                                                  _c("md-input", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.registerCompanyContact,
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value: _vm.houseNumber,
                                                      callback: function ($$v) {
                                                        _vm.houseNumber = $$v
                                                      },
                                                      expression: "houseNumber",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.isPrivateRole
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "city-zip md-layout-item md-xsmall-size-100 md-size-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-size-50",
                                        },
                                        [
                                          _c(
                                            "ValidationProvider",
                                            {
                                              attrs: {
                                                rules: {
                                                  required: !_vm.isPrivateRole,
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "md-field",
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("city")) +
                                                        " *"
                                                    ),
                                                  ]),
                                                  _c("md-input", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.registerCompanyContact,
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value: _vm.city,
                                                      callback: function ($$v) {
                                                        _vm.city = $$v
                                                      },
                                                      expression: "city",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-size-50",
                                        },
                                        [
                                          _c(
                                            "ValidationProvider",
                                            {
                                              attrs: {
                                                rules: {
                                                  required: !_vm.isPrivateRole,
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "md-field",
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("zipCode")
                                                      ) + " *"
                                                    ),
                                                  ]),
                                                  _c("md-input", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.registerCompanyContact,
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value: _vm.zipCode,
                                                      callback: function ($$v) {
                                                        _vm.zipCode = $$v
                                                      },
                                                      expression: "zipCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.isPrivateRole
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "customer-number md-layout-item md-xsmall-size-100 md-size-100",
                                    },
                                    [
                                      _c(
                                        "md-field",
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(_vm.$t("customerNumber"))
                                            ),
                                          ]),
                                          _c("md-input", {
                                            attrs: {
                                              disabled:
                                                _vm.registerCompanyContact,
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.mdesignCustomerNumber,
                                              callback: function ($$v) {
                                                _vm.mdesignCustomerNumber = $$v
                                              },
                                              expression:
                                                "mdesignCustomerNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c(
                          "md-card-actions",
                          [
                            _c(
                              "ValidationProvider",
                              {
                                attrs: {
                                  rules: { required: { allowFalse: false } },
                                  name: "checkbox",
                                },
                              },
                              [
                                _c("div", { staticClass: "required-fields" }, [
                                  _vm._v(
                                    "* " + _vm._s(_vm.$t("requiredFields"))
                                  ),
                                ]),
                                _c("div", { staticClass: "terms-conditions" }, [
                                  _c("label", { staticClass: "line" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.checkbox,
                                          expression: "checkbox",
                                        },
                                      ],
                                      staticClass: "my-checkbox",
                                      attrs: { type: "checkbox", required: "" },
                                      domProps: {
                                        checked: Array.isArray(_vm.checkbox)
                                          ? _vm._i(_vm.checkbox, null) > -1
                                          : _vm.checkbox,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.checkbox,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.checkbox = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.checkbox = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.checkbox = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "terms-conditions-text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("termsAndConditions")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "mailto:privacy@mdesign.de",
                                            },
                                          },
                                          [_vm._v("privacy@mdesign.de")]
                                        ),
                                        _vm._v(
                                          _vm._s(_vm.$t("termsAndConditions2"))
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              target: "_blank",
                                              href: _vm.$t("privacyLink"),
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("here")))]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "button-container" },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-primary md-round mt-4",
                                    attrs: {
                                      slot: "footer",
                                      disabled: invalid,
                                      "data-bind": _vm.recaptchaSubmit,
                                    },
                                    on: {
                                      "data-callback": function ($event) {
                                        return _vm.submitForm()
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.showSwal()
                                      },
                                    },
                                    slot: "footer",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("submit")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("input", {
        staticClass: "g-recaptcha",
        attrs: {
          slot: "footer",
          type: "submit",
          id: "capcha",
          "data-sitekey": _vm.key,
          "data-bind": _vm.recaptchaSubmit,
        },
        on: {
          "data-callback": function ($event) {
            return _vm.submitForm()
          },
        },
        slot: "footer",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }